.wrapper-btn{
    display: flex;
    align-items: center;
}

.btn {
  border-radius: 10px;
  max-width: 100%;
  text-align: center;
  padding: 0 15px;
  text-transform: uppercase;
  color: #FFF;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  box-sizing: border-box;
  text-decoration: none;

  &.centerd {
    margin: 0 auto;
  }

  &.btn-show-details {
    margin: 0 auto;
  }

  &.wallet {
    @include font-black(1.25rem);
    background-color: $light-blue;
    color: $main-blue;
    height: 43px;
    line-height: 43px;

    @media screen and (max-width: 992px) {
      line-height: 1;
    }

    &::after {
      content: "";
      width: 20px;
      height: 20px;
      background-image: url($resolvepath + 'assets/wallet.svg');
      background-repeat: no-repeat;
      background-size: contain;
      display: inline-block;
      vertical-align: middle;
      margin: 0 5px 0 10px;
    }
  }

  &.centered {
    margin: 0 auto;
  }

  &.secondary {
    @include font-semibold(1.6rem);
    //height: 45px;
    //line-height: 45px;
    transition: all .4s ease-in-out;
    border-radius: 5px;
    font-size: 1.6875rem;
    line-height: 1;
    padding: 5px 15px 6px;
    letter-spacing: 3px;

    &:hover {
      color: #FFF;

      &::after {
        transform: translate(5px, 1.5px);
      }
    }

    &.no-arrow {
      &::after {
        display: none;
      }
    }

    &::after {
      content: "";
      width: 1.25rem;
      height: 1.25rem;
      background-image: url($resolvepath + 'assets/arrow-btn.svg');
      background-repeat: no-repeat;
      background-size: contain;
      //width: 2rem;
      //height: 2rem;
      margin: 0 0 0 1rem;
      transform: translate(0, 1.5px);
      display: inline-block;
      vertical-align: middle;      
      transition: all .125s ease-in-out;
    }

    &.red {
      background-color: $secondary-color;
    }

    &.blue {
      background-color: $main-blue
    }

    &.blue-light {
      background-color: $light-blue;
      color: $main-blue;

      &::after {
        background-image: url($resolvepath + 'assets/arrow-blue.svg');
      }
    }
  }

  &.full {
    width: 100%;
  }

  &.small {
    //height: 2.6rem;
    //line-height: 2.6rem;
    font-size: 1.5625rem;
  }

  &.xs {
    height: 2.375rem;
    line-height: 2.375rem;
    //font-size: 1.125rem;
    font-size: 1rem;
  }

  &.has-mb {
    margin-bottom: 3.125rem;
  }
}
