.home-menu {
  &.desktop {
    @media screen and (max-width: 992px) {
      padding: 0 1.25rem;
    }

    @media screen and (max-width: 640px) {
      display: none;
    }

    .logged-menu {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between !important;

      &:hover {
        cursor: pointer;
      }

      li {
        text-align: left;
        line-height: 1;

        &.my-id {
          text-align: right;
        }

        a {
          color: #FFF;
          display: block;
          width: 100%;
        }

        span {
          display: block;
          margin-top: .25rem;
          width: 100%;
          font-size: .75rem;
          font-weight: 400;

          /*&.current-wallet {
            &:after {
              content: "...";
            }
          }*/
        }

        &:hover {
          a {
            color: $secondary-color !important;
          }
        }
      }
    }
  }
}

.home-header {
  @media screen and (max-width: 640px) {
    height: auto;
    padding-top: 12rem;
  }

  .internal-navigation {
    &.home-version {
      @media screen and (max-width: 640px) {
        background-color: transparent;
      }

      .wrapper-logo {
        img {
          @media screen and (max-width: 640px) {
            width: 85px;
            max-width: 85px;
          }
        }
      }
    }
  }

  .btn.secondary.red.centered {
    @media screen and (max-width: 640px) {
      max-width: 90%;
    }
  }
}

.internal-navigation {
  background-color: $main-blue;
  padding: 15px 0;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10000;

  &.home-version {
    opacity: 0;
    visibility: hidden;
    height: 0;
    padding: 0;
    transition: all 0.2s ease-in-out;
    position: fixed !important;

    @media screen and (max-width: 640px) {
      opacity: 1;
      visibility: visible;
      height: auto;
      width: 100%;
      padding: 15px 0;
    }

    &.visible {
      opacity: 1;
      visibility: visible;
      padding: 15px 0;
      height: auto;
    }
  }

  .wrapper-nav {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    .wrapper-menu {
      flex: 0 0 80%;

      ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        @include font-black(1rem);

        li {
          text-transform: uppercase;
          text-align: center;
          margin: 0 10px;
          color: #FFF;

          a {
            color: #FFF;
            text-decoration: none;
          }

          .btn {
            &.wallet {
              @include font-black(1rem);
            }
          }
        }
      }
    }
  }

  .wrapper-logo {
    flex: 0 0 20%;

    img {
      // filter: drop-shadow(0 8px 5px rgba(255, 255, 255, 0.5));
      max-width: 72px;
    }
  }
}


.btn-menu-mobile {
  height: 4px;
  background-color: #FFF;
  width: 40px;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  cursor: pointer;

  &:before {
    bottom: 10px;
  }

  &:after {
    top: 10px;
  }

  &:after,
  &:before {
    content: "";
    height: 4px;
    background-color: #FFF;
    width: 50px;
    display: block;
    position: absolute;
    cursor: pointer;
  }
}

.logged-menu {
  ul {
    li {
      color: #FFF;

      &:hover {
        a {
          color: $secondary-color !important;
        }
      }
    }
  }
}

//NAV
nav {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  .logo-wrapper {
    flex: 0 0 160px;

    @media screen and (max-width: 992px) {
      flex: 0 0 150px;
    }
  }

  .left-side,
  .right-side {
    flex: 0 0 calc(50% - 100px);

    @media screen and (max-width: 992px) {
      flex-shrink: 1;
    }
  }

  .left-side {
    ul {
      justify-content: flex-end;

      li {
        &:first-of-type {
          @media screen and (max-width: 992px) {
            margin-left: unset;
          }
        }
      }
    }
  }

  .right-side {
    padding-left: 20px;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    @include font-black(1.25rem);
    justify-content: flex-start;

    li {
      text-transform: uppercase;
      text-align: center;
      margin: 0 20px;
      color: #FFF;

      a {
        color: #FFF;
        text-decoration: none;
      }
    }

    &.logged-menu {
      justify-content: flex-start !important;
      display: none;

      li {
        margin: 10px 0;
        color: #FFF;
        font-size: 1rem;

        &:hover {
          a {
            color: $secondary-color;
          }
        }

        &:not(&:first-child) {
          margin-left: 10px;
        }
      }
    }
  }
}


.icon-scroll,
.icon-scroll:before {
  position: absolute;
  left: 50%;
}

.icon-scroll {
  width: 30px;
  height: 45px;
  margin-left: -20px;
  box-shadow: inset 0 0 0 1px #fff;
  border-radius: 25px;
  position: absolute;

  @media screen and (max-width: 1680px) {
    margin-top: 3rem;
  }

  @media screen and (min-width: 640px) and (max-width: 768px) {
    margin-top: unset;
  }

  @media screen and (max-height: 992px) {
    margin-top: 2.5rem;
  }
}

.icon-scroll:before {
  content: '';
  width: 5px;
  height: 5px;
  background: #fff;
  margin-left: -2px;
  top: 5px;
  border-radius: 4px;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: scroll;
  animation-name: scroll;
}

@-webkit-keyframes scroll {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translateY(46px);
  }
}

@keyframes scroll {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translateY(46px);
  }
}

.internal-navigation {
  .wrapper-nav {
    @media screen and (max-width: 640px) {
      padding: .5rem .85rem;
      position: relative;
    }

    .wallet-cont-show-on-xs {
      display: none;

      @media screen and (max-width: 640px) {
        display: block;
        margin-left: auto;
        margin-right: auto;
        list-style: none;
        padding: 0;
      }

      li {
        .wallet {
          @media screen and (max-width: 640px) {
            height: auto;
            font-size: .85rem;
            padding: 4px 1rem;
            left: 50%;
            //transform: translateX(-50%);
          }
        }
      }

      .logged-menu {
        padding-top: .5rem;
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-end !important;

        &:hover {
          cursor: pointer;
        }

        li {
          text-align: left;
          line-height: 1;
          flex: 0;

          &.my-nft {
            min-width: 75px;
          }

          &.my-id {
            text-align: right;
            min-width: 50px;
          }

          a {
            color: #FFF;
            display: block;
            width: 100%;
          }

          span {
            display: block;
            margin-top: .25rem;
            width: 100%;
            font-size: .75rem;
            font-weight: 400;
            /*&.current-wallet {
              &:after {
                content: "...";
              }
            }*/
          }

          &:hover {
            a {
              color: $secondary-color !important;
            }
          }
        }
      }
    }

    .burger-menu {
      @media screen and (max-width: 640px) {
        width: 37px;
        height: 17px;
        flex-direction: column;
        justify-content: space-around;
        margin-left: unset;
        position: relative;
        z-index: 10000;
        margin-left: auto;
      }

      &.burger-clicked {
        .top-bun {
          @media screen and (max-width: 640px) {
            transform: translateY(0.5px) rotate(45deg);
          }
        }

        .bottom-bun {
          @media screen and (max-width: 640px) {
            transform: translateY(-7.5px) rotate(-45deg);
          }
        }
      }

      .top-bun,
      .bottom-bun {
        @media screen and (max-width: 640px) {
          width: 100%;
          border-bottom: 3px solid #fff;
          transition: all ease-in-out .125s;
        }
      }
    }

    .wrapper-menu {
      @media screen and (max-width: 640px) {
        display: none;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, calc(100% + 1.25rem));
        background-color: $main-blue;
        width: 100vw;
        padding: 0 1.75rem;
      }

      ul {
        @media screen and (max-width: 640px) {
          display: flex;
          flex-direction: column;
          padding: 1rem 0;
        }

        li {
          @media screen and (max-width: 640px) {
            width: 100%;
            padding: .75rem 0;
            margin: unset;
            border-bottom: .5px solid #fff;
            text-align: left;
            font-size: 2rem;
          }

          &.hide-on-xs {
            @media screen and (max-width: 640px) {
              display: none;
            }
          }

          &:last-of-type,
          &:nth-last-of-type(2) {
            @media screen and (max-width: 640px) {
              border-bottom: unset;
              padding: 1.25rem 0;
            }
          }

          a {
            color: $light-blue;

            &:hover {
              color: $secondary-color !important;
            }
          }

          .logged-menu {
            padding-top: .5rem;
            margin-bottom: -1rem;
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between !important;

            &:hover {
              cursor: pointer;
            }

            li {
              text-align: left;
              line-height: 1;

              &.my-id {
                text-align: right;
              }

              a {
                color: #FFF;
                display: block;
                width: 100%;
              }

              span {
                display: block;
                margin-top: .25rem;
                width: 100%;
                font-size: .75rem;
                font-weight: 400;
                /*&.current-wallet {
                  &:after {
                    content: "...";
                  }
                }*/
              }

              &:hover {
                a {
                  color: $secondary-color !important;
                }
              }
            }
          }
        }
      }
    }
  }

  .btn {
    &.wallet {
      &.connected {
        position: absolute;
        top: 0;
        //right: 2rem;
        right: calc((100vw - 1290px) / 2);
        height: 30px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        //transform: translateX(-50%);

        @media screen and (max-width: 1366px) {
          right: calc((100vw - 1122px) / 2);
        }

        @media screen and (max-width: 1180px) {
          right: calc((100vw - 942px) / 2);
        }

        @media screen and (max-width: 992px) {
          right: 2rem;
        }

        /*@media screen and (max-width: 1180px) {
          right: calc((100vw - 942px) / 2);
        }*/

        @media screen and (max-width: 640px) {
          top: -1rem;
          right: 1rem;
          left: unset;
        }
      }
    }
  }
}

.btn-open-mobile {
  position: absolute;
  top: 50%;
  right: 2rem;
  transform: translateY(-50%);
  font-size: 3rem;
  color: #FFF;
  cursor: pointer;
  display: none;

  @media screen and (max-width:660px) {
    display: block;
  }
}

.panel-mobile-menu {
  position: fixed;
  z-index: 20000;
  width: 100%;
  background-color: #FFF;
  background-color: $main-blue;
  height: 100vh;
  transform: translateX(-100%);
  transition: all .4s ease-in-out;

  .wrapper-logo {
    position: absolute;
    width: 100px;
    left: 50%;
    transform: translate(-50%, 0);
    top: 2rem;
  }

  &.open {
    transform: translateX(0);
  }

  .close-btn {
    font-size: 2.6rem;
    color: #FFF;
    position: absolute;
    top: 2rem;
    right: 2rem;
  }

  ul {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 100vh;
    font-weight: bold;

    li {
      text-transform: uppercase;
      font-size: 2rem;
      padding: .5rem 0;

      a {
        text-decoration: none;
        color: #FFF;
      }
    }
  }
}