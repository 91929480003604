@import url("https://use.typekit.net/ujg8ubc.css");

@mixin font-medium ($fontsize) {
  font-family: acumin-pro, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: $font-size;
}

@mixin font-bold($font-size) {
  font-family: acumin-pro, sans-serif;
  font-weight: 700;
  font-size: $font-size;
  font-style: normal;
}

@mixin font-semibold($font-size) {
  font-family: acumin-pro, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: $font-size;
}

@mixin font-black($font-size) {
  font-family: acumin-pro, sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: $font-size;
}

@mixin font-ultrablack($font-size) {
  font-family: acumin-pro, sans-serif;
  font-weight: 900;
  font-style: normal;
  font-size: $font-size;
}

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

.txt-center {
  text-align: center;
}

body {
  .txt-fit-holder {
    //width: 100%;
    display: inline-block;
    width: auto;
    margin-left: auto;
    margin-right: auto;

    .txt-fit {
      display: inline-block;
      width: auto;
      max-width: 100%;
      text-align: center;
      text-transform: uppercase;
      word-break: keep-all;
      white-space: nowrap;

      @media screen and (max-width: 640px) {
        font-size: 3.25rem;
        word-break: normal;
        white-space: normal;
      }

      &.txt-wrap {
        white-space: normal;
        word-break: normal;
      }
    }

    p.txt-fit {
      @media screen and (max-width: 640px) {
        font-size: 1.5rem;
      }

      &.txt-wrap {
        white-space: normal;
        word-break: normal;
        font-weight: normal;
        text-transform: none;
      }
    }
  }

  .title-decoration {
    //padding: 0.5rem 0 1rem;
    line-height: .75;
    padding-top: 1px;
    padding-bottom: 16px;

    @media screen and (max-width: 640px) {
      padding-top: 5px;
      padding-bottom: 10px;
    }
  }
}

.wrapper-title-wrap {
  position: relative;
  text-align: center;
  width: 100%;

  &.bg-pattern-red {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100vw;
      height: 100%;
      background-image: url($resolvepath + 'assets/bg-red.svg');
      background-size: contain;
      background-repeat: repeat-x;
      background-position: center;
    }
  }

  &.bg-pattern-azure {
    padding: 3.4375rem 0;

    @media screen and (max-width: 640px) {
      padding: 3rem 1rem;
    }

    &::before {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100vw;
      height: 100%;
      background-image: url($resolvepath + 'assets/bg-azure.svg');
      background-size: contain;
      background-repeat: repeat-x;
      background-position: center;

      @media screen and (max-width: 640px) {
        background-size: cover;
        min-width: 100%;
        min-height: 100%;
      }
    }

    .txt-fit-holder {
      h2.txt-fit {
        //font-size: 6.65vw;
        font-size: 6rem;

        @media screen and (max-width: 640px) {
          font-size: 3.25rem;
        }
      }

      p.txt-fit {
        font-size: 1.5vw;

        @media screen and (max-width: 640px) {
          font-size: 1.25rem;
        }
      }
    }
  }

  .wrapper-ball-title {
    text-transform: uppercase;
    padding: 7.25rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    @media screen and (max-width: 640px) {
      padding: 3rem 1rem;
    }

    .txt-fit-holder {
      // to fix
      //max-width: calc(100% - (17.2rem + 6rem));
      max-width: calc(100% - 5vw);

      @media screen and (max-width: 640px) {
        max-width: unset;
      }
      /*&.width-vw {
        max-width: calc(100vw - (17.2rem + 6rem));

        @media screen and (min-width: 1440px) and (max-width: 1536px) {
          max-width: 1080px;
          margin: 0 auto;
        }

        @media screen and (max-width: 640px) {
          max-width: unset;
        }
      }*/

      .txt-fit {
        font-size: 4rem;

        /*@media screen and (max-width: 1919px) {
          font-size: 4.5vw;
        }*/

        @media screen and (max-width: 640px) {
          font-size: 3.25rem;
        }
      }
    }

    h1, h2, h3 {
      line-height: 1;
    }

    h1, h2 {
      @include font-ultrablack(5.5rem);
      //color: $main-blue;
      text-transform: uppercase;
    }

    h2, h3 {
      color: white;
    }

    h3 {
      @include font-black(2.8rem);
      //color: $light-blue;
      margin-bottom: .85rem;

      @media screen and (max-width: 820px) {
        @include font-black(1.5rem);
      }

      @media screen and (max-width: 640px) {
        font-size: 1.25rem;
      }
    }

    h2 {
      position: relative;
      display: inline-block;
      width: auto;
      padding: 8px 0 18px;

      @media screen and (max-width: 640px) {
        padding: 2px 0 8px;
        overflow: hidden;
      }

      &::before {
        left: 0;
        transform: translate(calc(-100% - 3rem), -50%);
      }

      &::after {
        right: 0;
        transform: translate(calc(100% + 3rem), -50%);
      }

      &::before, &::after {
        content: "";
        width: 7.6rem;
        height: 7.6rem;
        display: inline-block;
        vertical-align: middle;
        background-image: url($resolvepath + 'assets/pallone-pixel.png');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        position: absolute;
        top: 50%;
        /*@media screen and (max-width:992px) {
          width: 4.6rem;
          height: 4.6rem;
        }*/
        @media screen and (max-width: 640px) {
          content: none;
        }
      }
    }
  }
}

.title-decoration {
  @include font-ultrablack(6rem);
  color: $light-blue;
  text-transform: uppercase;
  width: 100%;
  border-top: 0.625rem solid $light-blue;
  border-bottom: 0.625rem solid $light-blue;
  line-height: 1;
  //padding: 2rem 0;
  margin: 0;

  &.main-color {
    color: $main-blue;
    border-bottom-color: $main-blue;
    border-top-color: $main-blue;
  }

  &.small {
    font-size: 4rem;
  }

  &.red {
    color: $secondary-color;
    border-bottom-color: $secondary-color;
    border-top-color: $secondary-color;
  }

  &.blue {
    color: $main-blue;
    border-top-color: $main-blue;
    border-bottom-color: $main-blue;
  }

  &.white {
    color: #fff;
    border-top-color: #fff;
    border-bottom-color: #fff;
  }

  @media screen and (max-width:992px) {
    font-size: 1.8rem;
  }
}

p {
  font-family: acumin-pro, sans-serif;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
  color: $main-blue;

  &.big {
    @include font-black(2rem)
  }

  @media screen and (max-width: 1366px) {
    font-size: 1.3rem;
  }

  @media screen and (max-width:992px) {
    //font-size: 18px !important;
  }
}

a {
  color: $secondary-color;

  &:hover {
    color: $light-blue;
  }
}
