@import url("https://use.typekit.net/ujg8ubc.css");
.txt-center {
  text-align: center;
}

body .txt-fit-holder {
  display: inline-block;
  width: auto;
  margin-left: auto;
  margin-right: auto;
}
body .txt-fit-holder .txt-fit {
  display: inline-block;
  width: auto;
  max-width: 100%;
  text-align: center;
  text-transform: uppercase;
  word-break: keep-all;
  white-space: nowrap;
}
@media screen and (max-width: 640px) {
  body .txt-fit-holder .txt-fit {
    font-size: 3.25rem;
    word-break: normal;
    white-space: normal;
  }
}
body .txt-fit-holder .txt-fit.txt-wrap {
  white-space: normal;
  word-break: normal;
}
@media screen and (max-width: 640px) {
  body .txt-fit-holder p.txt-fit {
    font-size: 1.5rem;
  }
}
body .txt-fit-holder p.txt-fit.txt-wrap {
  white-space: normal;
  word-break: normal;
  font-weight: normal;
  text-transform: none;
}
body .title-decoration {
  line-height: 0.75;
  padding-top: 1px;
  padding-bottom: 16px;
}
@media screen and (max-width: 640px) {
  body .title-decoration {
    padding-top: 5px;
    padding-bottom: 10px;
  }
}

.wrapper-title-wrap {
  position: relative;
  text-align: center;
  width: 100%;
}
.wrapper-title-wrap.bg-pattern-red::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  height: 100%;
  background-image: url("~assets/bg-red.svg");
  background-size: contain;
  background-repeat: repeat-x;
  background-position: center;
}
.wrapper-title-wrap.bg-pattern-azure {
  padding: 3.4375rem 0;
}
@media screen and (max-width: 640px) {
  .wrapper-title-wrap.bg-pattern-azure {
    padding: 3rem 1rem;
  }
}
.wrapper-title-wrap.bg-pattern-azure::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  height: 100%;
  background-image: url("~assets/bg-azure.svg");
  background-size: contain;
  background-repeat: repeat-x;
  background-position: center;
}
@media screen and (max-width: 640px) {
  .wrapper-title-wrap.bg-pattern-azure::before {
    background-size: cover;
    min-width: 100%;
    min-height: 100%;
  }
}
.wrapper-title-wrap.bg-pattern-azure .txt-fit-holder h2.txt-fit {
  font-size: 6rem;
}
@media screen and (max-width: 640px) {
  .wrapper-title-wrap.bg-pattern-azure .txt-fit-holder h2.txt-fit {
    font-size: 3.25rem;
  }
}
.wrapper-title-wrap.bg-pattern-azure .txt-fit-holder p.txt-fit {
  font-size: 1.5vw;
}
@media screen and (max-width: 640px) {
  .wrapper-title-wrap.bg-pattern-azure .txt-fit-holder p.txt-fit {
    font-size: 1.25rem;
  }
}
.wrapper-title-wrap .wrapper-ball-title {
  text-transform: uppercase;
  padding: 7.25rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
@media screen and (max-width: 640px) {
  .wrapper-title-wrap .wrapper-ball-title {
    padding: 3rem 1rem;
  }
}
.wrapper-title-wrap .wrapper-ball-title .txt-fit-holder {
  max-width: calc(100% - 5vw);
  /*&.width-vw {
    max-width: calc(100vw - (17.2rem + 6rem));

    @media screen and (min-width: 1440px) and (max-width: 1536px) {
      max-width: 1080px;
      margin: 0 auto;
    }

    @media screen and (max-width: 640px) {
      max-width: unset;
    }
  }*/
}
@media screen and (max-width: 640px) {
  .wrapper-title-wrap .wrapper-ball-title .txt-fit-holder {
    max-width: unset;
  }
}
.wrapper-title-wrap .wrapper-ball-title .txt-fit-holder .txt-fit {
  font-size: 4rem;
  /*@media screen and (max-width: 1919px) {
    font-size: 4.5vw;
  }*/
}
@media screen and (max-width: 640px) {
  .wrapper-title-wrap .wrapper-ball-title .txt-fit-holder .txt-fit {
    font-size: 3.25rem;
  }
}
.wrapper-title-wrap .wrapper-ball-title h1, .wrapper-title-wrap .wrapper-ball-title h2, .wrapper-title-wrap .wrapper-ball-title h3 {
  line-height: 1;
}
.wrapper-title-wrap .wrapper-ball-title h1, .wrapper-title-wrap .wrapper-ball-title h2 {
  font-family: acumin-pro, sans-serif;
  font-weight: 900;
  font-style: normal;
  font-size: 5.5rem;
  text-transform: uppercase;
}
.wrapper-title-wrap .wrapper-ball-title h2, .wrapper-title-wrap .wrapper-ball-title h3 {
  color: white;
}
.wrapper-title-wrap .wrapper-ball-title h3 {
  font-family: acumin-pro, sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 2.8rem;
  margin-bottom: 0.85rem;
}
@media screen and (max-width: 820px) {
  .wrapper-title-wrap .wrapper-ball-title h3 {
    font-family: acumin-pro, sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 640px) {
  .wrapper-title-wrap .wrapper-ball-title h3 {
    font-size: 1.25rem;
  }
}
.wrapper-title-wrap .wrapper-ball-title h2 {
  position: relative;
  display: inline-block;
  width: auto;
  padding: 8px 0 18px;
}
@media screen and (max-width: 640px) {
  .wrapper-title-wrap .wrapper-ball-title h2 {
    padding: 2px 0 8px;
    overflow: hidden;
  }
}
.wrapper-title-wrap .wrapper-ball-title h2::before {
  left: 0;
  transform: translate(calc(-100% - 3rem), -50%);
}
.wrapper-title-wrap .wrapper-ball-title h2::after {
  right: 0;
  transform: translate(calc(100% + 3rem), -50%);
}
.wrapper-title-wrap .wrapper-ball-title h2::before, .wrapper-title-wrap .wrapper-ball-title h2::after {
  content: "";
  width: 7.6rem;
  height: 7.6rem;
  display: inline-block;
  vertical-align: middle;
  background-image: url("~assets/pallone-pixel.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 50%;
  /*@media screen and (max-width:992px) {
    width: 4.6rem;
    height: 4.6rem;
  }*/
}
@media screen and (max-width: 640px) {
  .wrapper-title-wrap .wrapper-ball-title h2::before, .wrapper-title-wrap .wrapper-ball-title h2::after {
    content: none;
  }
}

.title-decoration {
  font-family: acumin-pro, sans-serif;
  font-weight: 900;
  font-style: normal;
  font-size: 6rem;
  color: #3DFFFF;
  text-transform: uppercase;
  width: 100%;
  border-top: 0.625rem solid #3DFFFF;
  border-bottom: 0.625rem solid #3DFFFF;
  line-height: 1;
  margin: 0;
}
.title-decoration.main-color {
  color: #00008d;
  border-bottom-color: #00008d;
  border-top-color: #00008d;
}
.title-decoration.small {
  font-size: 4rem;
}
.title-decoration.red {
  color: #FF175B;
  border-bottom-color: #FF175B;
  border-top-color: #FF175B;
}
.title-decoration.blue {
  color: #00008d;
  border-top-color: #00008d;
  border-bottom-color: #00008d;
}
.title-decoration.white {
  color: #fff;
  border-top-color: #fff;
  border-bottom-color: #fff;
}
@media screen and (max-width: 992px) {
  .title-decoration {
    font-size: 1.8rem;
  }
}

p {
  font-family: acumin-pro, sans-serif;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
  color: #00008d;
}
p.big {
  font-family: acumin-pro, sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 2rem;
}
@media screen and (max-width: 1366px) {
  p {
    font-size: 1.3rem;
  }
}
a {
  color: #FF175B;
}
a:hover {
  color: #3DFFFF;
}

@media screen and (max-width: 992px) {
  .home-menu.desktop {
    padding: 0 1.25rem;
  }
}
@media screen and (max-width: 640px) {
  .home-menu.desktop {
    display: none;
  }
}
.home-menu.desktop .logged-menu {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between !important;
}
.home-menu.desktop .logged-menu:hover {
  cursor: pointer;
}
.home-menu.desktop .logged-menu li {
  text-align: left;
  line-height: 1;
}
.home-menu.desktop .logged-menu li.my-id {
  text-align: right;
}
.home-menu.desktop .logged-menu li a {
  color: #FFF;
  display: block;
  width: 100%;
}
.home-menu.desktop .logged-menu li span {
  display: block;
  margin-top: 0.25rem;
  width: 100%;
  font-size: 0.75rem;
  font-weight: 400;
  /*&.current-wallet {
    &:after {
      content: "...";
    }
  }*/
}
.home-menu.desktop .logged-menu li:hover a {
  color: #FF175B !important;
}

@media screen and (max-width: 640px) {
  .home-header {
    height: auto;
    padding-top: 12rem;
  }
}
@media screen and (max-width: 640px) {
  .home-header .internal-navigation.home-version {
    background-color: transparent;
  }
}
@media screen and (max-width: 640px) {
  .home-header .internal-navigation.home-version .wrapper-logo img {
    width: 85px;
    max-width: 85px;
  }
}
@media screen and (max-width: 640px) {
  .home-header .btn.secondary.red.centered {
    max-width: 90%;
  }
}

.internal-navigation {
  background-color: #00008d;
  padding: 15px 0;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10000;
}
.internal-navigation.home-version {
  opacity: 0;
  visibility: hidden;
  height: 0;
  padding: 0;
  transition: all 0.2s ease-in-out;
  position: fixed !important;
}
@media screen and (max-width: 640px) {
  .internal-navigation.home-version {
    opacity: 1;
    visibility: visible;
    height: auto;
    width: 100%;
    padding: 15px 0;
  }
}
.internal-navigation.home-version.visible {
  opacity: 1;
  visibility: visible;
  padding: 15px 0;
  height: auto;
}
.internal-navigation .wrapper-nav {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.internal-navigation .wrapper-nav .wrapper-menu {
  flex: 0 0 80%;
}
.internal-navigation .wrapper-nav .wrapper-menu ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-family: acumin-pro, sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 1rem;
}
.internal-navigation .wrapper-nav .wrapper-menu ul li {
  text-transform: uppercase;
  text-align: center;
  margin: 0 10px;
  color: #FFF;
}
.internal-navigation .wrapper-nav .wrapper-menu ul li a {
  color: #FFF;
  text-decoration: none;
}
.internal-navigation .wrapper-nav .wrapper-menu ul li .btn.wallet {
  font-family: acumin-pro, sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 1rem;
}
.internal-navigation .wrapper-logo {
  flex: 0 0 20%;
}
.internal-navigation .wrapper-logo img {
  max-width: 72px;
}

.btn-menu-mobile {
  height: 4px;
  background-color: #FFF;
  width: 40px;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  cursor: pointer;
}
.btn-menu-mobile:before {
  bottom: 10px;
}
.btn-menu-mobile:after {
  top: 10px;
}
.btn-menu-mobile:after, .btn-menu-mobile:before {
  content: "";
  height: 4px;
  background-color: #FFF;
  width: 50px;
  display: block;
  position: absolute;
  cursor: pointer;
}

.logged-menu ul li {
  color: #FFF;
}
.logged-menu ul li:hover a {
  color: #FF175B !important;
}

nav {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
nav .logo-wrapper {
  flex: 0 0 160px;
}
@media screen and (max-width: 992px) {
  nav .logo-wrapper {
    flex: 0 0 150px;
  }
}
nav .left-side,
nav .right-side {
  flex: 0 0 calc(50% - 100px);
}
@media screen and (max-width: 992px) {
  nav .left-side,
  nav .right-side {
    flex-shrink: 1;
  }
}
nav .left-side ul {
  justify-content: flex-end;
}
@media screen and (max-width: 992px) {
  nav .left-side ul li:first-of-type {
    margin-left: unset;
  }
}
nav .right-side {
  padding-left: 20px;
}
nav ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  font-family: acumin-pro, sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 1.25rem;
  justify-content: flex-start;
}
nav ul li {
  text-transform: uppercase;
  text-align: center;
  margin: 0 20px;
  color: #FFF;
}
nav ul li a {
  color: #FFF;
  text-decoration: none;
}
nav ul.logged-menu {
  justify-content: flex-start !important;
  display: none;
}
nav ul.logged-menu li {
  margin: 10px 0;
  color: #FFF;
  font-size: 1rem;
}
nav ul.logged-menu li:hover a {
  color: #FF175B;
}
nav ul.logged-menu li:not(nav ul.logged-menu li:first-child) {
  margin-left: 10px;
}

.icon-scroll,
.icon-scroll:before {
  position: absolute;
  left: 50%;
}

.icon-scroll {
  width: 30px;
  height: 45px;
  margin-left: -20px;
  box-shadow: inset 0 0 0 1px #fff;
  border-radius: 25px;
  position: absolute;
}
@media screen and (max-width: 1680px) {
  .icon-scroll {
    margin-top: 3rem;
  }
}
@media screen and (min-width: 640px) and (max-width: 768px) {
  .icon-scroll {
    margin-top: unset;
  }
}
@media screen and (max-height: 992px) {
  .icon-scroll {
    margin-top: 2.5rem;
  }
}

.icon-scroll:before {
  content: "";
  width: 5px;
  height: 5px;
  background: #fff;
  margin-left: -2px;
  top: 5px;
  border-radius: 4px;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: scroll;
  animation-name: scroll;
}

@-webkit-keyframes scroll {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(46px);
  }
}
@keyframes scroll {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(46px);
  }
}
@media screen and (max-width: 640px) {
  .internal-navigation .wrapper-nav {
    padding: 0.5rem 0.85rem;
    position: relative;
  }
}
.internal-navigation .wrapper-nav .wallet-cont-show-on-xs {
  display: none;
}
@media screen and (max-width: 640px) {
  .internal-navigation .wrapper-nav .wallet-cont-show-on-xs {
    display: block;
    margin-left: auto;
    margin-right: auto;
    list-style: none;
    padding: 0;
  }
}
@media screen and (max-width: 640px) {
  .internal-navigation .wrapper-nav .wallet-cont-show-on-xs li .wallet {
    height: auto;
    font-size: 0.85rem;
    padding: 4px 1rem;
    left: 50%;
  }
}
.internal-navigation .wrapper-nav .wallet-cont-show-on-xs .logged-menu {
  padding-top: 0.5rem;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end !important;
}
.internal-navigation .wrapper-nav .wallet-cont-show-on-xs .logged-menu:hover {
  cursor: pointer;
}
.internal-navigation .wrapper-nav .wallet-cont-show-on-xs .logged-menu li {
  text-align: left;
  line-height: 1;
  flex: 0;
}
.internal-navigation .wrapper-nav .wallet-cont-show-on-xs .logged-menu li.my-nft {
  min-width: 75px;
}
.internal-navigation .wrapper-nav .wallet-cont-show-on-xs .logged-menu li.my-id {
  text-align: right;
  min-width: 50px;
}
.internal-navigation .wrapper-nav .wallet-cont-show-on-xs .logged-menu li a {
  color: #FFF;
  display: block;
  width: 100%;
}
.internal-navigation .wrapper-nav .wallet-cont-show-on-xs .logged-menu li span {
  display: block;
  margin-top: 0.25rem;
  width: 100%;
  font-size: 0.75rem;
  font-weight: 400;
  /*&.current-wallet {
    &:after {
      content: "...";
    }
  }*/
}
.internal-navigation .wrapper-nav .wallet-cont-show-on-xs .logged-menu li:hover a {
  color: #FF175B !important;
}
@media screen and (max-width: 640px) {
  .internal-navigation .wrapper-nav .burger-menu {
    width: 37px;
    height: 17px;
    flex-direction: column;
    justify-content: space-around;
    margin-left: unset;
    position: relative;
    z-index: 10000;
    margin-left: auto;
  }
}
@media screen and (max-width: 640px) {
  .internal-navigation .wrapper-nav .burger-menu.burger-clicked .top-bun {
    transform: translateY(0.5px) rotate(45deg);
  }
}
@media screen and (max-width: 640px) {
  .internal-navigation .wrapper-nav .burger-menu.burger-clicked .bottom-bun {
    transform: translateY(-7.5px) rotate(-45deg);
  }
}
@media screen and (max-width: 640px) {
  .internal-navigation .wrapper-nav .burger-menu .top-bun,
  .internal-navigation .wrapper-nav .burger-menu .bottom-bun {
    width: 100%;
    border-bottom: 3px solid #fff;
    transition: all ease-in-out 0.125s;
  }
}
@media screen and (max-width: 640px) {
  .internal-navigation .wrapper-nav .wrapper-menu {
    display: none;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, calc(100% + 1.25rem));
    background-color: #00008d;
    width: 100vw;
    padding: 0 1.75rem;
  }
}
@media screen and (max-width: 640px) {
  .internal-navigation .wrapper-nav .wrapper-menu ul {
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
  }
}
@media screen and (max-width: 640px) {
  .internal-navigation .wrapper-nav .wrapper-menu ul li {
    width: 100%;
    padding: 0.75rem 0;
    margin: unset;
    border-bottom: 0.5px solid #fff;
    text-align: left;
    font-size: 2rem;
  }
}
@media screen and (max-width: 640px) {
  .internal-navigation .wrapper-nav .wrapper-menu ul li.hide-on-xs {
    display: none;
  }
}
@media screen and (max-width: 640px) {
  .internal-navigation .wrapper-nav .wrapper-menu ul li:last-of-type, .internal-navigation .wrapper-nav .wrapper-menu ul li:nth-last-of-type(2) {
    border-bottom: unset;
    padding: 1.25rem 0;
  }
}
.internal-navigation .wrapper-nav .wrapper-menu ul li a {
  color: #3DFFFF;
}
.internal-navigation .wrapper-nav .wrapper-menu ul li a:hover {
  color: #FF175B !important;
}
.internal-navigation .wrapper-nav .wrapper-menu ul li .logged-menu {
  padding-top: 0.5rem;
  margin-bottom: -1rem;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between !important;
}
.internal-navigation .wrapper-nav .wrapper-menu ul li .logged-menu:hover {
  cursor: pointer;
}
.internal-navigation .wrapper-nav .wrapper-menu ul li .logged-menu li {
  text-align: left;
  line-height: 1;
}
.internal-navigation .wrapper-nav .wrapper-menu ul li .logged-menu li.my-id {
  text-align: right;
}
.internal-navigation .wrapper-nav .wrapper-menu ul li .logged-menu li a {
  color: #FFF;
  display: block;
  width: 100%;
}
.internal-navigation .wrapper-nav .wrapper-menu ul li .logged-menu li span {
  display: block;
  margin-top: 0.25rem;
  width: 100%;
  font-size: 0.75rem;
  font-weight: 400;
  /*&.current-wallet {
    &:after {
      content: "...";
    }
  }*/
}
.internal-navigation .wrapper-nav .wrapper-menu ul li .logged-menu li:hover a {
  color: #FF175B !important;
}
.internal-navigation .btn.wallet.connected {
  position: absolute;
  top: 0;
  right: calc((100vw - 1290px) / 2);
  height: 30px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  /*@media screen and (max-width: 1180px) {
    right: calc((100vw - 942px) / 2);
  }*/
}
@media screen and (max-width: 1366px) {
  .internal-navigation .btn.wallet.connected {
    right: calc((100vw - 1122px) / 2);
  }
}
@media screen and (max-width: 1180px) {
  .internal-navigation .btn.wallet.connected {
    right: calc((100vw - 942px) / 2);
  }
}
@media screen and (max-width: 992px) {
  .internal-navigation .btn.wallet.connected {
    right: 2rem;
  }
}
@media screen and (max-width: 640px) {
  .internal-navigation .btn.wallet.connected {
    top: -1rem;
    right: 1rem;
    left: unset;
  }
}

.btn-open-mobile {
  position: absolute;
  top: 50%;
  right: 2rem;
  transform: translateY(-50%);
  font-size: 3rem;
  color: #FFF;
  cursor: pointer;
  display: none;
}
@media screen and (max-width: 660px) {
  .btn-open-mobile {
    display: block;
  }
}

.panel-mobile-menu {
  position: fixed;
  z-index: 20000;
  width: 100%;
  background-color: #FFF;
  background-color: #00008d;
  height: 100vh;
  transform: translateX(-100%);
  transition: all 0.4s ease-in-out;
}
.panel-mobile-menu .wrapper-logo {
  position: absolute;
  width: 100px;
  left: 50%;
  transform: translate(-50%, 0);
  top: 2rem;
}
.panel-mobile-menu.open {
  transform: translateX(0);
}
.panel-mobile-menu .close-btn {
  font-size: 2.6rem;
  color: #FFF;
  position: absolute;
  top: 2rem;
  right: 2rem;
}
.panel-mobile-menu ul {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 100vh;
  font-weight: bold;
}
.panel-mobile-menu ul li {
  text-transform: uppercase;
  font-size: 2rem;
  padding: 0.5rem 0;
}
.panel-mobile-menu ul li a {
  text-decoration: none;
  color: #FFF;
}

@property --p {
  syntax: "<number>";
  inherits: true;
  initial-value: 0;
}
.list-statistic-pie {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  box-sizing: border-box;
  max-width: 100%;
  padding: 1.5rem 0;
  border-top: 0.5px solid #E5E5F3;
  margin: 0 0 1.25rem;
}
@media screen and (max-width: 1180px) {
  .list-statistic-pie {
    padding: 1rem 0;
  }
}
@media screen and (max-width: 820px) {
  .list-statistic-pie {
    max-width: calc(100% - 1.875rem);
    marign-right: auto;
  }
}
@media screen and (max-width: 819px) {
  .list-statistic-pie {
    max-width: unset;
    marign-right: unset;
  }
}
@media screen and (max-width: 640px) {
  .list-statistic-pie {
    flex-wrap: nowrap;
    justify-content: space-between;
    width: calc(100% + 1rem);
    /*margin-left: -.5rem;
    margin-right: -.5rem;*/
    padding-top: 1.75rem;
  }
}
@media screen and (max-width: 576px) {
  .list-statistic-pie {
    flex-wrap: wrap;
    justify-content: center;
  }
}
.list-statistic-pie .single-pie {
  flex: 0 0 calc(145px + 2rem);
  box-sizing: border-box;
  padding: 1rem;
}
@media screen and (max-width: 1440px) {
  .list-statistic-pie .single-pie {
    flex: 0 0 calc(125px + 2rem);
  }
}
@media screen and (max-width: 640px) {
  .list-statistic-pie .single-pie {
    padding: 0.5rem;
  }
}
@media screen and (max-width: 576px) {
  .list-statistic-pie .single-pie {
    padding: 1rem;
  }
}
.list-statistic-pie .single-pie .label {
  font-family: acumin-pro, sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 1.625rem;
  color: #00008d;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 0.625rem;
}
@media screen and (max-width: 1180px) {
  .list-statistic-pie .single-pie .label {
    font-family: acumin-pro, sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 1.3rem;
  }
}

.wrapper-pie {
  position: relative;
  overflow: hidden;
  height: 145px;
  margin-bottom: 0.625rem;
}
@media screen and (max-width: 1440px) {
  .wrapper-pie {
    height: 125px;
  }
}
@media screen and (max-width: 1180px) {
  .wrapper-pie {
    height: 100px;
  }
}
@media screen and (max-width: 640px) {
  .wrapper-pie {
    height: 90px;
  }
}

.pie {
  --p: 20;
  --b: 22px;
  --c: darkred;
  --w: 145px;
  width: var(--w);
  aspect-ratio: 1;
  position: relative;
  display: inline-grid;
  margin: 0;
  place-content: center;
  font-size: 1.562rem;
  font-weight: bold;
  font-family: sans-serif;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  line-height: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 1440px) {
  .pie {
    --w: 125px;
  }
}
@media screen and (max-width: 1180px) {
  .pie {
    --b: 18px;
    --w: 100px;
  }
}
@media screen and (max-width: 992px) {
  .pie {
    --w: 100px;
  }
}
@media screen and (max-width: 640px) {
  .pie {
    --b: 16px;
    --w: 90px;
  }
}
@media screen and (max-width: 1180px) {
  .pie {
    font-size: 1.25rem;
  }
}
.pie .point-gains {
  font-size: 1rem;
  line-height: 1;
  color: #00008d;
}

.pie:before,
.pie:after {
  content: "";
  position: absolute;
  border-radius: 50%;
}

.pie:before {
  inset: 0;
  background: radial-gradient(farthest-side, var(--c) 98%, rgba(0, 0, 0, 0)) top/var(--b) var(--b) no-repeat, conic-gradient(var(--c) calc(var(--p) * 1%), rgba(0, 0, 0, 0) 0);
  -webkit-mask: radial-gradient(farthest-side, rgba(0, 0, 0, 0) calc(99% - var(--b)), #000 calc(100% - var(--b)));
  mask: radial-gradient(farthest-side, rgba(0, 0, 0, 0) calc(99% - var(--b)), #000 calc(100% - var(--b)));
}

.pie:after {
  inset: calc(50% - var(--b) / 2);
  background: var(--c);
  transform: rotate(calc(var(--p) * 3.6deg)) translateY(calc(50% - var(--w) / 2));
}

.animate {
  animation: p 1s 0.5s both;
}

.no-round:before {
  background-size: 0 0, auto;
}

.no-round:after {
  content: none;
}

@keyframes p {
  from {
    --p: 0 ;
  }
}
.wrapper-info {
  padding: 1rem;
}
.wrapper-info.has-bg-light {
  background-color: #F2F2F9;
}

.modal-wrapper .body-modal .wrapper-info.has-bg-light {
  flex: 0 1 63.5%;
  padding-right: 1.875rem;
  max-width: calc(63.5% - 1.875rem);
}
@media screen and (max-width: 820px) {
  .modal-wrapper .body-modal .wrapper-info.has-bg-light {
    max-width: unset;
  }
}
@media screen and (max-width: 376px) {
  .modal-wrapper .body-modal .wrapper-info .feature .feature-get {
    transform: scale(0.85) translate(2px, 0);
    transform-origin: left;
  }
}
.modal-wrapper .body-modal .btn-show-details-cont {
  flex: 0 1 63.5%;
  padding-right: 1.875rem;
  max-width: calc(63.5% - 1.875rem);
}
@media screen and (max-width: 991px) {
  .modal-wrapper .body-modal .btn-show-details-cont {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: unset;
    padding-right: unset;
  }
}

.btn-up {
  position: fixed;
  z-index: 100;
  color: red;
  font-size: 2rem;
  line-height: 30px;
  right: 1rem;
  z-index: 10001;
  bottom: 2rem;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  background-color: #FFF;
  color: #00008d;
  text-align: center;
  opacity: 0;
}

@media screen and (max-width: 640px) {
  .roadmap .wrapper-content .accordion-learn-more {
    margin-bottom: 1.5rem;
  }
}
.roadmap .wrapper-content .accordion-learn-more .accordion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem 0.6rem;
  background-color: #ceceec;
}
.roadmap .wrapper-content .accordion-learn-more .accordion-header.accordion-open .fa-angle-down {
  transform: rotate(-180deg);
}
.roadmap .wrapper-content .accordion-learn-more .accordion-header:hover {
  cursor: pointer;
}
.roadmap .wrapper-content .accordion-learn-more .accordion-header h3 {
  margin-bottom: unset;
  color: #00008d;
  font-family: acumin-pro, sans-serif;
  font-weight: 800;
  font-style: normal;
  line-height: 1;
  text-transform: uppercase;
}
.roadmap .wrapper-content .accordion-learn-more .accordion-header .fa-angle-down {
  color: #00008d;
  font-size: 2rem;
  transition: all ease-in-out 0.2s;
}
.roadmap .wrapper-content .accordion-learn-more .accordion-content {
  padding-top: 1rem;
}
.roadmap .wrapper-content .accordion-learn-more .accordion-content > * {
  font-family: acumin-pro, sans-serif;
  font-weight: 500;
  color: #00008d;
}
.roadmap .wrapper-content .accordion-learn-more .accordion-content p, .roadmap .wrapper-content .accordion-learn-more .accordion-content ul, .roadmap .wrapper-content .accordion-learn-more .accordion-content ol, .roadmap .wrapper-content .accordion-learn-more .accordion-content li {
  font-size: 1rem;
}
.roadmap .wrapper-content .accordion-learn-more .accordion-content ul, .roadmap .wrapper-content .accordion-learn-more .accordion-content ol {
  padding-left: 2rem;
  margin-bottom: 1.5rem;
  flex-direction: column;
  align-items: flex-start;
}
.roadmap .wrapper-content .accordion-learn-more .accordion-content ul {
  list-style: disc;
}
.roadmap .wrapper-content .accordion-learn-more .accordion-content li {
  margin-bottom: 0.85rem;
}
.roadmap .wrapper-content .accordion-learn-more .accordion-content li:last-of-type {
  margin-bottom: unset;
}
.roadmap .wrapper-content .accordion-learn-more .accordion-content h4, .roadmap .wrapper-content .accordion-learn-more .accordion-content h5 {
  text-transform: uppercase;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 0.85rem;
}
.roadmap .wrapper-content .accordion-learn-more .accordion-content h4 {
  font-size: 1.125rem;
}
.roadmap .wrapper-content .accordion-learn-more .accordion-content h5 {
  font-size: 1.025rem;
  margin-bottom: 0.85rem;
}

.accordion-table, .p-internal-tb {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 1.5rem;
  color: #00008d;
}
@media screen and (max-width: 820px) {
  .accordion-table.table-grow .t-block, .p-internal-tb.table-grow .t-block {
    flex: 0.5;
    width: 33.3333333333%;
  }
}
@media screen and (max-width: 820px) {
  .accordion-table.table-grow .t-block .t-header, .p-internal-tb.table-grow .t-block .t-header {
    min-height: 27.5px;
  }
}
.accordion-table.table-grow .t-block .t-header:first-of-type, .p-internal-tb.table-grow .t-block .t-header:first-of-type {
  padding-left: unset;
  padding-right: unset;
}
@media screen and (max-width: 820px) {
  .accordion-table.table-grow .t-block .t-row .t-col, .p-internal-tb.table-grow .t-block .t-row .t-col {
    min-height: 21.3px;
  }
}
@media screen and (max-width: 820px) {
  .accordion-table.table-grow .t-block h4, .accordion-table.table-grow .t-block h5, .p-internal-tb.table-grow .t-block h4, .p-internal-tb.table-grow .t-block h5 {
    font-size: 0.65rem;
    line-height: 1;
  }
}
.accordion-table h4, .accordion-table h5, .p-internal-tb h4, .p-internal-tb h5 {
  font-size: 0.8rem;
  line-height: 1.6;
  margin-bottom: unset;
}
.accordion-table .t-block, .p-internal-tb .t-block {
  flex: 1 1 50%;
}
.accordion-table .t-block:nth-of-type(even), .p-internal-tb .t-block:nth-of-type(even) {
  background-color: #f2f2f9;
}
.accordion-table .t-block:nth-of-type(even) .t-row:nth-of-type(odd), .p-internal-tb .t-block:nth-of-type(even) .t-row:nth-of-type(odd) {
  background-color: #eaeaf7;
}
.accordion-table .t-block.txt-center .t-col, .accordion-table .t-block.txt-center h4, .accordion-table .t-block.txt-center h5, .p-internal-tb .t-block.txt-center .t-col, .p-internal-tb .t-block.txt-center h4, .p-internal-tb .t-block.txt-center h5 {
  text-align: center;
}
.accordion-table .t-block.txt-center .t-col, .p-internal-tb .t-block.txt-center .t-col {
  justify-content: center;
}
.accordion-table .t-block.t-col-grow .t-row .t-col, .p-internal-tb .t-block.t-col-grow .t-row .t-col {
  flex-grow: 1;
}
.accordion-table .t-block .t-header, .p-internal-tb .t-block .t-header {
  text-align: center;
  padding: 0.5rem 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ceceec;
}
.accordion-table .t-block .t-header h5, .p-internal-tb .t-block .t-header h5 {
  margin-bottom: unset;
}
.accordion-table .t-block .t-header .t-col, .p-internal-tb .t-block .t-header .t-col {
  flex: 1;
}
.accordion-table .t-block .t-row, .p-internal-tb .t-block .t-row {
  display: flex;
}
.accordion-table .t-block .t-row:nth-of-type(odd), .p-internal-tb .t-block .t-row:nth-of-type(odd) {
  background-color: #f2f2f9;
}
.accordion-table .t-block .t-row .t-col, .p-internal-tb .t-block .t-row .t-col {
  font-size: 0.85rem;
  flex: 0.5;
  display: flex;
  align-items: center;
  padding: 0.25rem 1rem;
  word-break: keep-all;
  white-space: nowrap;
}
@media screen and (max-width: 1180px) {
  .accordion-table .t-block .t-row .t-col, .p-internal-tb .t-block .t-row .t-col {
    flex: auto;
    width: 16.6666666667%;
  }
}
.accordion-table .t-block .t-row .t-col.row-head, .p-internal-tb .t-block .t-row .t-col.row-head {
  flex: 2;
}
@media screen and (max-width: 1180px) {
  .accordion-table .t-block .t-row .t-col.row-head, .p-internal-tb .t-block .t-row .t-col.row-head {
    flex: auto;
    width: 33.3333333333%;
  }
}
.accordion-table .t-block .t-row .t-col.eth, .p-internal-tb .t-block .t-row .t-col.eth {
  display: flex;
  flex: 2;
}
@media screen and (max-width: 1180px) {
  .accordion-table .t-block .t-row .t-col.eth, .p-internal-tb .t-block .t-row .t-col.eth {
    flex: auto;
    width: 50%;
  }
}
.accordion-table .t-block .t-row .t-col.eth:before, .p-internal-tb .t-block .t-row .t-col.eth:before {
  content: "";
  width: 25px;
  height: 25px;
  margin-right: 5px;
  background-image: url("~assets/eth.svg");
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: center;
}

.p-internal-tb-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-left: unset;
  padding-right: unset;
}
.p-internal-tb-wrap .p-internal-tb {
  flex-grow: 0;
  width: calc(50% - 0.75rem);
}
@media screen and (max-width: 640px) {
  .p-internal-tb-wrap .p-internal-tb {
    flex-grow: 1;
    width: 100%;
    margin-bottom: 2.75rem;
  }
}
@media screen and (max-width: 640px) {
  .p-internal-tb-wrap .p-internal-tb:last-of-type {
    margin-bottom: unset;
  }
}
.p-internal-tb-wrap .p-internal-tb .t-block {
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 640px) {
  .p-internal-tb-wrap .p-internal-tb .t-block {
    min-height: 125px;
  }
}
.p-internal-tb-wrap .p-internal-tb .t-header h3 {
  font-family: acumin-pro, sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 1.3rem;
  font-style: normal;
  color: #00008d;
  text-transform: uppercase;
  margin-bottom: unset;
}
.p-internal-tb-wrap .p-internal-tb .msg-no-matches {
  flex-grow: 1;
  background-color: rgba(255, 23, 91, 0.1882352941);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.p-internal-tb-wrap .p-internal-tb .msg-no-matches h4 {
  font-size: 1.125rem;
  margin-top: unset;
  margin-bottom: 1.25rem;
  color: #FF175B;
}

.player-not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 55vh;
}
.player-not-found .wrapper-content .wrapper-text h2 {
  color: #FF175B;
  font-family: acumin-pro, sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 3.5rem;
  line-height: 0.9;
  text-transform: uppercase;
  margin: 0 0 1rem;
}
.player-not-found .wrapper-content .wrapper-text p {
  font-size: 1.225rem;
  line-height: 1.25;
}

.roadmap.intro-msg .wrapper-content {
  padding-left: unset;
  border-left: unset;
  margin-bottom: 0.25rem;
  text-align: center;
}
.roadmap.intro-msg .wrapper-content .wrapper-text h2 {
  color: #FF175B;
}
.roadmap.intro-msg .wrapper-content .wrapper-text p {
  line-height: 1.125;
}

.layer-cc {
  position: fixed;
  z-index: 999999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
}
.layer-cc .layer-content {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 992px;
  background-color: #fff;
  width: auto;
  border-radius: 0.75rem;
  display: flex;
  padding: 5rem 3rem;
}
@media screen and (min-width: 1440px) {
  .layer-cc .layer-content {
    max-height: calc(90% - 7rem);
    padding: 3rem;
  }
}
@media screen and (max-width: 992px) {
  .layer-cc .layer-content {
    max-width: 90%;
    padding: 3rem 1.75rem;
  }
}
.layer-cc .layer-content .btn-close {
  position: absolute;
  top: -1rem;
  right: -0.5rem;
  width: 3rem;
  height: 3rem;
  background: unset;
  font-size: 3.5rem;
  color: #fff;
  line-height: 0;
  transform: translate(0, -100%);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
}
.layer-cc .layer-content .btn-close:hover {
  cursor: pointer;
}
.layer-cc .layer-content .layer-cc-text {
  width: 100%;
  height: 100%;
  padding-left: 4rem;
  padding-right: 4rem;
  border-left: 10px solid #FF175B;
  border-right: 10px solid #FF175B;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: stretch;
}
@media screen and (min-width: 1440px) {
  .layer-cc .layer-content .layer-cc-text {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media screen and (max-width: 820px) {
  .layer-cc .layer-content .layer-cc-text {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media screen and (max-width: 640px) {
  .layer-cc .layer-content .layer-cc-text {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    border-left: unset;
    border-right: unset;
  }
}
.layer-cc .layer-content .layer-cc-text h2 {
  font-family: acumin-pro, sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 2.25rem;
  line-height: 1;
  color: #FF175B;
  text-transform: uppercase;
  margin: 0 0 1rem;
  text-align: center;
}
@media screen and (max-width: 820px) {
  .layer-cc .layer-content .layer-cc-text h2 {
    font-size: 2rem;
  }
}
@media screen and (max-width: 640px) {
  .layer-cc .layer-content .layer-cc-text h2 {
    font-size: 1.75rem;
  }
}
.layer-cc .layer-content .layer-cc-text p {
  font-size: 1.125rem;
  color: #00008d;
  margin-top: 0;
  line-height: 1.15;
  text-align: center;
  margin-bottom: unset;
}
.layer-cc .layer-content .layer-cc-text .player-info {
  width: 100%;
  padding: 1.25rem;
  text-align: center;
  font-size: 1.5rem;
  line-height: 1.125;
  text-transform: uppercase;
  color: #00008d;
  font-weight: 700;
  background-color: #f2f2f9;
  margin: 1.5rem auto;
  border-left: 5px solid #00008d;
  border-right: 5px solid #00008d;
}
@media screen and (max-width: 820px) {
  .layer-cc .layer-content .layer-cc-text .player-info {
    font-size: 1.25rem;
  }
}
.layer-cc .layer-content .layer-cc-text .player-info .paypal-credentials {
  font-size: 1rem;
  text-transform: none;
  margin-bottom: 0.85rem;
}
.layer-cc .layer-content .layer-cc-text .player-info .paypal-credentials p {
  margin-bottom: 1rem;
  font-size: 1rem;
}
@media screen and (max-width: 640px) {
  .layer-cc .layer-content .layer-cc-text .player-info .paypal-credentials p {
    margin-bottom: 0.25rem;
  }
}
.layer-cc .layer-content .layer-cc-text .player-info .paypal-credentials .single-cred {
  margin-bottom: 0.25rem;
  font-size: 0.85rem;
}
.layer-cc .layer-content .layer-cc-text .player-info .paypal-credentials .single-cred span {
  font-size: 0.85rem;
  font-weight: 400;
}
.layer-cc .layer-content .layer-cc-text .wallet-form p {
  margin-bottom: 0.5rem;
}
.layer-cc .layer-content .layer-cc-text input[type=text] {
  height: 37px;
  width: 100%;
  border: 1.5px solid rgba(0, 0, 141, 0.1);
  border-radius: unset;
  margin-bottom: 1rem;
  font-family: acumin-pro, sans-serif;
  font-weight: 500;
  font-size: 1.25rem;
  text-align: center;
  display: flex;
  align-items: center;
  padding: 0 1.25rem 0.25rem;
}
.layer-cc .layer-content .layer-cc-text input[type=text]:placeholder {
  font-family: acumin-pro, sans-serif;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1;
}
.layer-cc .layer-content .layer-cc-text .layer-cc-send {
  width: 100%;
  max-width: unset;
}
@media screen and (max-width: 640px) {
  .layer-cc .layer-content .layer-cc-text .layer-cc-send {
    font-size: 1rem;
    padding-left: 0.95rem;
  }
}
.layer-cc .layer-content .layer-cc-img {
  flex: 1 1 40%;
  width: 40%;
  text-align: center;
  border-right: 10px solid #FF175B;
  padding: 0 4rem;
  margin-top: unset;
  margin-bottom: unset;
  display: flex;
  align-items: center;
  align-self: stretch;
}
@media screen and (min-width: 1440px) {
  .layer-cc .layer-content .layer-cc-img {
    padding: 0 2rem;
  }
}

.player-not-available-msg {
  padding: 1rem 1rem 0.5rem;
  margin-top: 1.5rem;
  background-color: #F2F2F9;
}

@media screen and (max-width: 640px) {
  .accordion-learn-more {
    margin-bottom: 1.5rem;
  }
}
.accordion-learn-more .accordion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem 0.6rem;
  background-color: #ceceec;
}
.accordion-learn-more .accordion-header.accordion-open .fa-angle-down {
  transform: rotate(-180deg);
}
.accordion-learn-more .accordion-header:hover {
  cursor: pointer;
}
.accordion-learn-more .accordion-header h3 {
  margin-bottom: unset;
  color: #00008d;
  font-family: acumin-pro, sans-serif;
  font-weight: 800;
  font-style: normal;
  line-height: 1;
  text-transform: uppercase;
}
.accordion-learn-more .accordion-header .fa-angle-down {
  color: #00008d;
  font-size: 2rem;
  transition: all ease-in-out 0.2s;
}
.accordion-learn-more .accordion-content {
  padding-top: 1rem;
}
.accordion-learn-more .accordion-content > * {
  font-family: acumin-pro, sans-serif;
  font-weight: 500;
  color: #00008d;
}
.accordion-learn-more .accordion-content p, .accordion-learn-more .accordion-content ul, .accordion-learn-more .accordion-content ol, .accordion-learn-more .accordion-content li {
  font-size: 1rem;
}
.accordion-learn-more .accordion-content ul, .accordion-learn-more .accordion-content ol {
  padding-left: 2rem;
  margin-bottom: 1.5rem;
  flex-direction: column;
  align-items: flex-start;
}
.accordion-learn-more .accordion-content ul {
  list-style: disc;
}
.accordion-learn-more .accordion-content li {
  margin-bottom: 0.85rem;
}
.accordion-learn-more .accordion-content li:last-of-type {
  margin-bottom: unset;
}
.accordion-learn-more .accordion-content h4, .accordion-learn-more .accordion-content h5 {
  text-transform: uppercase;
  font-weight: 700;
  line-height: 1;
}
.accordion-learn-more .accordion-content h4 {
  font-size: 1.125rem;
}
.accordion-learn-more .accordion-content h5 {
  font-size: 1.025rem;
}

.wrapper-faq .accordion-learn-more {
  margin: 1rem 0;
}
.wrapper-faq .accordion-learn-more p {
  line-height: 1.75;
}

@media screen and (max-width: 660px) {
  .img-history {
    padding: 4rem;
  }
}

.team {
  margin-bottom: 4rem;
}
.team .title-decoration.blue {
  border-top-color: #FFF;
  border-bottom-color: #FFF;
}
.team .wrapper-title-wrap h2 {
  color: #FFF;
}
.team .wrapper-title-wrap.bg-pattern-azure::before {
  background-image: url("~assets/bg-red.svg");
}
.team .single-team {
  text-align: center;
}
.team .single-team .wrapper-img {
  padding: 1rem 2rem;
}
.team .single-team .role {
  color: #00008d;
}

.btn.btn-twitter-community {
  padding: 0.5rem 1.85rem;
  border-radius: 0.125rem;
  background-color: #00008d;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn.btn-twitter-community i {
  margin-right: 0.5rem;
}

.wrapper-btn {
  display: flex;
  align-items: center;
}

.btn {
  border-radius: 10px;
  max-width: 100%;
  text-align: center;
  padding: 0 15px;
  text-transform: uppercase;
  color: #FFF;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  box-sizing: border-box;
  text-decoration: none;
}
.btn.centerd {
  margin: 0 auto;
}
.btn.btn-show-details {
  margin: 0 auto;
}
.btn.wallet {
  font-family: acumin-pro, sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 1.25rem;
  background-color: #3DFFFF;
  color: #00008d;
  height: 43px;
  line-height: 43px;
}
@media screen and (max-width: 992px) {
  .btn.wallet {
    line-height: 1;
  }
}
.btn.wallet::after {
  content: "";
  width: 20px;
  height: 20px;
  background-image: url("~assets/wallet.svg");
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  vertical-align: middle;
  margin: 0 5px 0 10px;
}
.btn.centered {
  margin: 0 auto;
}
.btn.secondary {
  font-family: acumin-pro, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 1.6rem;
  transition: all 0.4s ease-in-out;
  border-radius: 5px;
  font-size: 1.6875rem;
  line-height: 1;
  padding: 5px 15px 6px;
  letter-spacing: 3px;
}
.btn.secondary:hover {
  color: #FFF;
}
.btn.secondary:hover::after {
  transform: translate(5px, 1.5px);
}
.btn.secondary.no-arrow::after {
  display: none;
}
.btn.secondary::after {
  content: "";
  width: 1.25rem;
  height: 1.25rem;
  background-image: url("~assets/arrow-btn.svg");
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0 0 0 1rem;
  transform: translate(0, 1.5px);
  display: inline-block;
  vertical-align: middle;
  transition: all 0.125s ease-in-out;
}
.btn.secondary.red {
  background-color: #FF175B;
}
.btn.secondary.blue {
  background-color: #00008d;
}
.btn.secondary.blue-light {
  background-color: #3DFFFF;
  color: #00008d;
}
.btn.secondary.blue-light::after {
  background-image: url("~assets/arrow-blue.svg");
}
.btn.full {
  width: 100%;
}
.btn.small {
  font-size: 1.5625rem;
}
.btn.xs {
  height: 2.375rem;
  line-height: 2.375rem;
  font-size: 1rem;
}
.btn.has-mb {
  margin-bottom: 3.125rem;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  position: relative;
  scroll-behavior: smooth;
  font-family: acumin-pro, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
}
@media screen and (max-width: 1440px) {
  html,
  body {
    font-size: 14px;
  }
}
@media screen and (max-width: 1400px) {
  html,
  body {
    font-size: 12px;
  }
}

body {
  overflow-x: hidden;
}

.preloader-app {
  z-index: 15000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  text-align: center;
}
.preloader-app .preloader-container {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  font-size: 1.25rem;
  background-color: #FFFFFF;
  color: #000000;
  padding: 2rem;
  border-radius: 50%;
}
.preloader-app .preloader-container .clip-loader {
  display: inline-block;
}
.preloader-app .preloader-container .text-loading {
  position: relative;
  margin-top: 32px;
  width: 320px;
}
.preloader-app .preloader-container .text-loading .text-shadow {
  font-size: 21px !important;
  line-height: 1.4;
}

.vhelper {
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}

img {
  max-width: 100%;
  width: 100%;
  object-fit: contain;
  margin: 0 auto;
}

ul {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
ul.flex-start {
  justify-content: flex-start;
}
ul.flex-end {
  justify-content: flex-end;
}

.borderd {
  position: relative;
}
.borderd:before {
  content: "";
  height: 100%;
  width: 3px;
  background-color: #00008d;
  position: absolute;
  top: 0;
  left: 7.5px;
}

.decoration img {
  max-width: 100%;
  width: 100%;
  height: 110px;
  object-fit: cover;
  object-position: top;
}
@media screen and (max-width: 1440px) {
  .decoration img {
    height: 80px;
  }
}
.decoration.bottom {
  position: absolute;
  bottom: 0;
}
.decoration.bottom img {
  transform: rotate(180deg);
  object-position: bottom;
  margin-bottom: -40px;
}
@media screen and (max-width: 1440px) {
  .decoration.bottom img {
    margin-bottom: -20px;
  }
}

header {
  background: radial-gradient(#3DFFFF, #00008d);
  height: 100vh;
  padding-bottom: 2.5rem;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  /*
    @media screen and (max-width:992px) {
      height: 50vh;
      padding: 2rem 1rem;
    }
  */
}
header .container {
  height: calc(100% - 156px - 100px);
}
header .row, header .col-lg-12, header .content-header {
  height: 100%;
}
header .wrapper-carousel {
  margin-right: auto;
  margin-left: auto;
  position: relative;
  overflow: hidden;
  width: 100vw;
  left: 50%;
  transform: translateX(-50%);
}
@media screen and (max-width: 992px) {
  header .wrapper-carousel {
    width: 100vw;
    left: 50%;
    transform: translateX(-50%);
  }
}
@media only screen and (min-width: 1440px) and (max-width: 1536px) and (min-device-pixel-ratio: 1.25) {
  header .wrapper-carousel {
    width: 85%;
    left: unset;
    transform: none;
  }
}
@media only screen and (min-width: 1440px) and (max-width: 1536px) and (-webkit-min-device-pixel-ratio: 1.25) {
  header .wrapper-carousel {
    width: 85%;
    left: unset;
    transform: none;
  }
}
@media only screen and (min-width: 1440px) and (max-width: 1536px) and (min--moz-device-pixel-ratio: 1.25) {
  header .wrapper-carousel {
    width: 85%;
    left: unset;
    transform: none;
  }
}
header .wrapper-carousel .swiper {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  /*overflow: hidden;*/
  list-style: none;
  padding: 0 0 1.75rem;
  z-index: 1;
  height: 100%;
  /*@media screen and (min-width: ) and (max-width: ) {
    padding-bottom: unset;
  }*/
}
@media screen and (max-width: 768px) {
  header .wrapper-carousel .swiper {
    padding: 0 0 3rem;
  }
}
header .wrapper-carousel .swiper .swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: border-box;
}
header .wrapper-carousel .swiper .swiper-slide {
  background-position: center;
  background-size: cover;
}
header .wrapper-carousel .swiper .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
header .wrapper-carousel .swiper-container-3d .swiper-slide-shadow-left, header .wrapper-carousel .swiper-container-3d .swiper-slide-shadow-right {
  background-image: none;
}
header .wrapper-carousel img {
  display: block;
  margin: 0 auto;
  /*width: 90%;*/
  -webkit-box-reflect: below 5px -webkit-linear-gradient(bottom, rgba(255, 0, 0, 0.1) 25%, transparent 32px, transparent 100%);
  z-index: 0;
  position: relative;
  /*
    @media screen and (max-width:1660px) {
        width: 75%;
    }
  */
}
@media screen and (max-width: 1440px) {
  header .wrapper-carousel img {
    width: 70%;
  }
}
header .content-header {
  /*position: absolute;
  top: 50%;
  left: 50%;
  bottom: 14%;
  transform: translate(-50%, 0);
  transform: translate(-50%, -50%);*/
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
header .content-header .wrapper-title {
  /*position: relative;
  z-index: 10;
  margin-top: -30px;*/
  width: 100%;
}
header .content-header .wrapper-title h1 {
  font-family: acumin-pro, sans-serif;
  font-weight: 900;
  font-style: normal;
  font-size: 7.5rem;
  line-height: 1;
  color: #FFF;
  text-transform: uppercase;
  margin-bottom: 2.5rem;
  text-shadow: 0px 6px 22px rgba(255, 255, 255, 0.9);
}
@media screen and (max-width: 992px) {
  header .content-header .wrapper-title h1 {
    font-size: 6rem;
  }
}
@media screen and (max-width: 640px) {
  header .content-header .wrapper-title h1 {
    line-height: 1;
  }
}
@media screen and (max-width: 1280px) and (max-height: 640px) {
  header .content-header .wrapper-title h1 {
    font-size: 4.5rem;
    margin-bottom: 1rem;
  }
}

.wrapper-card {
  margin: 3.75rem 0 2.25rem;
}
@media screen and (max-width: 640px) {
  .wrapper-card {
    margin: 1.5rem 0;
  }
}
.wrapper-card .single-card {
  margin: 1.5rem 0;
}
.wrapper-card .single-card .img-card {
  margin: 0 0 1.25rem;
}
.wrapper-card .single-card .img-card img {
  width: 100%;
}

.internal .mistery-player {
  margin-top: unset;
  background-image: unset;
}

.mistery-player {
  background-color: #000;
  background-image: url("~assets/bg-mistery.svg");
  background-repeat: repeat;
  background-size: auto;
  color: #FFF;
  margin: 0 0;
  position: relative;
}
.mistery-player .wrapper-content {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  padding: 7.375rem 0 6rem;
  margin: 2rem auto;
  overflow: hidden;
  /*@media screen and (max-width: 768px) {
    border-left: none;
    border-right: none;
    margin: 2rem auto;
  }*/
}
@media screen and (max-width: 1280px) {
  .mistery-player .wrapper-content {
    padding: 4rem 0 3rem;
  }
}
@media screen and (max-width: 768px) {
  .mistery-player .wrapper-content {
    padding: 3rem 0 5rem;
    flex-wrap: wrap;
  }
}
.mistery-player .wrapper-content .wrapper-text, .mistery-player .wrapper-content .wrapper-card {
  align-self: stretch;
}
.mistery-player .wrapper-content .wrapper-text {
  flex: 1 1 60%;
  width: 60%;
  padding-left: 6.25rem;
  border-left: 10px solid #FF175B;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media screen and (max-width: 820px) {
  .mistery-player .wrapper-content .wrapper-text {
    width: 50%;
    flex: 1 1 50%;
  }
}
@media screen and (max-width: 768px) {
  .mistery-player .wrapper-content .wrapper-text {
    flex: 1 1 100%;
    width: auto;
    order: 2;
    border-left: unset;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.mistery-player .wrapper-content .wrapper-text .txt-fit-holder {
  margin-left: unset;
}
.mistery-player .wrapper-content .wrapper-text h2 {
  font-family: acumin-pro, sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 4.5rem;
  line-height: 1;
  color: #FF175B;
  text-transform: uppercase;
  margin: 0 0 1rem;
  text-align: left;
}
@media screen and (max-width: 820px) {
  .mistery-player .wrapper-content .wrapper-text h2 {
    font-family: acumin-pro, sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 3rem;
    white-space: normal;
    word-break: normal;
  }
}
@media screen and (max-width: 768px) {
  .mistery-player .wrapper-content .wrapper-text h2 {
    font-family: acumin-pro, sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 4.5rem;
  }
}
.mistery-player .wrapper-content .wrapper-text p {
  color: #FFF;
  margin-top: 0;
  line-height: 1.35;
  margin-bottom: 1.5rem;
}
.mistery-player .wrapper-content .wrapper-text .btn.secondary {
  color: #000;
  letter-spacing: 2px;
}
.mistery-player .wrapper-content .wrapper-text .btn.secondary:after {
  background-image: url("~assets/arrow-btn-black.svg");
}
.mistery-player .wrapper-content .wrapper-card {
  flex: 1 1 40%;
  width: 40%;
  text-align: center;
  /*padding-right: 6.25rem;
  padding-left: 6.25rem;*/
  border-right: 10px solid #FF175B;
  margin-top: unset;
  margin-bottom: unset;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .mistery-player .wrapper-content .wrapper-card {
    flex: 1 1 100%;
    width: auto;
    order: 1;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: -1rem;
    border-right: unset;
  }
}
@media screen and (max-width: 768px) {
  .mistery-player .wrapper-content .wrapper-card img {
    max-width: 50%;
  }
}
@media screen and (max-width: 640px) {
  .mistery-player .wrapper-content .wrapper-card img {
    max-width: unset;
  }
}

.roadmap.with-bg {
  background-color: rgba(0, 0, 141, 0.1);
}
.roadmap.light .wrapper-content {
  border-left: 10px solid #3DFFFF;
}
@media screen and (max-width: 992px) {
  .roadmap.light .wrapper-content {
    border-left: 0;
  }
}
.roadmap.light .wrapper-content.txt-right {
  text-align: right;
  border-right: 10px solid #3DFFFF !important;
  border-left: 0;
}
.roadmap.light .wrapper-content .wrapper-text h2 {
  color: #3DFFFF;
}
.roadmap.green .wrapper-content {
  border-left: 10px solid #00FF8C;
}
@media screen and (max-width: 992px) {
  .roadmap.green .wrapper-content {
    border-left: 0;
  }
}
.roadmap.green .wrapper-content .wrapper-text h2 {
  color: #00FF8C;
}
.roadmap.red .wrapper-content {
  border-left: 10px solid #FF175B;
}
@media screen and (max-width: 992px) {
  .roadmap.red .wrapper-content {
    border-left: 0;
  }
}
.roadmap.red .wrapper-content .wrapper-text h2 {
  color: #FF175B;
}
.roadmap .wrapper-title-wrap.wrapper-title-roadmap {
  padding: 2.5rem 0;
}
.roadmap .wrapper-title-wrap.wrapper-title-roadmap .txt-fit-holder {
  /*@media screen and (max-width: 768px) {
    width: calc(100% - (12.5rem))
  }*/
}
@media screen and (max-width: 640px) {
  .roadmap .wrapper-title-wrap.wrapper-title-roadmap .txt-fit-holder {
    width: 100%;
  }
}
.roadmap .wrapper-title-wrap.wrapper-title-roadmap .txt-fit-holder .txt-fit {
  padding-top: unset;
}
@media screen and (max-width: 640px) {
  .roadmap .wrapper-title-wrap.wrapper-title-roadmap .txt-fit-holder .txt-fit {
    font-size: 5.25rem;
  }
}
.roadmap .wrapper-content {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  padding: 0 4rem;
  margin: 2rem auto;
  border-left: 10px solid #00008d;
}
.roadmap .wrapper-content.txt-right {
  text-align: right;
}
@media screen and (max-width: 992px) {
  .roadmap .wrapper-content {
    border-left: 0;
    padding: 0 2rem;
    margin: 2rem auto;
  }
}
@media screen and (max-width: 768px) {
  .roadmap .wrapper-content {
    padding: 0;
  }
}
@media screen and (max-width: 640px) {
  .roadmap .wrapper-content {
    padding: 0 1rem;
  }
}
.roadmap .wrapper-content .wrapper-text {
  box-sizing: border-box;
  flex: 0 0 70%;
}
.roadmap .wrapper-content .wrapper-text.full-width {
  flex: 0 0 100%;
}
@media screen and (max-width: 768px) {
  .roadmap .wrapper-content .wrapper-text {
    flex: 0 0 50%;
  }
}
@media screen and (max-width: 640px) {
  .roadmap .wrapper-content .wrapper-text {
    flex: 0 0 100%;
  }
}
.roadmap .wrapper-content .wrapper-text h2 {
  color: #00008d;
  font-family: acumin-pro, sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 3.5rem;
  line-height: 0.9;
  text-transform: uppercase;
  margin: 0 0 1rem;
}
@media screen and (max-width: 768px) {
  .roadmap .wrapper-content .wrapper-text h2 {
    font-size: 2.5rem;
  }
}
.roadmap .wrapper-content .wrapper-text p {
  font-size: 1.225rem;
  line-height: 1.5;
  margin-bottom: 1.5rem;
}
.roadmap .wrapper-content .wrapper-text p:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 640px) {
  .roadmap .wrapper-content .wrapper-text p:last-child {
    margin-bottom: 1.75rem;
  }
}
.roadmap .wrapper-content .wrapper-card {
  box-sizing: border-box;
  flex: 0 0 30%;
  text-align: center;
  padding: 2.5rem 4rem 0;
  margin-bottom: 1.5rem;
}
@media screen and (max-width: 820px) {
  .roadmap .wrapper-content .wrapper-card {
    padding-left: 2.5rem;
    padding-right: unset;
  }
}
@media screen and (max-width: 768px) {
  .roadmap .wrapper-content .wrapper-card {
    flex: 0 0 50%;
    padding: 2.5rem 0 0 4rem;
  }
}
@media screen and (max-width: 640px) {
  .roadmap .wrapper-content .wrapper-card {
    flex: 0 0 100%;
    padding: 0;
  }
}
@media screen and (max-width: 768px) {
  .roadmap .wrapper-content .wrapper-card img {
    display: block;
    max-height: 200px;
    margin-top: auto;
    margin-bottom: auto;
  }
}

@media screen and (max-width: 640px) {
  .tab-player {
    max-width: calc(100% - 18px);
    margin: 0 auto;
  }
}
@media screen and (max-width: 1180px) {
  .tab-player .container .single-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
@media screen and (max-width: 1180px) {
  .tab-player .container .single-card ul li {
    flex: 0 1 50%;
    flex-wrap: nowrap;
    justify-content: flex-start;
    line-height: 1;
  }
}
@media screen and (max-width: 1180px) {
  .tab-player .container .single-card .btn.secondary.blue {
    display: flex;
    flex-wrap: nowrap;
    line-height: 1;
    padding: 0 0.5rem;
  }
}
@media screen and (max-width: 1180px) {
  .tab-player .container .single-card .btn.secondary.blue:after {
    width: 1.75rem;
    marign-left: 0.5rem;
  }
}

.tab-wrapper .tab-controller {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  padding: 0;
  margin: 0;
}
@media screen and (max-width: 820px) {
  .tab-wrapper .tab-controller {
    position: relative;
    width: 100vw;
    left: 50%;
    transform: translateX(-50%);
  }
}
.tab-wrapper .tab-controller .single-controller {
  flex-grow: 1;
  text-align: center;
  padding: 1rem;
  cursor: pointer;
  text-transform: uppercase;
  font-family: acumin-pro, sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 1.5rem;
  color: #00008d;
  background-color: rgba(0, 0, 141, 0.1);
}
.tab-wrapper .tab-controller .single-controller:nth-child(odd) {
  background-color: rgba(0, 0, 141, 0.05);
}
.tab-wrapper .tab-controller .single-controller.active {
  background-color: #3DFFFF;
}
.tab-wrapper .tab-controller .single-controller:last-child {
  background-color: #000;
  color: #FF175B;
}
@media screen and (max-width: 992px) {
  .tab-wrapper .tab-controller .single-controller {
    flex: 1 1 auto;
    text-align: center;
  }
}
@media screen and (max-width: 640px) {
  .tab-wrapper .tab-controller .single-controller {
    flex: 1 1 50%;
  }
}
.tab-wrapper .single-tab {
  display: none;
  max-width: 100%;
  margin: 0 auto;
  background-color: rgba(0, 0, 141, 0.1);
}
.tab-wrapper .single-tab:nth-child(odd) {
  background-color: rgba(0, 0, 141, 0.05);
}
.tab-wrapper .single-tab.mistery-player {
  margin: 0;
  position: relative;
  background-color: transparent;
  background-image: none;
}
.tab-wrapper .single-tab.mistery-player:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100%;
  background-color: #000;
  background-image: url("~assets/bg-mistery.svg");
  background-repeat: repeat;
  background-size: auto;
}
.tab-wrapper .single-tab.mistery-player .wrapper-content {
  margin: 0;
  padding: 7.375rem 0 6rem;
  border-left: 0;
  border-right: 0;
}
.tab-wrapper .single-tab.mistery-player .wrapper-content .wrapper-text {
  border-left: unset;
  padding-left: 4.25rem;
}
@media screen and (max-width: 768px) {
  .tab-wrapper .single-tab.mistery-player .wrapper-content .wrapper-text {
    padding-left: 1.125rem;
  }
}
.tab-wrapper .single-tab.mistery-player .wrapper-content .wrapper-text .txt-fit-holder {
  width: 100%;
  margin-left: unset;
}
.tab-wrapper .single-tab.mistery-player .wrapper-content .wrapper-card {
  margin: 0;
  border-right: unset;
}
.tab-wrapper .single-tab .single-card {
  margin-top: 15px;
  margin-bottom: 15px;
}
.tab-wrapper .single-tab .single-card .card-info .label-availability {
  font-family: acumin-pro, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 1.125rem;
  margin: 0 0 10px;
  color: #00008d;
}
.tab-wrapper .single-tab .single-card ul {
  margin: 10px 0 0;
  justify-content: space-between;
}
.tab-wrapper .single-tab .single-card ul li {
  margin: 0 0 10px;
  color: #00008d;
  font-family: acumin-pro, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 1.125rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.tab-wrapper .single-tab .single-card ul li::before {
  content: "";
  width: 25px;
  height: 25px;
  margin-right: 5px;
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: center;
}
.tab-wrapper .single-tab .single-card ul li.level .not-available {
  color: #FF175B;
}
.tab-wrapper .single-tab .single-card ul li.level::before {
  background-size: 100%;
  background-image: url("~assets/icon-campo.svg");
}
.tab-wrapper .single-tab .single-card ul li.coin::before {
  /*background-image: url($resolvepath+'assets/icon-coin.svg');*/
  background-image: url("~assets/eth.svg");
}
.tab-wrapper .single-tab.active {
  display: flex;
}

.tab-player {
  background-color: #e5e5f3;
  padding: 15px 0 25px;
}
@media screen and (max-width: 640px) {
  .tab-player {
    position: relative;
  }
}
@media screen and (max-width: 640px) {
  .tab-player:before {
    content: "";
    width: 100vw;
    height: 100%;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: #e5e5f3;
  }
}

.not-available {
  color: #FF175B;
}

.single-card {
  margin-top: 15px;
  margin-bottom: 15px;
  opacity: 0;
}
.single-card img {
  cursor: pointer;
}
.single-card .card-info .label-availability {
  font-family: acumin-pro, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 1.125rem;
  margin: 0 0 10px;
  color: #00008d;
}
.single-card ul {
  margin: 10px 0 0;
  justify-content: space-between;
}
.single-card ul li {
  margin: 0 0 10px;
  color: #00008d;
  font-family: acumin-pro, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.single-card ul li::before {
  content: "";
  width: 25px;
  height: 25px;
  margin-right: 5px;
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: center;
}
.single-card ul li.level {
  /*&::before {
      background-size: 100%;
      background-image: url($resolvepath+'assets/icon-campo.svg');
  }*/
}
.single-card ul li.coin::before {
  background-image: url("~assets/eth.svg");
}

@media screen and (max-width: 640px) {
  section.list-content-cont {
    padding: 1rem 1rem 0;
  }
}

.btn-filter-mobile {
  display: none;
}
@media screen and (max-width: 640px) {
  .btn-filter-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    z-index: 5000;
    top: 110px;
    width: 100vw;
    height: 36px;
    margin-top: -1rem;
    margin-left: -1rem;
    font-size: 1.25rem;
    text-transform: uppercase;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.5px;
    background-color: #fff;
  }
}
@media screen and (max-width: 375px) {
  .btn-filter-mobile {
    top: 105px;
  }
}
@media screen and (max-width: 640px) {
  .btn-filter-mobile .fa-list-ul {
    margin-top: 0.125rem;
    margin-right: 0.5rem;
  }
}

@media screen and (max-width: 640px) {
  .filters-top-wrap {
    position: fixed;
    z-index: 15000;
    top: 110px;
    left: 0;
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    transition: all ease-in-out 0.125s;
  }
}
@media screen and (max-width: 375px) {
  .filters-top-wrap {
    top: 105px;
  }
}
@media screen and (max-width: 640px) {
  .filters-top-wrap.open {
    transform: none;
  }
}
.filters-top-wrap.open .btn-filter-close, .filters-top-wrap.open .overlay {
  opacity: 1;
}
@media screen and (max-width: 991px) {
  .filters-top-wrap .row.filter {
    flex-wrap: nowrap;
  }
}
@media screen and (max-width: 640px) {
  .filters-top-wrap .row.filter {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 991px) {
  .filters-top-wrap .row.filter .list-filter.flex-start {
    justify-content: space-between;
  }
}
.filters-top-wrap label {
  text-transform: uppercase;
}
@media screen and (max-width: 1367px) {
  .filters-top-wrap label {
    font-size: 1.25rem;
  }
}
@media screen and (max-width: 991px) {
  .filters-top-wrap label {
    font-size: 1rem;
    white-space: nowrap;
    margin-right: auto;
  }
}
@media screen and (max-width: 640px) {
  .filters-top-wrap .filters-top-wrap-inner {
    flex: 0 0 85%;
    background-color: #fff;
    padding: 1.25rem;
  }
}
@media screen and (max-width: 640px) {
  .filters-top-wrap .filters-top-wrap-inner .filter .col-lg-8.has-w-auto {
    width: 100%;
  }
}
@media screen and (max-width: 640px) {
  .filters-top-wrap .filters-top-wrap-inner .filter .col-lg-8.has-w-auto .list-filter {
    padding-bottom: 1.125rem;
    border-bottom: 0.5px solid #c5c5c5;
    margin-bottom: 1.25rem;
  }
}
@media screen and (max-width: 640px) {
  .filters-top-wrap .filters-top-wrap-inner .filter .filter-available-cont {
    width: 100%;
  }
}
@media screen and (max-width: 640px) {
  .filters-top-wrap .filters-top-wrap-inner .filter .filter-available-cont .list-filter {
    width: 100%;
    margin-bottom: unset;
  }
}
@media screen and (max-width: 640px) {
  .filters-top-wrap .filters-top-wrap-inner .filter .filter-available-cont .list-filter li {
    justify-content: flex-start;
    /*padding-top: 1.125rem;
    padding-bottom: 1.125rem*/
    border-top: 0.5px solid #c5c5c5;
    border-bottom: 0.5px solid #c5c5c5;
    margin-top: 1.25rem;
    /*margin-bottom: 1rem;*/
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    margin-bottom: 0.5rem;
  }
}
@media screen and (max-width: 640px) {
  .filters-top-wrap .filters-top-wrap-inner .filter .form-field {
    margin-top: unset;
  }
}
@media screen and (max-width: 640px) {
  .filters-top-wrap .filters-top-wrap-inner .filter .form-field.field-search {
    margin-top: unset;
    margin-right: unset;
  }
}
@media screen and (max-width: 640px) {
  .filters-top-wrap .filters-top-wrap-inner .filter .form-field.field-search .input-search-cont, .filters-top-wrap .filters-top-wrap-inner .filter .form-field.field-search .btn.secondary {
    flex-grow: 1;
    height: 28px;
  }
}
@media screen and (max-width: 640px) {
  .filters-top-wrap .filters-top-wrap-inner .filter .form-field.has-mobile-flex-end {
    justify-content: flex-end;
  }
}
@media screen and (max-width: 640px) {
  .filters-top-wrap .filters-top-wrap-inner .filter-players-by-look label {
    margin-bottom: 0.5rem;
  }
}
@media screen and (max-width: 640px) {
  .filters-top-wrap .filters-top-wrap-inner .filter-players-by-look .shirts-pattern-wrap {
    padding-bottom: 1.125rem;
    border-bottom: 0.5px solid #c5c5c5;
    margin-bottom: 0.5rem;
  }
}
.filters-top-wrap .filters-top-wrap-inner .filter-players-by-look .js-filter-colors {
  min-height: 79px;
}
@media screen and (max-width: 640px) {
  .filters-top-wrap .filters-top-wrap-inner .filter-players-by-look .js-filter-colors {
    min-height: unset;
  }
}
@media screen and (max-width: 640px) {
  .filters-top-wrap .filters-top-wrap-inner .filter-players-by-look .js-filter-colors:last-of-type {
    margin-top: -1rem;
  }
}
@media screen and (max-width: 640px) {
  .filters-top-wrap .filters-top-wrap-inner .filter-players-by-look .js-filter-colors label {
    margin-left: unset;
    margin-bottom: 0.5rem;
  }
}
@media screen and (max-width: 640px) {
  .filters-top-wrap .filters-top-wrap-inner .filter-players-by-look .js-filter-colors .filter-by-colors {
    margin-right: unset;
    margin-left: unset;
  }
}
@media screen and (max-width: 640px) {
  .filters-top-wrap .filters-top-wrap-inner .filter-players-by-look .js-filter-colors .filter-by-colors:after {
    content: "";
    display: block;
    clear: both;
  }
}
@media screen and (max-width: 640px) {
  .filters-top-wrap .overlay {
    flex: 0 0 15%;
    background-color: rgba(0, 0, 0, 0.45);
    opacity: 0;
    transition: opacity ease-in-out 0.25s;
    transition-delay: 0.35s;
  }
}
.filters-top-wrap .btn-filter-close {
  display: none;
}
@media screen and (max-width: 640px) {
  .filters-top-wrap .btn-filter-close {
    position: absolute;
    top: 1rem;
    right: 7.5%;
    transform: translateX(50%);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 1;
    font-size: 2rem;
    color: #00008d;
    background-color: #3DFFFF;
    width: 36px;
    height: 36px;
    padding-bottom: 0.5rem;
    border-radius: 100%;
    opacity: 0;
    transition: opacity ease-in-out 0.25s;
    transition-delay: 0.35s;
  }
}

@media screen and (max-width: 640px) {
  .filter .list-filter {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 640px) {
  .filter .list-filter.flex-mobile-center {
    justify-content: center !important;
    align-items: center !important;
  }
}
@media screen and (max-width: 640px) {
  .filter .list-filter.flex-mobile-center li {
    flex-grow: 1;
    display: flex;
    justify-content: center;
  }
}
@media screen and (max-width: 640px) {
  .filter .list-filter li {
    flex: 0 1 50%;
  }
}
.filter .list-filter li.order-by-rarity {
  width: 100%;
  flex: 2;
}
@media screen and (max-width: 991px) {
  .filter .list-filter li.order-by-rarity {
    width: auto;
    flex: unset;
  }
}
@media screen and (max-width: 640px) {
  .filter .list-filter li.order-by-rarity {
    width: 100%;
    flex-grow: 1;
    margin-top: 0.65rem;
    margin-bottom: 0.125rem;
  }
}
.filter .list-filter li.order-by-rarity .form-field {
  flex-direction: row-reverse;
}
@media screen and (max-width: 991px) {
  .filter .list-filter li.order-by-rarity .form-field {
    flex-direction: row;
  }
}
@media screen and (max-width: 640px) {
  .filter .list-filter li.order-by-rarity .form-field {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
.filter .list-filter li.order-by-rarity .form-field label {
  margin-right: 0.75rem;
}
@media screen and (max-width: 991px) {
  .filter .list-filter li.order-by-rarity .form-field label {
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (max-width: 640px) {
  .filter .list-filter li.order-by-rarity .form-field label {
    margin-top: 2px;
    margin-right: unset;
    margin-bottom: 2px;
    margin-left: unset;
  }
}
.filter .list-filter li.order-by-rarity .form-field select {
  min-width: 175px;
  height: 28px;
  font-size: 0.9rem;
  padding-right: 10px;
  padding-bottom: 1px;
  padding-left: 10px;
  margin-top: -1px;
  border: 0.5px solid #00008d;
  border-radius: unset;
}
@media screen and (max-width: 991px) {
  .filter .list-filter li.order-by-rarity .form-field select {
    min-width: unset;
    padding: 0 5px;
    margin-right: 5px;
  }
}
@media screen and (max-width: 640px) {
  .filter .list-filter li.order-by-rarity .form-field select {
    margin-top: unset;
    width: 100%;
  }
}
.filter .list-filter li.order-by-rarity .form-field select:placeholder {
  font-size: 0.9rem;
}
.filter .list-filter li.order-by-rarity .form-field select options {
  font-size: 0.9rem;
}
.filter .has-w-auto {
  width: auto;
}
.filter .has-f-grow {
  flex-grow: 1;
}
@media screen and (max-width: 1279px) {
  .filter .list-filter-wrap {
    flex-grow: 1;
  }
}
@media screen and (max-width: 1279px) {
  .filter .field-search-wrap {
    flex-grow: 1;
  }
}
@media screen and (max-width: 991px) {
  .filter .field-search-wrap {
    width: auto;
    flex-shrink: 1;
  }
}
@media screen and (max-width: 1279px) {
  .filter .filter-available-cont {
    width: fit-content;
  }
}
@media screen and (max-width: 991px) {
  .filter .filter-available-cont {
    flex-shrink: 1;
  }
}
.filter .field-search {
  justify-content: center;
  height: 30px;
}
.filter .input-search-cont {
  border: 0.5px solid #00008d;
  height: 28px;
  margin-right: 5px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.filter .input-search-cont .input-search {
  width: calc(100% - 21px - 7px);
  border: unset;
  background-color: transparent;
  border-radius: unset;
  font-size: 0.9rem;
  padding-right: 10px;
  padding-bottom: 1px;
  padding-left: 10px;
}
.filter .input-search-cont .fa-magnifying-glass {
  font-size: 0.9rem;
  align-self: center;
  padding-right: 0.5rem;
}
.filter .input-search-cont .btn.secondary.blue {
  border: 0.5px solid #00008d;
}
.filter .btn-search-player {
  height: 28px;
  font-size: 10px;
  border-radius: unset;
}
.filter .btn-search-player:after {
  width: 0.75rem;
  height: 0.75rem;
}
.filter .form-field {
  display: flex;
  align-items: center;
  margin: 15px 15px 0 0;
  font-family: acumin-pro, sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 1.5rem;
  color: #00008d;
  height: 28px;
}
@media screen and (max-width: 991px) {
  .filter .form-field {
    margin-top: 10px;
    margin-right: auto;
  }
}
@media screen and (max-width: 640px) {
  .filter .form-field {
    height: auto;
  }
}
.filter .form-field input[type=radio], .filter .form-field input[type=checkbox] {
  appearance: none;
  width: 22px;
  height: 22px;
  background-color: rgba(0, 0, 141, 0.1);
  margin-right: 10px;
}
@media screen and (max-width: 991px) {
  .filter .form-field input[type=radio], .filter .form-field input[type=checkbox] {
    width: 18px;
    height: 18px;
    margin-right: 6px;
  }
}
.filter .form-field input[type=radio]:checked, .filter .form-field input[type=checkbox]:checked {
  background-image: url("~assets/check.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 80%;
}

.single-shirt.btn-shirts-all {
  text-align: center;
  border: 3px solid #00008d;
  padding: 5px 10px;
  max-height: 42.5px;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 1279px) {
  .single-shirt.btn-shirts-all {
    padding: 5px 7px;
  }
}
@media screen and (max-width: 991px) {
  .single-shirt.btn-shirts-all {
    font-size: 1.25rem;
  }
}
@media screen and (max-width: 640px) {
  .single-shirt.btn-shirts-all {
    flex-grow: 0;
  }
}

@media screen and (max-width: 640px) {
  .shirts-pattern-wrap {
    margin-bottom: 1rem;
  }
}

.filter-players-by-look {
  font-family: acumin-pro, sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 1.5rem;
  color: #00008d;
  margin-bottom: 20px;
  text-transform: uppercase;
  margin-bottom: 1.25rem;
  justify-content: flex-end;
}
.filter-players-by-look .filter-by-colors-wrap {
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
  max-height: 47.5px;
}
@media screen and (max-width: 640px) {
  .filter-players-by-look .filter-by-colors-wrap {
    height: auto;
    max-height: unset;
    margin-bottom: 1.5rem;
  }
}
.filter-players-by-look .filter-by-colors-wrap .filter-by-colors {
  display: inline-flex;
  clear: both;
  flex-wrap: wrap;
  max-width: 60%;
}
@media screen and (max-width: 1279px) {
  .filter-players-by-look .filter-by-colors-wrap .filter-by-colors {
    display: inline-block;
  }
}
.filter-players-by-look .filter-by-colors-wrap .btn-colors-all {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 1;
  border: 3px solid #00008d;
  margin-bottom: 5px;
  max-height: 47.5px;
  font-size: 0.9rem;
}
@media screen and (max-width: 1366px) {
  .filter-players-by-look .filter-by-colors-wrap .btn-colors-all {
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 640px) {
  .filter-players-by-look .filter-by-colors-wrap .btn-colors-all {
    font-size: 1.25rem;
    height: 41px;
    margin-bottom: unset;
  }
}
.filter-players-by-look .filter-by-colors-wrap .btn-colors-all.selected {
  background-color: rgba(0, 0, 141, 0.1);
}
.filter-players-by-look .filter-by-colors-wrap .btn-colors-all:hover {
  cursor: pointer;
}
.filter-players-by-look .btn-reset-all {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 1;
  border: 3px solid #00008d;
  margin-bottom: 5px;
  height: 100%;
  min-height: 42.5px;
  max-height: 42.5px;
  font-size: 0.9rem;
  position: relative;
}
@media screen and (max-width: 1439px) {
  .filter-players-by-look .btn-reset-all {
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 1279px) {
  .filter-players-by-look .btn-reset-all {
    font-size: 0.85rem;
    min-height: 37.5px;
    max-height: 37.5px;
    margin-bottom: 14.5px;
  }
}
@media screen and (max-width: 640px) {
  .filter-players-by-look .btn-reset-all {
    font-size: 1rem;
  }
}
.filter-players-by-look .btn-reset-all.selected {
  background-color: rgba(0, 0, 141, 0.1);
}
.filter-players-by-look .btn-reset-all:hover {
  cursor: pointer;
}
@media screen and (max-width: 640px) {
  .filter-players-by-look .btn-reset-all:before {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 0;
    border-top: 0.5px solid #c5c5c5;
  }
}
.filter-players-by-look .filter-by-colors {
  width: calc(100% + 5px);
}
.filter-players-by-look .filter-by-colors .single-input:hover, .filter-players-by-look .filter-by-colors .btn-colors-all:hover {
  cursor: pointer;
}
.filter-players-by-look .filter-by-colors .single-input {
  display: inline-flex;
  float: left;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  margin-bottom: 5px;
  width: 20px;
  height: 20px;
}
@media screen and (max-width: 1279px) {
  .filter-players-by-look .filter-by-colors .single-input {
    width: 15px;
    height: 15px;
    margin-right: 2.5px;
    margin-bottom: 2.5px;
  }
}
@media screen and (max-width: 991px) {
  .filter-players-by-look .filter-by-colors .single-input {
    width: 12.5px;
    height: 12.5px;
  }
}
@media screen and (max-width: 640px) {
  .filter-players-by-look .filter-by-colors .single-input {
    width: 20px;
    height: 20px;
  }
}
.filter-players-by-look .filter-by-colors .single-input:hover {
  cursor: pointer;
}
.filter-players-by-look .filter-by-colors .single-input input {
  width: 100%;
  height: 100%;
  border: 1px solid #00008d;
  border-radius: unset;
  appearance: initial;
}
.filter-players-by-look .filter-by-colors .single-input input:hover {
  cursor: pointer;
}
.filter-players-by-look .filter-by-colors .single-input input.selected {
  opacity: 0.75;
  background-image: url("~assets/check.svg");
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: center;
}
.filter-players-by-look .filter-by-colors .single-input input.maroon {
  background-color: #800000;
}
.filter-players-by-look .filter-by-colors .single-input input.darkkhaki {
  background-color: #BDB76B;
}
.filter-players-by-look .filter-by-colors .single-input input.mediumseagreen {
  background-color: #3CB371;
}
.filter-players-by-look .filter-by-colors .single-input input.violet {
  background-color: #EE82EE;
}
.filter-players-by-look .filter-by-colors .single-input input.palegreen {
  background-color: #98FB98;
}
.filter-players-by-look .filter-by-colors .single-input input.deeppink {
  background-color: #FF1493;
}
.filter-players-by-look .filter-by-colors .single-input input.red {
  background-color: red;
}
.filter-players-by-look .filter-by-colors .single-input input.blue {
  background-color: blue;
}
.filter-players-by-look .filter-by-colors .single-input input.fuchsia {
  background-color: #FF00FF;
}
.filter-players-by-look .filter-by-colors .single-input input.burlywood {
  background-color: #DEB887;
}
.filter-players-by-look .filter-by-colors .single-input input.lightsalmon {
  background-color: #FFA07A;
}
.filter-players-by-look .filter-by-colors .single-input input.coral {
  background-color: #FF7F50;
}
.filter-players-by-look .filter-by-colors .single-input input.purple {
  background-color: #800080;
}
.filter-players-by-look .filter-by-colors .single-input input.maroon {
  background-color: #800000;
}
.filter-players-by-look .filter-by-colors .single-input input.limegreen {
  background-color: #32CD32;
}
.filter-players-by-look .filter-by-colors .single-input input.mediumturquoise {
  background-color: #48D1CC;
}
.filter-players-by-look .filter-by-colors .single-input input.palegoldenrod {
  background-color: #EEE8AA;
}
.filter-players-by-look .filter-by-colors .single-input input.navahowhite {
  background-color: #FFDEAD;
}
.filter-players-by-look .filter-by-colors .single-input input.chartreuse {
  background-color: #7FFF00;
}
.filter-players-by-look .filter-by-colors .btn-colors-all {
  font-size: 10px;
  font-size: 10px;
  line-height: 1;
  border: 1px solid #00008d;
  display: inline-flex;
  float: left;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 20px;
  margin-bottom: 5px;
}
@media screen and (max-width: 640px) {
  .filter-players-by-look .filter-by-colors .btn-colors-all {
    margin-left: 3px;
    margin-right: 3px;
  }
}

.single-shirt {
  flex: 0 0 calc(20% - 15px);
  padding: 5px 10px;
  margin-right: 2.5px;
  border: 3px solid transparent;
  min-height: 42.5px;
  max-height: 42.5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 1279px) {
  .single-shirt {
    padding: 5px;
  }
}
@media screen and (max-width: 991px) {
  .single-shirt {
    flex: 0 0 calc(20% - 5px);
    padding-left: 1px;
    padding-right: 1px;
  }
}
@media screen and (max-width: 640px) {
  .single-shirt {
    flex-grow: 1;
    min-height: unset;
  }
}
.single-shirt:hover {
  cursor: pointer;
}
@media screen and (max-width: 640px) {
  .single-shirt:not(.btn-shirts-all) {
    max-width: 56.5px;
  }
}
.single-shirt.selected {
  border: 3px solid #00008d;
  background-color: rgba(0, 0, 141, 0.1);
}
.single-shirt img {
  width: 100%;
  max-width: 100%;
  object-fit: contain;
}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 888888;
  /*background-color: #FFF;*/
  background-color: rgba(0, 0, 0, 0.8);
  display: none;
  overflow: auto;
  height: 100%;
}
.modal-wrapper .body-modal {
  background: #FFF;
  width: 100%;
  height: 100%;
  max-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 50px 25px 50px 2.5rem;
  overflow: hidden;
}
@media screen and (max-width: 992px) {
  .modal-wrapper .body-modal {
    padding: 5rem 2.5rem 5rem 2.5rem;
  }
}
@media screen and (max-width: 640px) {
  .modal-wrapper .body-modal {
    padding: 5rem 1.125rem;
    position: relative;
  }
}
@media screen and (max-width: 640px) {
  .modal-wrapper .body-modal:before, .modal-wrapper .body-modal:after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%;
    height: 5rem;
    background-color: #f2f2f9;
  }
}
@media screen and (max-width: 640px) {
  .modal-wrapper .body-modal:before {
    top: 0;
  }
}
@media screen and (max-width: 640px) {
  .modal-wrapper .body-modal:after {
    bottom: 0;
  }
}
.modal-wrapper .body-modal .content {
  width: 100%;
  max-width: calc(100% - 6rem);
  height: 100%;
  padding-right: 3rem;
  margin: 0 auto;
  overflow-y: scroll;
  display: flex;
  /* ===== Scrollbar CSS ===== */
  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: #00008d #f1f1f1;
  /* Chrome, Edge, and Safari */
}
.modal-wrapper .body-modal .content::-webkit-scrollbar {
  width: 12px;
}
.modal-wrapper .body-modal .content::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.modal-wrapper .body-modal .content::-webkit-scrollbar-thumb {
  background-color: #00008d;
  border-radius: 10px;
  border: 3px solid #ffffff;
}
@media screen and (max-width: 992px) {
  .modal-wrapper .body-modal .content {
    max-width: calc(100% - 3rem);
  }
}
@media screen and (max-width: 640px) {
  .modal-wrapper .body-modal .content {
    max-width: 100%;
    padding-left: 0.5rem;
    padding-right: 1rem;
  }
}
.modal-wrapper .body-modal .content .single-card-detail-wrapper {
  margin-top: unset;
  margin-bottom: unset;
}
@media screen and (max-width: 640px) {
  .modal-wrapper .body-modal .content .single-card-detail-wrapper {
    padding-bottom: 2.75rem;
  }
}
.modal-wrapper.active {
  display: block;
}
.modal-wrapper .close {
  font-size: 2rem;
  color: #00008d;
  position: absolute;
  right: 2rem;
  top: 50px;
  cursor: pointer;
  z-index: 1000;
}
@media screen and (max-width: 992px) {
  .modal-wrapper .close {
    top: 0.75rem;
    right: 1.25rem;
  }
}
.modal-wrapper .next, .modal-wrapper .prev {
  font-size: 2.25rem;
  color: #00008d;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000;
  cursor: pointer;
}
@media screen and (max-width: 640px) {
  .modal-wrapper .next, .modal-wrapper .prev {
    top: unset;
    bottom: -0.85rem;
  }
}
.modal-wrapper .next {
  /*font-size: 2rem;
      color: $main-blue;
      position: absolute;*/
  right: 1.5rem;
  /*top: 50%;
      cursor: pointer;
      z-index: 1000;*/
}
@media screen and (max-width: 640px) {
  .modal-wrapper .next {
    right: calc(50% - 2.75rem);
  }
}
.modal-wrapper .prev {
  /*font-size: 2rem;
      color: $main-blue;
      position: absolute;*/
  left: 1.5rem;
  /*top: 50%;
      cursor: pointer;
      z-index: 1000;*/
}
@media screen and (max-width: 640px) {
  .modal-wrapper .prev {
    left: calc(50% - 4rem);
  }
}
.modal-wrapper .container {
  width: 100%;
  max-width: 100%;
}
.modal-wrapper .content {
  /*width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);*/
}
@media screen and (max-width: 992px) {
  .modal-wrapper .content {
    position: relative;
    top: auto;
    left: auto;
    transform: none;
    padding: 1rem;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 992px) {
  .modal-wrapper .content .img-card {
    margin: 0 auto;
    display: block;
  }
}

.single-card-detail-wrapper {
  margin-top: 25px;
  margin-bottom: 25px;
}
.single-card-detail-wrapper .btn.xs {
  font-size: 1.125rem;
  line-height: 1;
  height: auto;
}
@media screen and (max-width: 1180px) {
  .single-card-detail-wrapper .btn.xs {
    font-size: 0.85rem;
    padding: 3px 15px 4px;
  }
}
@media screen and (max-width: 1180px) {
  .single-card-detail-wrapper .btn.xs.js-buy-cc {
    font-size: 0.75rem;
    padding-left: 0.75rem;
  }
}

@media screen and (max-width: 820px) {
  .body-modal .single-card-detail-wrapper .player-card {
    margin-bottom: unset;
  }
}
@media screen and (max-width: 819px) {
  .body-modal .single-card-detail-wrapper .player-card {
    padding: unset;
    flex: 1 1 100%;
    margin-bottom: 2.5rem;
    min-height: unset;
  }
}
@media screen and (max-width: 820px) {
  .body-modal .single-card-detail-wrapper .player-card .img-player-card-cont {
    width: 40%;
    margin-bottom: unset;
  }
}
@media screen and (max-width: 640px) {
  .body-modal .single-card-detail-wrapper .player-card .img-player-card-cont {
    /*flex: 1 1 40%;*/
  }
}
@media screen and (max-width: 820px) {
  .body-modal .single-card-detail-wrapper .player-card .img-player-card-cont img {
    width: 100%;
  }
}
.body-modal .single-card-detail-wrapper .player-card .img-player-card-cont img:hover {
  cursor: pointer;
}
@media screen and (max-width: 820px) {
  .body-modal .single-card-detail-wrapper .player-card .main-player-info {
    width: 60%;
    margin-bottom: unset;
  }
}
@media screen and (max-width: 640px) {
  .body-modal .single-card-detail-wrapper .player-card .main-player-info {
    /*flex: 1 1 60%;*/
    float: right;
  }
}
@media screen and (max-width: 640px) {
  .body-modal .single-card-detail-wrapper .player-card .main-player-info .bar-graph .label-value {
    font-size: 1rem;
  }
}
.body-modal .single-card-detail-wrapper .buy-container {
  top: 0;
}
@media screen and (max-width: 992px) {
  .body-modal .single-card-detail-wrapper .buy-container {
    position: sticky;
  }
}
@media screen and (max-width: 820px) {
  .body-modal .single-card-detail-wrapper .buy-container {
    position: unset;
    padding: 2rem;
    border-right: 5px solid #00008d;
    background-color: #F2F2F9;
    flex: 1 1 100%;
  }
}
@media screen and (max-width: 640px) {
  .body-modal .single-card-detail-wrapper .buy-container {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    margin: 0 auto;
    max-width: 100%;
  }
}
.body-modal .single-card-detail-wrapper .player-stats-info {
  /*@media screen and (max-width: 992px) {
    margin-top: -10rem;
  }*/
}
@media screen and (max-width: 820px) {
  .body-modal .single-card-detail-wrapper .player-stats-info {
    padding-left: unset;
    padding-right: unset;
    margin-top: 2.5rem;
    flex: 1 1 100%;
  }
}
@media screen and (max-width: 640px) {
  .body-modal .single-card-detail-wrapper .player-stats-info .list-statistic-pie {
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media screen and (max-width: 640px) {
  .body-modal .single-card-detail-wrapper .player-stats-info .list-statistic-pie .single-pie {
    padding: 1rem;
  }
}
@media screen and (max-width: 640px) {
  .body-modal .single-card-detail-wrapper .player-stats-info .info.health {
    margin-top: -1rem;
    margin-bottom: unset;
  }
}
@media screen and (max-width: 640px) {
  .body-modal .single-card-detail-wrapper .player-stats-info .features {
    margin-bottom: -1rem;
  }
}
@media screen and (max-width: 640px) {
  .body-modal .single-card-detail-wrapper .player-stats-info .features .features-block .single-features-wrap {
    flex-wrap: wrap;
    justify-content: flex-start;
    width: calc(100% + 1rem);
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
}
@media screen and (max-width: 640px) {
  .body-modal .single-card-detail-wrapper .player-stats-info .features .features-block .single-features-wrap .feature {
    width: auto;
    flex: 0 1 50%;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
  }
}
@media screen and (max-width: 640px) {
  .body-modal .single-card-detail-wrapper .player-stats-info .btn-show-details {
    margin-bottom: 2rem;
  }
}

.single-card-infomation-details.internal {
  padding: 2rem 0;
}
@media screen and (max-width: 640px) {
  .single-card-infomation-details.internal {
    padding: 0 1rem;
  }
}
.single-card-infomation-details.internal .single-card-detail-wrapper {
  padding-top: 2.75rem;
  padding-bottom: 2.75rem;
  margin-top: unset;
  margin-bottom: unset;
}
@media screen and (max-width: 640px) {
  .single-card-infomation-details.internal .player-stats-info {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
  }
}
.single-card-infomation-details.internal .buy-container {
  position: static;
}
.single-card-infomation-details.internal .wrapper-info .features {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.single-card-infomation-details.internal .wrapper-info .features .features-block {
  width: calc(50% - 0.75rem);
}
@media screen and (max-width: 640px) {
  .single-card-infomation-details.internal .wrapper-info .features .features-block {
    width: 100%;
  }
}
.single-card-infomation-details h3 {
  font-size: 2.5rem;
  font-weight: 400;
  color: #00008d;
  margin-bottom: 0;
  line-height: 1;
}
@media screen and (max-width: 1180px) {
  .single-card-infomation-details h3 {
    font-size: 2rem;
  }
}
@media screen and (max-width: 640px) {
  .single-card-infomation-details h3 {
    font-size: 1.75rem;
  }
}
.single-card-infomation-details .player-card {
  flex: 1 1 63.5%;
  display: block;
  float: left;
  width: 63.5%;
  padding-right: 1.875rem;
  margin-bottom: 3rem;
}
@media screen and (max-width: 768px) {
  .single-card-infomation-details .player-card {
    width: 100%;
  }
}
@media screen and (max-width: 640px) {
  .single-card-infomation-details .player-card {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    margin-bottom: 2.25rem;
  }
}
.single-card-infomation-details .player-card .img-player-card-cont {
  display: inline-block;
  width: 30%;
  padding-right: 1rem;
}
@media screen and (max-width: 640px) {
  .single-card-infomation-details .player-card .img-player-card-cont {
    padding-right: 0.5rem;
  }
}
.single-card-infomation-details .player-card .img-player-card-cont img:hover {
  cursor: pointer;
}
.single-card-infomation-details .player-card .main-player-info {
  display: inline-block;
  width: calc(70% - 1rem);
  padding-left: 1rem;
  float: right;
}
.single-card-infomation-details .player-card .main-player-info .hearts-graph .label-value {
  color: var(--bs-body-color);
  margin-bottom: 0.5rem;
}
.single-card-infomation-details .player-card .main-player-info .hearts-graph .hearts {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  height: 2rem;
}
@media screen and (max-width: 640px) {
  .single-card-infomation-details .player-card .main-player-info .hearts-graph .hearts {
    height: 1.5rem;
  }
}
.single-card-infomation-details .player-card .main-player-info .hearts-graph .hearts .heart {
  display: block;
  margin-right: 10px;
  flex: 0 0 2rem;
  height: 100%;
  background-image: url("~assets/heart-empty.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
@media screen and (max-width: 640px) {
  .single-card-infomation-details .player-card .main-player-info .hearts-graph .hearts .heart {
    flex: 0 0 1.5rem;
    margin-right: 5px;
  }
}
.single-card-infomation-details .player-card .main-player-info .hearts-graph .hearts .heart.heart-full {
  background-image: url("~assets/heart-full.png");
}
.single-card-infomation-details .player-card .main-player-info .hearts-graph .hearts .heart.heart-half {
  background-image: url("~assets/heart-half.png");
}
.single-card-infomation-details .buy-container {
  display: block;
  float: right;
  width: 36.5%;
  padding-left: 1.875rem;
  border-left: 5px solid #00008d;
  position: sticky;
  top: calc(125px + 2.75rem);
}
@media screen and (max-width: 992px) {
  .single-card-infomation-details .buy-container {
    position: unset;
  }
}
@media screen and (max-width: 768px) {
  .single-card-infomation-details .buy-container {
    width: 100%;
    padding: 2rem;
    border-right: 5px solid #00008d;
    background-color: #F2F2F9;
  }
}
@media screen and (max-width: 640px) {
  .single-card-infomation-details .buy-container {
    max-width: calc(100% - var(--bs-gutter-x) * 0.5 * 2);
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    margin: 0 auto;
  }
}
.single-card-infomation-details .buy-container p {
  line-height: 1.15;
}
.single-card-infomation-details .player-stats-info {
  padding-right: 1.875rem;
  display: block;
  float: left;
  width: 63.5%;
}
@media screen and (max-width: 768px) {
  .single-card-infomation-details .player-stats-info {
    margin-top: 2.5rem;
    width: 100%;
    padding-right: unset;
  }
}
.single-card-infomation-details .wrapper-info {
  display: block;
  float: left;
  width: 100%;
  margin-bottom: 2.75rem;
}
.single-card-infomation-details .p-internal-tb-wrap {
  width: 100%;
}
.single-card-infomation-details .nickname {
  display: flex;
}
.single-card-infomation-details .nickname .form-field {
  padding: 0 10px;
  background-color: #00008d;
  color: #3DFFFF;
  font-family: acumin-pro, sans-serif;
  font-weight: 700;
  font-size: 1.125rem;
  font-style: normal;
  font-size: 1.125rem;
}
.single-card-infomation-details .nickname .form-field input {
  background: transparent;
  appearance: none;
  border: 0;
  color: #3DFFFF;
  font-family: acumin-pro, sans-serif;
  font-weight: 700;
  font-size: 1.125rem;
  font-style: normal;
  font-size: 1.125rem;
}
.single-card-infomation-details .nickname .btn-edit {
  width: 30px;
  height: 30px;
  margin: 0 10px;
  background-color: #3DFFFF;
  text-align: center;
  box-sizing: border-box;
  padding: 0 5px;
  cursor: pointer;
}
.single-card-infomation-details .nickname .btn-edit img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.single-card-infomation-details .idcard, .single-card-infomation-details .rarity-info, .single-card-infomation-details .value-price {
  font-family: acumin-pro, sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 1.5rem;
  color: #00008d;
}
@media screen and (max-width: 1366px) {
  .single-card-infomation-details .idcard, .single-card-infomation-details .rarity-info, .single-card-infomation-details .value-price {
    font-family: acumin-pro, sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 1.3rem;
  }
}
.single-card-infomation-details .info {
  font-weight: 700;
  font-size: 2rem;
  color: #00008d;
}
@media screen and (max-width: 1180px) {
  .single-card-infomation-details .info {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 640px) {
  .single-card-infomation-details .info {
    font-size: 1.3rem;
  }
}
.single-card-infomation-details .info.health {
  margin-bottom: 1.65rem;
}
@media screen and (max-width: 1180px) {
  .single-card-infomation-details .info.health {
    margin-bottom: 1.25rem;
  }
}
.single-card-infomation-details .value-price {
  margin-right: 15px;
}
.single-card-infomation-details .value-price:before {
  content: "";
  width: 2.3rem;
  height: 2.3rem;
  background-image: url("~assets/eth.svg");
  background-size: contain;
  display: inline-block;
  vertical-align: middle;
}
.single-card-infomation-details .gained {
  color: #36ff36;
}
.single-card-infomation-details .bar-graph {
  height: 3rem;
  background-color: #00008d;
  width: 100%;
  position: relative;
  border: 0;
  font-size: 0;
  margin: 0.9rem 0;
}
@media screen and (max-width: 640px) {
  .single-card-infomation-details .bar-graph {
    height: 2.5rem;
  }
}
.single-card-infomation-details .bar-graph .label-value {
  font-family: acumin-pro, sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
  font-style: normal;
  color: #FFF;
  position: absolute;
  z-index: 300;
  top: calc(50% - 1px);
  left: 1.125rem;
  transform: translate(0, -50%);
  line-height: 3rem;
}
@media screen and (max-width: 1180px) {
  .single-card-infomation-details .bar-graph .label-value {
    font-family: acumin-pro, sans-serif;
    font-weight: 700;
    font-size: 1.3rem;
    font-style: normal;
    top: calc(50% - 1px);
  }
}
.single-card-infomation-details .bar-graph .label-value .point-gains {
  font-size: 1rem;
  margin-left: 0.5rem;
  color: #3dffff;
}
.single-card-infomation-details .bar-graph .value-bar {
  height: 3rem;
  background-color: #3DFFFF;
  position: absolute;
  border: 0;
  font-size: 0;
  top: 0;
  left: 0;
  z-index: 200;
  animation: load 3s normal forwards;
}
@media screen and (max-width: 640px) {
  .single-card-infomation-details .bar-graph .value-bar {
    height: 2.5rem;
  }
}
.single-card-infomation-details .features .features-block {
  font-size: 0;
  padding-bottom: 1rem;
  margin-bottom: 0.75rem;
}
.single-card-infomation-details .features .features-block:last-of-type {
  margin-bottom: unset;
}
.single-card-infomation-details .features .features-block .features-block-title {
  font-family: acumin-pro, sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 1.3rem;
  color: #00008d;
  text-transform: uppercase;
  margin-bottom: 0.85rem;
}
.single-card-infomation-details .features .features-block .single-features-wrap {
  display: flex;
  justify-content: space-between;
}
.single-card-infomation-details .features .features-block .single-features-wrap .feature {
  text-align: center;
  vertical-align: top;
  width: calc(33.3333333333% - 0.5rem);
}
.single-card-infomation-details .features .features-block .single-features-wrap .feature .feature-info-wrap {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  border: 2.5px solid #00008d;
  background-color: #f2f2f9;
}
.single-card-infomation-details .features .features-block .single-features-wrap .feature .feature-info-wrap .feature-info {
  /*padding-top: 1rem;
  padding-bottom: 1rem;
  width: 90%;
  margin: 0 auto;
  border: 0.5rem solid $main-blue;
  border-radius: 1rem;*/
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: acumin-pro, sans-serif;
  font-weight: 700;
  font-size: 1.125rem;
  font-style: normal;
  color: #00008d;
}
.single-card-infomation-details .features .features-block .single-features-wrap .feature .feature-get {
  padding-top: 0.65rem;
  display: inline-block;
  width: auto;
  margin: 0 auto;
}
.single-card-infomation-details .features .features-block .single-features-wrap .feature .feature-get .btn {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

@keyframes load {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
footer {
  background-color: #000053;
  padding: 2rem 0;
}
@media screen and (max-width: 768px) {
  footer {
    padding: 3.5rem 1.125rem;
  }
}
footer .wrapper-loghi-power {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 768px) {
  footer .wrapper-loghi-power {
    margin-bottom: 1.75rem;
  }
}
footer .wrapper-loghi-power .single-logo {
  margin: 10px;
}
footer .wrapper-loghi-power .single-logo:nth-child(1) {
  margin-left: 0;
}
footer p {
  color: #FFF;
  font-size: 14px;
  line-height: 1.25;
}
@media screen and (max-width: 768px) {
  footer p {
    margin-bottom: 2.75rem;
  }
}
footer .label {
  font-size: 14px;
  color: #FFF;
  margin-bottom: 10px;
}
@media screen and (max-width: 768px) {
  footer .label {
    margin-top: 0.5rem;
    margin-bottom: 0.75rem;
  }
}
footer .logo-powered {
  width: 100px;
}
footer ul {
  margin: 0;
  display: block;
  column-count: 2;
}
@media screen and (max-width: 768px) {
  footer ul {
    margin-bottom: 1.5rem;
  }
}
footer ul li {
  margin: 0 0 10px;
}
@media screen and (max-width: 768px) {
  footer ul li {
    text-transform: uppercase;
  }
}
footer ul li a {
  color: #3DFFFF;
  text-decoration: none;
}
footer ul li a:hover {
  color: #FF175B;
}
footer .social {
  column-count: 1;
  display: flex;
  justify-content: flex-start;
  margin-top: 0.75rem;
}
@media screen and (max-width: 640px) {
  footer .social {
    margin-bottom: 1.25rem;
  }
}
footer .social li {
  font-size: 1.2rem;
  margin: 0 5px;
}
footer .social li:first-of-type {
  margin-left: unset;
}
footer .logo-footer {
  text-align: right;
}
@media screen and (max-width: 992px) {
  footer .logo-footer {
    text-align: center;
  }
}
@media screen and (max-width: 768px) {
  footer .logo-footer {
    text-align: left;
  }
}
footer .logo-footer img {
  max-width: 115px;
}
@media screen and (max-width: 992px) {
  footer .logo-footer img {
    max-width: 70px;
  }
}

.page-rules h1, .page-team h1 {
  font-size: 4rem;
  color: #FF175B;
  border-color: #FF175B;
}
.page-rules .intro-wrapper, .page-team .intro-wrapper {
  margin-top: 50px;
  margin-bottom: 50px;
}
@media screen and (max-width: 640px) {
  .page-rules .intro-wrapper, .page-team .intro-wrapper {
    margin: 1.5rem auto;
  }
}
.page-rules .action-section, .page-team .action-section {
  margin-top: 50px;
  margin-bottom: 50px;
}

.single-team-member {
  text-align: center;
  text-transform: uppercase;
}
.single-team-member .wrapper-img img {
  max-width: 100%;
}
.single-team-member .name {
  color: #00008d;
  font-family: acumin-pro, sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 1.5rem;
  line-height: 1.125;
}
@media screen and (max-width: 992px) {
  .single-team-member .name {
    font-size: 1.3em;
  }
}
.single-team-member .role {
  font-family: acumin-pro, sans-serif;
  font-weight: 700;
  font-size: 1rem;
  font-style: normal;
  color: #FF175B;
  text-transform: none;
}

/*
.wrapper-carousel {
  --carousel-slider-max-width: 1200px;
  --carousel-slider-width: 520px;
  --carousel-slider-height: 380px;
  position: relative;
  width: var(--carousel-slider-max-width);
  margin: 0 auto;
  max-width: 100%;
  overflow: hidden;
  @media screen and (max-width:992px) {
      height: 24vh;
    }  
  }
*/
.toasty-area {
  position: fixed;
  top: 10%;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1000000;
}

.toast {
  position: absolute;
  width: 25%;
  background-color: #FFFFFF;
  -webkit-box-shadow: 0px 5px 10px -1px rgba(90, 90, 90, 0.25);
  box-shadow: 0px 5px 10px -1px rgba(90, 90, 90, 0.25);
  text-align: center;
  padding: 0.5rem;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
@media screen and (max-width: 768px) {
  .toast {
    width: 95%;
  }
}
.toast .icon-toast {
  width: 40px;
  margin-left: -10px;
}
.toast .panel-toast-icon {
  width: 12%;
  display: inline-block;
  vertical-align: middle;
}
.toast .panel-toast-message {
  text-align: left;
  width: 80%;
  display: inline-block;
  vertical-align: middle;
  line-height: 1.2;
  padding-left: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #898989;
  font-family: acumin-pro, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 1rem;
}

.toast-type-0 {
  border-left: 7px solid #39B14F;
}
.toast-type-0 .panel-toast-icon {
  width: 30px;
  height: 30px;
  line-height: 30px;
}
.toast-type-0 .panel-toast-icon:before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f058";
  font-size: 1.6rem;
  color: #39B14F;
}

.toast-type-1 {
  border-left: 7px solid #D9A300;
}
.toast-type-1 .panel-toast-icon {
  width: 30px;
  height: 30px;
  line-height: 30px;
}
.toast-type-1 .panel-toast-icon:before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f05a";
  font-size: 1.6rem;
  color: #D9A300;
}

.toast-type-2 {
  border-left: 7px solid #FF2626;
}
.toast-type-2 .panel-toast-icon {
  width: 30px;
  height: 30px;
  line-height: 30px;
}
.toast-type-2 .panel-toast-icon:before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f071";
  font-size: 1.6rem;
  color: #FF2626;
}

.player-card.mistery-player {
  margin: inherit 0;
  background-color: transparent;
}
.player-card.mistery-player h3 {
  color: #FF175B;
}

.page-team .wrapper-title-wrap.bg-pattern-azure .txt-fit-holder h2.txt-fit {
  line-height: 1;
}

.team-members {
  margin-top: 50px;
  margin-bottom: 50px;
}
@media screen and (max-width: 992px) {
  .team-members {
    margin-bottom: 30px;
  }
}
.team-members .single-team-member {
  margin-bottom: 25px;
}
@media screen and (max-width: 640px) {
  .team-members .single-team-member {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.img-lightbox-cont {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 888899;
  display: flex;
  justify-content: center;
  align-items: center;
}
.img-lightbox-cont .overlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.img-lightbox-cont .img-lightbox {
  position: relative;
  z-index: 1;
  width: auto;
  max-height: 70%;
}

.lore-wrapper h4 {
  color: #00008d;
  font-family: "acumin-pro", sans-serif;
  font-weight: 900;
  font-style: normal;
  text-transform: uppercase;
  font-size: 1.75rem;
}
.lore-wrapper h4 ~ p {
  margin-top: 0;
}
.lore-wrapper p {
  margin: 1rem 0;
  line-height: 1.25;
}
.lore-wrapper ul {
  list-style: disc;
  padding-left: 2rem;
  margin-bottom: 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
  color: #00008d;
}
.lore-wrapper ul li {
  margin-bottom: 0.85rem;
}

.clrfix, .my-nft-page .my-player-list .single-card-detail-wrapper .btn-show-details-cont:after, .my-nft-page .my-player-list .single-card-detail-wrapper .player-card:after, .my-nft-page .my-player-list .single-card-detail-wrapper .player-stats-info:after, .my-nft-page .my-player-list .single-card-detail-wrapper .wrapper-info:after, .my-nft-page .my-player-list .single-card-detail-wrapper:after {
  content: "";
  display: block;
  clear: both;
}

.my-nft-page .my-nft .wrapper-text h1 {
  color: #FF175B;
  font-family: acumin-pro, sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 3.5rem;
  line-height: 0.9;
  text-transform: uppercase;
  margin: 0 0 1rem;
}
@media screen and (max-width: 768px) {
  .my-nft-page .my-nft .wrapper-text h1 {
    font-size: 2.5rem;
  }
}
.my-nft-page .my-nft .wrapper-text p.big {
  font-size: 1.225rem;
  line-height: 1.125;
  margin-bottom: 1.5rem;
}
.my-nft-page .not-available {
  display: none;
}
.my-nft-page .single-card-infomation-details.internal {
  /*padding: 0;
  margin: 0;*/
  padding-bottom: unset;
}
.my-nft-page .my-player-list .single-card-detail-wrapper {
  margin-top: 0;
  margin-bottom: 0;
  background-color: #e5e5f3;
  position: relative;
}
.my-nft-page .my-player-list .single-card-detail-wrapper:before {
  content: "";
  width: 100vw;
  height: 100%;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #e5e5f3;
}
.my-nft-page .my-player-list .single-card-detail-wrapper:nth-child(odd) {
  background-color: #f2f2f9;
}
.my-nft-page .my-player-list .single-card-detail-wrapper:nth-child(odd):before {
  background-color: #f2f2f9;
}
@media screen and (max-width: 768px) {
  .my-nft-page .my-player-list .single-card-detail-wrapper .buy-container {
    margin-bottom: 2.5rem;
  }
}
@media screen and (max-width: 640px) {
  .my-nft-page .my-player-list .single-card-detail-wrapper .buy-container {
    background-color: rgba(255, 255, 255, 0.5);
  }
}
.my-nft-page .my-player-list .single-card-detail-wrapper .buy-container .player-not-available-msg {
  background-color: transparent;
  padding: unset;
  display: none;
}
.my-nft-page .my-player-list .row {
  max-width: 100%;
}
.my-nft-page .my-player-list .single-card-detail-wrapper .player-card {
  margin-bottom: 1.5rem;
}
.my-nft-page .my-player-list .single-card-detail-wrapper .player-card.mistery-player {
  background: unset;
  margin-top: unset;
}
.my-nft-page .my-player-list .single-card-detail-wrapper .player-stats-info {
  display: none;
}
.my-nft-page .my-player-list .single-card-detail-wrapper .player-stats-info .list-statistic-pie {
  border-top: 0.5px solid #c4c4c4;
}
.my-nft-page .my-player-list .single-card-detail-wrapper .wrapper-info {
  margin-bottom: unset;
  display: none;
}
.my-nft-page .my-player-list .single-card-detail-wrapper .wrapper-info.has-bg-light {
  background-color: transparent;
}
.my-nft-page .my-player-list .single-card-detail-wrapper .p-internal-tb {
  display: none;
}
.my-nft-page .my-player-list .single-card-detail-wrapper .btn-show-details-cont {
  display: block;
  float: left;
  width: 100%;
  margin-bottom: 1.5rem;
}