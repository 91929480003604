* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body {
    margin: 0;
    padding: 0;
    width: 100%;    
    position: relative;
    scroll-behavior: smooth;
    @include font-semibold($default-font-size);
    @media screen and (max-width:1440px) {
        font-size: 14px;
    }
    @media screen and (max-width:1400px) {
        font-size: 12px;
    }
}

body {
  overflow-x: hidden;
}

.preloader-app {
    z-index: 15000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.7);
    text-align: center;

    .preloader-container {
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        font-size: 1.25rem;
        background-color: #FFFFFF;
        color: #000000;
        padding: 2rem;
        border-radius: 50%;

        .clip-loader {
            display: inline-block;
            //border-radius: 50%;
            //padding: 2rem;
            //background-color: #ffffff;
        }

        .text-loading {
            position: relative;
            margin-top: 32px;
            width: 320px;

            .text-shadow {
                font-size: 21px !important;
                line-height: 1.4;
            }
        }
    }
}

.vhelper {
    display: inline-block;
    vertical-align: middle;
    height: 100%;
}

img {
    max-width: 100%;
    width: 100%;
    object-fit: contain;
    margin: 0 auto;
}

ul {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &.flex-start {
        justify-content: flex-start;
    }

    &.flex-end {
        justify-content: flex-end;
    }
}

.borderd {
    position: relative;

    &:before {
        content: "";
        height: 100%;
        width: 3px;
        background-color: $main-blue;
        position: absolute;
        top: 0;
        left: 7.5px;
    }
}

//UI ELEMENT

.decoration {
    img {
        max-width: 100%;
        width: 100%;
        height: 110px;
        object-fit: cover;
        object-position: top;

        @media screen and (max-width:1440px) {
            height: 80px;
        }
    }

    &.bottom {
        position: absolute;
        bottom: 0;

        img {
            transform: rotate(180deg);
            object-position: bottom;
            margin-bottom: -40px;

            @media screen and (max-width:1440px) {
                margin-bottom: -20px;
            }
        }
    }
}

// HEADER
header {
  background: radial-gradient($light-blue, $main-blue);
  height: calc(100vh);
  padding-bottom: 2.5rem;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  /*
    @media screen and (max-width:992px) {
      height: 50vh;
      padding: 2rem 1rem;
    }
  */

  .container {
    height: calc(100% - 156px - 100px);
  }

  .row, .col-lg-12, .content-header {
    height: 100%;
  }

  .wrapper-carousel {
    //width: 100%;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    overflow: hidden;
    width: 100vw;
    left: 50%;
    transform: translateX(-50%);

    @media screen and (max-width: 992px) {
      width: 100vw;
      left: 50%;
      transform: translateX(-50%);
    }

    @media only screen and (min-width: 1440px) and (max-width: 1536px) and (min-device-pixel-ratio: 1.25) {
      width: 85%;
      left: unset;
      transform: none;
    }

    @media only screen and (min-width: 1440px) and (max-width: 1536px) and (-webkit-min-device-pixel-ratio: 1.25) {
      width: 85%;
      left: unset;
      transform: none;
    }

    @media only screen and (min-width: 1440px) and (max-width: 1536px) and (min--moz-device-pixel-ratio: 1.25) {
      width: 85%;
      left: unset;
      transform: none;
    }

    .swiper {
      margin-left: auto;
      margin-right: auto;
      position: relative;
      /*overflow: hidden;*/
      list-style: none;
      //padding: 0 50px 32px;
      padding: 0 0 1.75rem;
      z-index: 1;
      height: 100%;
      /*@media screen and (min-width: ) and (max-width: ) {
        padding-bottom: unset;
      }*/

      @media screen and (max-width: 768px) {
        padding: 0 0 3rem;
      }

      .swiper-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 1;
        display: flex;
        transition-property: transform;
        //box-sizing: content-box;
        box-sizing: border-box;
      }

      .swiper-slide {
        background-position: center;
        background-size: cover;

        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    .swiper-container-3d .swiper-slide-shadow-left, .swiper-container-3d .swiper-slide-shadow-right {
      background-image: none;
    }

    img {
      display: block;
      margin: 0 auto;
      /*width: 90%;*/
      -webkit-box-reflect: below 5px -webkit-linear-gradient(bottom, rgba(255, 0, 0, 0.1) 25%, transparent 32px, transparent 100%);
      z-index: 0;
      position: relative;
      /*
        @media screen and (max-width:1660px) {
            width: 75%;
        }
      */
      @media screen and (max-width:1440px) {
        width: 70%;
      }
    }
  }

  .content-header {
    /*position: absolute;
    top: 50%;
    left: 50%;
    bottom: 14%;
    transform: translate(-50%, 0);
    transform: translate(-50%, -50%);*/
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .wrapper-title {
      /*position: relative;
      z-index: 10;
      margin-top: -30px;*/
      width: 100%;

      .txt-fit-holder {
      }

      h1 {
        @include font-ultrablack(7.5rem);
        line-height: 1;
        color: #FFF;
        text-transform: uppercase;
        //margin: 0 0 1.5rem;
        margin-bottom: 2.5rem;
        text-shadow: 0px 6px 22px rgb(255 255 255 / 90%);

        @media screen and (max-width: 992px) {
          font-size: 6rem;
        }

        @media screen and (max-width: 640px) {
          line-height: 1;
        }

        @media screen and (max-width: 1280px) and (max-height: 640px) {
          font-size: 4.5rem;
          margin-bottom: 1rem;
        }
      }
    }
  }
}

//card
.wrapper-card {
    //margin: 2rem 0;
    margin: 3.75rem 0 2.25rem;
    @media screen and (max-width: 640px) {
      margin: 1.5rem 0;
    }

    .single-card {
        margin: 1.5rem 0;

        .img-card {
            margin: 0 0 1.25rem;

            img {
                width: 100%;
            }
        }
    }
}

.internal {
  .mistery-player {
    margin-top: unset;
    background-image: unset;
  }
}

.mistery-player {
  background-color: #000;
  background-image: url($resolvepath + "assets/bg-mistery.svg");
  // background-image: url($resolvepath + "assets/sfondo-campo-2.png");
  background-repeat: repeat;
  background-size: auto;
  color: #FFF;
  margin:  0 0;
  position: relative;

  .wrapper-content {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    // padding: 0 4rem;
    padding: 7.375rem 0 6rem;
    margin: 2rem auto;
    overflow: hidden;
    // border-left: 10px solid $secondary-color;
    // border-right: 10px solid $secondary-color;
    /*@media screen and (max-width: 768px) {
      border-left: none;
      border-right: none;
      margin: 2rem auto;
    }*/
    @media screen and (max-width: 1280px) {
      padding: 4rem 0 3rem;
    }

    @media screen and (max-width: 768px) {
      //padding: 0 0 3rem;
      padding: 3rem 0 5rem;
      flex-wrap: wrap;
    }

    .wrapper-text, .wrapper-card {
      align-self: stretch;
    }

    .wrapper-text {
      flex: 1 1 60%;
      width: 60%;
      padding-left: 6.25rem;
      border-left: 10px solid $secondary-color;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media screen and (max-width: 820px) {
        width: 50%;
        flex: 1 1 50%;
      }

      @media screen and (max-width: 768px) {
        flex: 1 1 100%;
        width: auto;
        order: 2;
        border-left: unset;
        padding-left: 1rem;
        padding-right: 1rem;
      }

      .txt-fit-holder {
        margin-left: unset;
      }

      h2 {
        //@include font-black(5.5rem);
        @include font-black(4.5rem);
        line-height: 1;
        color: $secondary-color;
        text-transform: uppercase;
        margin: 0 0 1rem;
        text-align: left;

        @media screen and (max-width: 820px) {
          @include font-black(3rem);
          white-space: normal;
          word-break: normal;
        }

        @media screen and (max-width: 768px) {
          @include font-black(4.5rem);
        }
      }

      p {
        color: #FFF;
        margin-top: 0;
        //line-height: 1.15;
        line-height: 1.35;
        margin-bottom: 1.5rem;
      }

      .btn.secondary {
        color: #000;
        letter-spacing: 2px;

        &:after {
          background-image: url($resolvepath + 'assets/arrow-btn-black.svg');
        }
      }
    }

    .wrapper-card {
      flex: 1 1 40%;
      width: 40%;
      text-align: center;
      /*padding-right: 6.25rem;
      padding-left: 6.25rem;*/
      border-right: 10px solid $secondary-color;
      margin-top: unset;
      margin-bottom: unset;
      display: flex;
      align-items: center;

      @media screen and (max-width: 768px) {
        flex: 1 1 100%;
        width: auto;
        order: 1;
        padding-left: 1rem;
        padding-right: 1rem;
        margin-top: -1rem;
        border-right: unset;
      }

      img {
        @media screen and (max-width: 768px) {
          max-width: 50%;
        }

        @media screen and (max-width: 640px) {
          max-width: unset;
        }
      }
    }
  }
}

.roadmap {
  &.with-bg {
    background-color: rgba($color: $main-blue, $alpha: .1);
  }

  &.light {
    .wrapper-content {
      border-left: 10px solid $light-blue;

      @media screen and (max-width: 992px) {
        border-left: 0;
      }

      &.txt-right {
        text-align: right;
        border-right: 10px solid $light-blue !important;
        border-left: 0;
      }

      .wrapper-text {
        h2 {
          color: $light-blue;
        }
      }
    }
  }

  &.green {
    .wrapper-content {
      border-left: 10px solid #00FF8C;

      @media screen and (max-width: 992px) {
        border-left: 0;
      }

      .wrapper-text {

        h2 {
          color: #00FF8C;
        }
      }
    }
  }

  &.red {
    .wrapper-content {
      border-left: 10px solid $secondary-color;

      @media screen and (max-width:992px) {
        border-left: 0;
      }

      .wrapper-text {

        h2 {
          color: $secondary-color;
        }
      }
    }
  }

  .wrapper-title-wrap {
    &.wrapper-title-roadmap {
      padding: 2.5rem 0;

      .txt-fit-holder {
        //width: calc(100% - (17.2rem + 14rem));

        /*@media screen and (max-width: 768px) {
          width: calc(100% - (12.5rem))
        }*/

        @media screen and (max-width: 640px) {
          width: 100%;
        }

        .txt-fit {
          padding-top: unset;

          @media screen and (max-width: 640px) {
            font-size: 5.25rem;
          }
        }
      }
    }
  }

  .wrapper-content {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    padding: 0 4rem;    
    margin: 2rem auto;
    border-left: 10px solid $main-blue;


    &.txt-right {
      text-align: right;
    }

    @media screen and (max-width: 992px) {
      border-left: 0;
      padding: 0 2rem;
      margin: 2rem auto;
    }

    @media screen and (max-width: 768px) {
      padding: 0;
    }

    @media screen and (max-width: 640px) {
      padding: 0 1rem;
    }

    .wrapper-text {
      box-sizing: border-box;
      flex: 0 0 70%;

      &.full-width {
        flex: 0 0 100%;
      }

      @media screen and (max-width: 768px) {
        flex: 0 0 50%;
      }

      @media screen and (max-width: 640px) {
        flex: 0 0 100%;
      }

      h2 {
        color: $main-blue;
        @include font-black(3.5rem);
        line-height: .9;
        text-transform: uppercase;
        margin: 0 0 1rem;

        @media screen and (max-width: 768px) {
          font-size: 2.5rem;
        }
      }

      p {
        font-size: 1.225rem;
        line-height: 1.5;
        margin-bottom: 1.5rem;

        &:last-child {
          margin-bottom: 0;

          @media screen and (max-width: 640px) {
            margin-bottom: 1.75rem;
          }
        }
      }
    }

    .wrapper-card {
      box-sizing: border-box;
      flex: 0 0 30%;
      text-align: center;
      padding: 2.5rem 4rem 0;
      margin-bottom: 1.5rem;

      @media screen and (max-width: 820px) {
        padding-left: 2.5rem;
        padding-right: unset;
      }

      @media screen and (max-width: 768px) {
        flex: 0 0 50%;
        padding: 2.5rem 0 0 4rem;
      }

      @media screen and (max-width: 640px) {
        flex: 0 0 100%;
        padding: 0;
      }

      img {
        @media screen and (max-width: 768px) {
          display: block;
          max-height: 200px;
          margin-top: auto;
          margin-bottom: auto;
        }
      }
    }
  }
}

.tab-player {
  @media screen and (max-width: 640px) {
    max-width: calc(100% - 18px);
    margin: 0 auto;
  }
  .container {
    .single-card {
      @media screen and (max-width: 1180px) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      ul {
        li {
          @media screen and (max-width: 1180px) {
            flex: 0 1 50%;
            flex-wrap: nowrap;
            justify-content: flex-start;
            line-height: 1;
          }
        }
      }

      .btn.secondary.blue {
        @media screen and (max-width: 1180px) {
          display: flex;
          flex-wrap: nowrap;
          line-height: 1;
          padding: 0 .5rem;
        }

        &:after {
          @media screen and (max-width: 1180px) {
            width: 1.75rem;
            marign-left: .5rem;
          }
        }
      }
    }
  }
}

.tab-wrapper {
  .tab-controller {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    padding: 0;
    margin: 0;

    @media screen and (max-width: 820px) {
      position: relative;
      width: 100vw;
      left: 50%;
      transform: translateX(-50%);
    }

    .single-controller {
      flex-grow: 1;
      text-align: center;
      padding: 1rem;
      cursor: pointer;
      text-transform: uppercase;
      @include font-black(1.5rem);
      color: $main-blue;
      background-color: rgba($color: $main-blue, $alpha: .10);

      &:nth-child(odd) {
        background-color: rgba($color: $main-blue, $alpha: .05);
      }

      &.active {
        background-color: $light-blue;
      }

      &:last-child {
        background-color: #000;
        color: $secondary-color;
      }

      @media screen and (max-width: 992px) {
        flex: 1 1 auto;
        text-align: center;
      }

      @media screen and (max-width: 640px) {
        flex: 1 1 50%;
      }
    }
  }

  .single-tab {
    display: none;
    max-width: 100%;
    margin: 0 auto;
    background-color: rgba($color: $main-blue, $alpha: .10);

    &:nth-child(odd) {
      background-color: rgba($color: $main-blue, $alpha: .05);
    }

    &.mistery-player {
      margin: 0;
      //background-color: #000;
      position: relative;
      background-color: transparent;
      background-image: none;

      &:before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100vw;
        height: 100%;
        background-color: #000;
        background-image: url($resolvepath + "assets/bg-mistery.svg");
        background-repeat: repeat;
        background-size: auto;
      }

      .wrapper-content {
        margin: 0;
        //padding: 2rem;
        padding: 7.375rem 0 6rem;
        border-left: 0;
        border-right: 0;

        .wrapper-text {
          border-left: unset;
          padding-left: 4.25rem;

          @media screen and (max-width: 768px) {
            padding-left: 1.125rem;
          }

          .txt-fit-holder {
            width: 100%;
            margin-left: unset;
          }
        }

        .wrapper-card {
          margin: 0;
          border-right: unset;

          img {
            //width: 70%;
          }
        }
      }
    }

    .single-card {
      margin-top: 15px;
      margin-bottom: 15px;

      .card-info {
        .label-availability {
          @include font-semibold(1.125rem);
          margin: 0 0 10px;
          color: $main-blue;
        }
      }

      ul {
        margin: 10px 0 0;
        justify-content: space-between;

        li {
          margin: 0 0 10px;
          color: $main-blue;
          @include font-semibold(1.125rem);
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;

          &::before {
            content: "";
            width: 25px;
            height: 25px;
            margin-right: 5px;
            background-size: 80%;
            background-repeat: no-repeat;
            background-position: center;
          }

          &.level {
            .not-available {
              color: $secondary-color;
            }

            &::before {
              background-size: 100%;
              background-image: url($resolvepath+'assets/icon-campo.svg');
            }
          }

          &.coin {
            &::before {
              /*background-image: url($resolvepath+'assets/icon-coin.svg');*/
              background-image: url($resolvepath+'assets/eth.svg');
            }
          }
        }
      }
    }

    &.active {
      display: flex;
    }
  }
}

.tab-player {
  //background-color: rgba($color: $main-blue, $alpha: .10);
  background-color: #e5e5f3;
  padding: 15px 0 25px;

  @media screen and (max-width: 640px) {
    position: relative;
    //margin-bottom: 2.5rem;
  }

  &:before {
    @media screen and (max-width: 640px) {
      content: "";
      width: 100vw;
      height: 100%;
      position: absolute;
      z-index: -1;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      background-color: #e5e5f3;
    }
  }
}

.not-available {
    color: $secondary-color;
}

.single-card {
    margin-top: 15px;
    margin-bottom: 15px;
    opacity: 0;

    img {
        cursor: pointer;
    }

    .card-info {
        .label-availability {
            @include font-semibold(1.125rem);
            margin: 0 0 10px;
            color: $main-blue;
        }
    }

    ul {
        margin: 10px 0 0;
        justify-content: space-between;

        li {
            margin: 0 0 10px;
            color: $main-blue;
            @include font-semibold(1rem);
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;

            &::before {
                content: "";
                width: 25px;
                height: 25px;
                margin-right: 5px;
                background-size: 80%;
                background-repeat: no-repeat;
                background-position: center;
            }

            &.level {
                /*&::before {
                    background-size: 100%;
                    background-image: url($resolvepath+'assets/icon-campo.svg');
                }*/
            }

            &.coin {
                &::before {
                    background-image: url($resolvepath+'assets/eth.svg');
                }
            }
        }
    }
}

section {
  &.list-content-cont {
    @media screen and (max-width: 640px) {
      padding: 1rem 1rem 0;
    }
  }
}

.btn-filter-mobile {
  display: none;

  @media screen and (max-width: 640px) {
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    z-index: 5000;
    top: 110px;
    width: 100vw;
    height: 36px;
    margin-top: -1rem;
    margin-left: -1rem;
    font-size: 1.25rem;
    text-transform: uppercase;
    font-weight: 400;
    line-height: 1;
    letter-spacing: .5px;
    background-color: #fff;
  }

  @media screen and (max-width: 375px) {
    top: 105px;
  }

  .fa-list-ul {
    @media screen and (max-width: 640px) {
      margin-top: 0.125rem;
      margin-right: 0.5rem;
    }
  }
}

.filters-top-wrap {
  @media screen and (max-width: 640px) {
    position: fixed;
    z-index: 15000;
    top: 110px;
    left: 0;
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    transition: all ease-in-out .125s;
  }

  @media screen and (max-width: 375px) {
    top: 105px;
  }

  &.open {
    @media screen and (max-width: 640px) {
      transform: none;
    }

    .btn-filter-close, .overlay {
      opacity: 1;
    }
  }

  .row.filter {
    @media screen and (max-width: 991px) {
      flex-wrap: nowrap;
    }
    @media screen and (max-width: 640px) {
      flex-wrap: wrap;
    }

    .list-filter.flex-start {
      @media screen and (max-width: 991px) {
        justify-content: space-between;
      }
    }
  }

  label {
    text-transform: uppercase;

    @media screen and (max-width: 1367px) {
      font-size: 1.25rem;
    }

    @media screen and (max-width: 991px) {
      font-size: 1rem;
      white-space: nowrap;
      margin-right: auto;
    }
  }

  .filters-top-wrap-inner {
    @media screen and (max-width: 640px) {
      flex: 0 0 85%;
      background-color: #fff;
      padding: 1.25rem;
    }

    .filter {
      .col-lg-8.has-w-auto {
        @media screen and (max-width: 640px) {
          width: 100%;
        }

        .list-filter {
          @media screen and (max-width: 640px) {
            padding-bottom: 1.125rem;
            border-bottom: 0.5px solid #c5c5c5;
            margin-bottom: 1.25rem;
          }
        }
      }

      .filter-available-cont {
        @media screen and (max-width: 640px) {
          width: 100%;
        }

        .list-filter {
          @media screen and (max-width: 640px) {
            width: 100%;
            margin-bottom: unset;
          }

          li {
            @media screen and (max-width: 640px) {
              justify-content: flex-start;
              /*padding-top: 1.125rem;
              padding-bottom: 1.125rem*/;
              border-top: 0.5px solid #c5c5c5;
              border-bottom: 0.5px solid #c5c5c5;
              margin-top: 1.25rem;
              /*margin-bottom: 1rem;*/
              padding-top: 0.75rem;
              padding-bottom: 0.75rem;
              margin-bottom: 0.5rem;
            }
          }
        }
      }

      .form-field {
        @media screen and (max-width: 640px) {
          margin-top: unset;
        }

        &.field-search {
          @media screen and (max-width: 640px) {
            margin-top: unset;
            margin-right: unset;
          }

          .input-search-cont, .btn.secondary {
            @media screen and (max-width: 640px) {
              flex-grow: 1;
              height: 28px;
            }
          }
        }

        &.has-mobile-flex-end {
          @media screen and (max-width: 640px) {
            justify-content: flex-end;
          }
        }
      }
    }

    .filter-players-by-look {
      label {
        @media screen and (max-width: 640px) {
          margin-bottom: .5rem;
        }
      }

      .shirts-pattern-wrap {
        @media screen and (max-width: 640px) {
          padding-bottom: 1.125rem;
          border-bottom: 0.5px solid #c5c5c5;
          margin-bottom: .5rem;
        }
      }

      .js-filter-colors {
        min-height: 79px;

        @media screen and (max-width: 640px) {
          min-height: unset;
        }

        &:last-of-type {
          @media screen and (max-width: 640px) {
            margin-top: -1rem;
          }
        }

        label {
          @media screen and (max-width: 640px) {
            margin-left: unset;
            margin-bottom: .5rem;
          }
        }

        .filter-by-colors {
          @media screen and (max-width: 640px) {
            margin-right: unset;
            margin-left: unset;
          }

          &:after {
            @media screen and (max-width: 640px) {
              content: "";
              display: block;
              clear: both;
            }
          }
        }
      }
    }
  }

  .overlay {

    @media screen and (max-width: 640px) {
      flex: 0 0 15%;
      background-color: rgba(0,0,0,.45);
      opacity: 0;
      transition: opacity ease-in-out .25s;
      transition-delay: .35s;
    }
  }

  .btn-filter-close {
    display: none;

    @media screen and (max-width: 640px) {
      position: absolute;
      top: 1rem;
      right: 7.5%;
      transform: translateX(50%);
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      line-height: 1;
      font-size: 2rem;
      color: $main-blue;
      background-color: #3DFFFF;
      width: 36px;
      height: 36px;
      padding-bottom: .5rem;
      border-radius: 100%;
      opacity: 0;
      transition: opacity ease-in-out .25s;
      transition-delay: .35s;
    }
  }
}

.filter {
  .list-filter {
    @media screen and (max-width: 640px) {
      flex-wrap: wrap;
    }

    &.flex-mobile-center {
      @media screen and (max-width: 640px) {
        justify-content: center !important;
        align-items: center !important;
      }

      li {
        @media screen and (max-width: 640px) {
          flex-grow: 1;
          display: flex;
          justify-content: center;
        }
      }
    }

    li {
      @media screen and (max-width: 640px) {
        flex: 0 1 50%;
      }

      &.order-by-rarity {
        width: 100%;
        flex: 2;

        @media screen and (max-width: 991px) {
          width: auto;
          flex: unset;
        }

        @media screen and (max-width: 640px) {
          width: 100%;
          flex-grow: 1;
          margin-top: .65rem;
          margin-bottom: .125rem;
        }

        .form-field {
          flex-direction: row-reverse;

          @media screen and (max-width: 991px) {
            flex-direction: row;
          }

          @media screen and (max-width: 640px) {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
          }

          label {
            margin-right: .75rem;

            @media screen and (max-width: 991px) {
              margin-right: auto;
              margin-left: auto;
            }

            @media screen and (max-width: 640px) {
              margin-top: 2px;
              margin-right: unset;
              margin-bottom: 2px;
              margin-left: unset;
            }
          }

          select {
            min-width: 175px;
            height: 28px;
            font-size: .9rem;
            padding-right: 10px;
            padding-bottom: 1px;
            padding-left: 10px;
            margin-top: -1px;
            border: .5px solid $main-blue;
            border-radius: unset;

            @media screen and (max-width: 991px) {
              min-width: unset;
              padding: 0 5px;
              margin-right: 5px;
            }

            @media screen and (max-width: 640px) {
              margin-top: unset;
              width: 100%;
            }

            &:placeholder {
              font-size: .9rem;
            }

            options {
              font-size: .9rem;
            }
          }
        }
      }
    }
  }

  .has-w-auto {
    width: auto;
  }

  .has-f-grow {
    flex-grow: 1;
  }

  .list-filter-wrap {
    @media screen and (max-width: 1279px) {
      flex-grow: 1;
    }
  }

  .field-search-wrap {
    @media screen and (max-width: 1279px) {
      flex-grow: 1;
    }

    @media screen and (max-width: 991px) {
      width: auto;
      flex-shrink: 1;
    }
  }

  .filter-available-cont {
    @media screen and (max-width: 1279px) {
      width: fit-content;
    }

    @media screen and (max-width: 991px) {
      flex-shrink: 1;
    }
  }

  .field-search {
    justify-content: center;
    height: 30px;
  }

  .input-search-cont {
    border: .5px solid $main-blue;
    height: 28px;
    margin-right: 5px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;

    .input-search {
      width: calc(100% - 21px - 7px);
      border: unset;
      background-color: transparent;
      border-radius: unset;
      font-size: .9rem;
      padding-right: 10px;
      padding-bottom: 1px;
      padding-left: 10px;
    }

    .fa-magnifying-glass {
      font-size: .9rem;
      align-self: center;
      padding-right: 0.5rem;
    }

    .btn.secondary.blue {
      border: .5px solid $main-blue;
    }
  }

  .btn-search-player {
    height: 28px;
    font-size: 10px;
    border-radius: unset;

    &:after {
      width: .75rem;
      height: .75rem;
    }
  }

  .form-field {
    display: flex;
    align-items: center;
    margin: 15px 15px 0 0;
    @include font-black(1.5rem);
    color: $main-blue;
    height: 28px;

    @media screen and (max-width: 991px) {
      //margin: 10px 10px 0 0;
      margin-top: 10px;
      margin-right: auto;
    }

    @media screen and (max-width: 640px) {
      height: auto;
    }

    input[type="radio"], input[type="checkbox"] {
      appearance: none;
      width: 22px;
      height: 22px;
      background-color: rgba($color: $main-blue, $alpha: .1);
      margin-right: 10px;

      @media screen and (max-width: 991px) {
        width: 18px;
        height: 18px;
        margin-right: 6px;
      }

      &:checked {
        background-image: url($resolvepath+'assets/check.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 80%;
      }
    }
  }
}

.tmp-filter {
  //display: none;
}

.single-shirt {
  &.btn-shirts-all {
    text-align: center;
    border: 3px solid $main-blue;
    padding: 5px 10px;
    max-height: 42.5px;
    display: flex;
    align-items: center;

    @media screen and (max-width: 1279px) {
      padding: 5px 7px;
    }

    @media screen and (max-width: 991px) {
      font-size: 1.25rem;
    }

    @media screen and (max-width: 640px) {
      flex-grow: 0;
    }
  }
}

.shirts-pattern-wrap {
  @media screen and (max-width: 640px) {
    margin-bottom: 1rem;
  }
}

.filter-players-by-look {
  font-family: acumin-pro, sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 1.5rem;
  color: #00008d;
  margin-bottom: 20px;
  text-transform: uppercase;
  margin-bottom: 1.25rem;
  justify-content: flex-end;

  .js-filter-colors {
    > label {
      //margin-left: -4.5px;
    }
  }

  .filter-by-colors-wrap {
    display: flex;
    flex-wrap: nowrap;
    height: 100%;
    max-height: 47.5px;

    @media screen and (max-width: 640px) {
      height: auto;
      max-height: unset;
      margin-bottom: 1.5rem;
    }

    .filter-by-colors {
      display: inline-flex;
      clear: both;
      flex-wrap: wrap;
      max-width: 60%;

      @media screen and (max-width: 1279px) {
        display: inline-block;
      }
    }

    .btn-colors-all {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      line-height: 1;
      border: 3px solid $main-blue;
      margin-bottom: 5px;
      max-height: 47.5px;
      font-size: .9rem;

      @media screen and (max-width: 1366px) {
        margin-bottom: 10px;
      }

      @media screen and (max-width: 640px) {
        font-size: 1.25rem;
        height: 41px;
        margin-bottom: unset;
      }

      &.selected {
        background-color: rgba(0, 0, 141, 0.1);
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  .btn-reset-all {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 1;
    border: 3px solid $main-blue;
    margin-bottom: 5px;
    height: 100%;
    min-height: 42.5px;
    max-height: 42.5px;
    font-size: .9rem;
    position: relative;

    @media screen and (max-width: 1439px) {
      margin-bottom: 10px;
    }

    @media screen and (max-width: 1279px) {
      font-size: .85rem;
      min-height: 37.5px;
      max-height: 37.5px;
      margin-bottom: 14.5px;
    }

    @media screen and (max-width: 640px) {
      font-size: 1rem;
    }

    &.selected {
      background-color: rgba(0, 0, 141, 0.1);
    }

    &:hover {
      cursor: pointer;
    }

    &:before {
      @media screen and (max-width: 640px) {
        content: "";
        position: absolute;
        top: 0;
        width: 100%;
        height: 0;
        border-top: 0.5px solid #c5c5c5;
      }
    }
  }

  .filter-by-colors {
    width: calc(100% + 5px);
    //margin-left: -2.5px;
    //margin-right: -2.5px;

    .single-input, .btn-colors-all {
      &:hover {
        cursor: pointer;
      }
    }

    .single-input {
      display: inline-flex;
      float: left;
      align-items: center;
      justify-content: center;
      margin-right: 5px;
      margin-bottom: 5px;
      width: 20px;
      height: 20px;

      @media screen and (max-width: 1279px) {
        width: 15px;
        height: 15px;
        margin-right: 2.5px;
        margin-bottom: 2.5px;
      }

      @media screen and (max-width: 991px) {
        width: 12.5px;
        height: 12.5px;
      }

      @media screen and (max-width: 640px) {
        width: 20px;
        height: 20px;
      }

      &:hover {
        cursor: pointer;
      }

      input {
        width: 100%;
        height: 100%;
        border: 1px solid $main-blue;
        border-radius: unset;
        appearance: initial;

        &:hover {
          cursor: pointer;
        }

        &.selected {
          opacity: .75;
          background-image: url($resolvepath + "assets/check.svg");
          background-size: 80%;
          background-repeat: no-repeat;
          background-position: center;
        }

        &.maroon {
          background-color: #800000;
        }

        &.darkkhaki {
          background-color: #BDB76B;
        }

        &.mediumseagreen {
          background-color: #3CB371;
        }

        &.violet {
          background-color: #EE82EE;
        }

        &.palegreen {
          background-color: #98FB98;
        }

        &.deeppink {
          background-color: #FF1493;
        }

        &.red {
          background-color: red;
        }

        &.blue {
          background-color: blue;
        }

        &.fuchsia {
          background-color: #FF00FF;
        }

        &.burlywood {
          background-color: #DEB887;
        }

        &.lightsalmon {
          background-color: #FFA07A;
        }

        &.coral {
          background-color: #FF7F50;
        }

        &.purple {
          background-color: #800080;
        }

        &.maroon {
          background-color: #800000;
        }

        &.limegreen {
          background-color: #32CD32;
        }

        &.mediumturquoise {
          background-color: #48D1CC;
        }

        &.palegoldenrod {
          background-color: #EEE8AA;
        }

        &.navahowhite {
          background-color: #FFDEAD;
        }

        &.chartreuse {
          background-color: #7FFF00;
        }
      }
    }

    .btn-colors-all {
      font-size: 10px;
      font-size: 10px;
      line-height: 1;
      border: 1px solid #00008d;
      display: inline-flex;
      float: left;
      justify-content: center;
      align-items: center;
      width: 65px;
      height: 20px;
      margin-bottom: 5px;

      @media screen and (max-width: 640px) {
        margin-left: 3px;
        margin-right: 3px;
      }
    }
  }
}

.single-shirt {
  flex: 0 0 calc(100% / 5 - 15px);
  padding: 5px 10px;
  margin-right: 2.5px;
  border: 3px solid transparent;
  min-height: 42.5px;
  max-height: 42.5px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1279px) {
    padding: 5px;
  }

  @media screen and (max-width: 991px) {
    flex: 0 0 calc(20% - 5px);
    padding-left: 1px;
    padding-right: 1px;
  }

  @media screen and (max-width: 640px) {
    flex-grow: 1;
    min-height: unset;
  }

  &:hover {
    cursor: pointer;
  }

  &:not(.btn-shirts-all) {
    @media screen and (max-width: 640px) {
      max-width: 56.5px;
    }
  }

  &.selected {
    border: 3px solid $main-blue;
    background-color: rgba(0, 0, 141, 0.1);
  }

  img {
    width: 100%;
    max-width: 100%;
    object-fit: contain;
  }
}

//MODAL
.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 888888;
  /*background-color: #FFF;*/
  background-color: rgba(0,0,0,0.8);
  display: none;
  overflow: auto;
  height: 100%;

  .body-modal {
    background: #FFF;
    width: 100%;
    height: 100%;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 50px 25px 50px 2.5rem;
    //overflow: scroll;
    overflow: hidden;
    //border-radius: .85rem;

    @media screen and (max-width: 992px) {
      padding: 5rem 2.5rem 5rem 2.5rem;
    }

    @media screen and (max-width: 640px) {
      padding: 5rem 1.125rem;
      position: relative;
    }

    &:before, &:after {
      @media screen and (max-width: 640px) {
        content: "";
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        width: 100%;
        height: 5rem;
        background-color: #f2f2f9;
      }
    }

    &:before {
      @media screen and (max-width: 640px) {
        top: 0;
      }
    }

    &:after {
      @media screen and (max-width: 640px) {
        bottom: 0;
      }
    }

    .content {
      width: 100%;
      max-width: calc(100% - 6rem);
      height: 100%;
      padding-right: 3rem;
      margin: 0 auto;
      overflow-y: scroll;
      display: flex;
      /* ===== Scrollbar CSS ===== */
      /* Firefox */
      scrollbar-width: thin;
      scrollbar-color: $main-blue #f1f1f1;
      /* Chrome, Edge, and Safari */
      &::-webkit-scrollbar {
        width: 12px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $main-blue;
        border-radius: 10px;
        border: 3px solid #ffffff;
      }

      @media screen and (max-width: 992px) {
        max-width: calc(100% - 3rem);
      }

      @media screen and (max-width: 640px) {
        max-width: 100%;
        padding-left: 0.5rem;
        padding-right: 1rem;
      }

      .single-card-detail-wrapper {
        margin-top: unset;
        margin-bottom: unset;
        @media screen and (max-width: 640px) {
          padding-bottom: 2.75rem;
        }
      }
    }
  }

  &.active {
    display: block;
  }

  .close {
    font-size: 2rem;
    color: $main-blue;
    position: absolute;
    right: 2rem;
    //top: 2rem;
    top: 50px;
    cursor: pointer;
    z-index: 1000;

    @media screen and (max-width: 992px) {
      top: .75rem;
      right: 1.25rem;
    }
  }

  .next, .prev {
    font-size: 2.25rem;
    color: $main-blue;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1000;
    cursor: pointer;

    @media screen and (max-width: 640px) {
      top: unset;
      bottom: -.85rem;
    }
  }

  .next {
    /*font-size: 2rem;
        color: $main-blue;
        position: absolute;*/
    right: 1.5rem;
    /*top: 50%;
        cursor: pointer;
        z-index: 1000;*/
    @media screen and (max-width: 640px) {
      right: calc(50% - 2.75rem);
    }
  }

  .prev {
    /*font-size: 2rem;
        color: $main-blue;
        position: absolute;*/
    left: 1.5rem;
    /*top: 50%;
        cursor: pointer;
        z-index: 1000;*/
    @media screen and (max-width: 640px) {
      left: calc(50% - 4rem);
    }
  }

  .container {
    width: 100%;
    max-width: 100%;
  }

  .content {
    /*width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);*/

    @media screen and (max-width:992px) {
      position: relative;
      top: auto;
      left: auto;
      transform: none;
      padding: 1rem;
      box-sizing: border-box;
    }

    .img-card {
      @media screen and (max-width:992px) {
        //width: 50%;
        margin: 0 auto;
        display: block;
      }
    }
  }
}

.single-card-detail-wrapper {
  margin-top: 25px;
  margin-bottom: 25px;

  .btn {
    &.xs {
      font-size: 1.125rem;
      line-height: 1;
      height: auto;

      @media screen and (max-width: 1180px) {
        font-size: .85rem;
        padding: 3px 15px 4px;
      }

      &.js-buy-cc {
        @media screen and (max-width: 1180px) {
          font-size: .75rem;
          padding-left: .75rem;
        }
      }
    }
  }
}

.body-modal {
  .single-card-detail-wrapper {
    .player-card {      
      @media screen and (max-width: 820px) {
        margin-bottom: unset;
      }
      @media screen and (max-width: 819px) {
        padding: unset;
        flex: 1 1 100%;
        margin-bottom: 2.5rem;
        min-height: unset;
      }

      .img-player-card-cont {
        @media screen and (max-width: 820px) {
          width: 40%;
          margin-bottom: unset;
        }

        @media screen and (max-width: 640px) {
          /*flex: 1 1 40%;*/
        }

        img {
          @media screen and (max-width: 820px) {
            width: 100%;
          }

          &:hover {
            cursor: pointer;
          }
        }
      }

      .main-player-info {
        @media screen and (max-width: 820px) {
          width: 60%;
          margin-bottom: unset;
        }

        @media screen and (max-width: 640px) {
          /*flex: 1 1 60%;*/
          float: right;
          //padding-left: unset;
        }

        .bar-graph {
          .label-value {
            @media screen and (max-width: 640px) {
              font-size: 1rem;
            }
          }
        }
      }
    }

    .buy-container {
      top: 0;

      @media screen and (max-width: 992px) {
        position: sticky;
      }

      @media screen and (max-width: 820px) {
        position: unset;
        padding: 2rem;
        border-right: 5px solid $main-blue;
        background-color: #F2F2F9;
        flex: 1 1 100%;
      }

      @media screen and (max-width: 640px) {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        margin: 0 auto;
        //max-width: calc(100% - var(--bs-gutter-x) * .5 * 2);
        max-width: 100%;
      }
    }

    .player-stats-info {
      /*@media screen and (max-width: 992px) {
        margin-top: -10rem;
      }*/

      @media screen and (max-width: 820px) {
        padding-left: unset;
        padding-right: unset;
        margin-top: 2.5rem;
        flex: 1 1 100%;
      }

      .list-statistic-pie {
        @media screen and (max-width: 640px) {
          flex-wrap: wrap;
          justify-content: center;
        }

        .single-pie {
          @media screen and (max-width: 640px) {
            padding: 1rem;
          }
        }
      }

      .info.health {
        @media screen and (max-width: 640px) {
          margin-top: -1rem;
          margin-bottom: unset;
        }
      }

      .features {
        @media screen and (max-width: 640px) {
          margin-bottom: -1rem;
        }

        .features-block {
          .single-features-wrap {
            @media screen and (max-width: 640px) {
              flex-wrap: wrap;
              justify-content: flex-start;
              width: calc(100% + 1rem);
              margin-left: -0.5rem;
              margin-right: -0.5rem;
            }

            .feature {
              @media screen and (max-width: 640px) {
                width: auto;
                flex: 0 1 50%;
                padding: .5rem;
                margin-bottom: .5rem;
              }
            }
          }
        }
      }

      .btn-show-details {
        @media screen and (max-width: 640px) {
          margin-bottom: 2rem;
        }
      }
    }
  }
}

.single-card-infomation-details {
  &.internal {
    padding: 2rem 0;

    @media screen and (max-width: 640px) {
      padding: 0 1rem;
    }

    .single-card-detail-wrapper {
      padding-top: 2.75rem;
      padding-bottom: 2.75rem;
      margin-top: unset;
      margin-bottom: unset;
    }

    .player-stats-info {

      @media screen and (max-width: 640px) {
        padding-right: calc(var(--bs-gutter-x) * .5);
      }
    }

    .buy-container {
      position: static;
    }

    .wrapper-info {
      .features {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .features-block {
          width: calc(50% - 0.75rem);

          @media screen and (max-width: 640px) {
            width: 100%;
          }
        }
      }
    }
  }

  h3 {
    //@include font-black(2.8rem);
    font-size: 2.5rem;
    font-weight: 400;
    color: $main-blue;
    margin-bottom: 0;
    line-height: 1;

    @media screen and (max-width: 1180px) {
      font-size: 2rem;
    }

    @media screen and (max-width: 640px) {
      font-size: 1.75rem;
    }
  }

  .player-card {
    flex: 1 1 63.5%;
    display: block;
    float: left;
    width: 63.5%;
    padding-right: 1.875rem;
    margin-bottom: 3rem;

    @media screen and (max-width: 768px) {
      width: 100%;
    }

    @media screen and (max-width: 640px) {
      padding-right: calc(var(--bs-gutter-x) * .5);
      margin-bottom: 2.25rem;
    }

    .img-player-card-cont {
      display: inline-block;
      width: 30%;
      padding-right: 1rem;

      @media screen and (max-width: 640px) {
        padding-right: .5rem;
      }

      img {
        &:hover {
          cursor: pointer;
        }
      }
    }

    .main-player-info {
      display: inline-block;
      width: calc(70% - 1rem);
      padding-left: 1rem;
      float: right;

      .hearts-graph {
        .label-value {
          color: var(--bs-body-color);
          margin-bottom: .5rem;
        }

        .hearts {
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          width: 100%;
          height: 2rem;

          @media screen and (max-width: 640px) {
            height: 1.5rem;
          }

          .heart {
            display: block;
            margin-right: 10px;
            flex: 0 0 2rem;
            height: 100%;
            background-image: url($resolvepath + "assets/heart-empty.png");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;

            @media screen and (max-width: 640px) {
              flex: 0 0 1.5rem;
              margin-right: 5px;
            }

            &.heart-full {
              background-image: url($resolvepath + "assets/heart-full.png");
            }

            &.heart-half {
              background-image: url($resolvepath + "assets/heart-half.png");
            }
          }
        }
      }
    }
  }

  .buy-container {
    display: block;
    float: right;
    width: 36.5%;
    padding-left: 1.875rem;
    border-left: 5px solid $main-blue;
    position: sticky;
    top: calc(125px + 2.75rem);

    @media screen and (max-width: 992px) {
      position: unset;
    }

    @media screen and (max-width: 768px) {
      width: 100%;
      padding: 2rem;
      border-right: 5px solid $main-blue;
      background-color: #F2F2F9;
    }

    @media screen and (max-width: 640px) {
      max-width: calc(100% - var(--bs-gutter-x) * .5 * 2);
      padding-left: 1.25rem;
      padding-right: 1.25rem;
      margin: 0 auto;
    }

    p {
      line-height: 1.15;
    }
  }

  .player-stats-info {
    padding-right: 1.875rem;
    display: block;
    float: left;
    width: 63.5%;

    @media screen and (max-width: 768px) {
      margin-top: 2.5rem;
      width: 100%;
      padding-right: unset;
    }
  }

  .wrapper-info {
    display: block;
    float: left;
    width: 100%;
    margin-bottom: 2.75rem;
  }

  .p-internal-tb-wrap {
    width: 100%;
  }

  .nickname {
    display: flex;

    .form-field {
      padding: 0 10px;
      background-color: $main-blue;
      color: $light-blue;
      @include font-bold(1.125rem);
      font-size: 1.125rem;

      input {
        background: transparent;
        appearance: none;
        border: 0;
        color: $light-blue;
        @include font-bold(1.125rem);
        font-size: 1.125rem;
      }
    }

    .btn-edit {
      width: 30px;
      height: 30px;
      margin: 0 10px;
      background-color: $light-blue;
      text-align: center;
      box-sizing: border-box;
      padding: 0 5px;
      cursor: pointer;

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
  }

  .idcard, .rarity-info, .value-price {
    @include font-black(1.5rem);
    color: $main-blue;

    @media screen and (max-width: 1366px) {
      @include font-black(1.3rem);
    }
  }

  .idcard {
    //@include font-black(1.3rem);
  }

  .info {
    font-weight: 700;
    font-size: 2rem;
    color: $main-blue;

    @media screen and (max-width: 1180px) {
      font-size: 1.5rem;
    }

    @media screen and (max-width: 640px) {
      font-size: 1.3rem;
    }

    &.health {
      margin-bottom: 1.65rem;

      @media screen and (max-width: 1180px) {
        margin-bottom: 1.25rem;
      }
    }
  }

  .rarity-info {
    //@include font-black(1.3rem);
    //color: $main-blue;
  }

  .value-price {
    //@include font-black(1.3rem);
    //color: $main-blue;
    margin-right: 15px;

    &:before {
      content: "";
      width: 2.3rem;
      height: 2.3rem;
      background-image: url($resolvepath+'assets/eth.svg');
      background-size: contain;
      display: inline-block;
      vertical-align: middle;
    }
  }

  .gained {
    color: $green-gain;
  }

  .bar-graph {
    height: 3rem;
    background-color: $main-blue;
    width: 100%;
    position: relative;
    border: 0;
    font-size: 0;
    margin: .9rem 0;

    @media screen and (max-width: 640px) {
      height: 2.5rem;
    }

    .label-value {
      //@include font-bold(1.125rem);
      @include font-bold(1.5rem);
      color: #FFF;
      position: absolute;
      z-index: 300;
      //top: 50%;
      top: calc(50% - 1px);
      left: 1.125rem;
      transform: translate(0, -50%);
      line-height: 3rem;

      @media screen and (max-width: 1180px) {
        @include font-bold(1.3rem);
        top: calc(50% - 1px);
      }

      .point-gains {
        font-size: 1rem;
        margin-left: 0.5rem;
        color: #3dffff;
      }
    }

    .value-bar {
      height: 3rem;
      background-color: $light-blue;
      position: absolute;
      border: 0;
      font-size: 0;
      top: 0;
      left: 0;
      z-index: 200;
      animation: load 3s normal forwards;

      @media screen and (max-width: 640px) {
        height: 2.5rem;
      }
    }
  }

  .features {
    .features-block {
      font-size: 0;
      padding-bottom: 1rem;
      margin-bottom: .75rem;

      &:last-of-type {
        margin-bottom: unset;
      }

      .features-block-title {
        @include font-black(1.3rem);
        color: $main-blue;
        text-transform: uppercase;
        margin-bottom: .85rem;
      }

      .single-features-wrap {
        display: flex;
        justify-content: space-between;

        .feature {
          text-align: center;
          vertical-align: top;
          //display: inline-block;
          width: calc((100% / 3) - .5rem);

          .feature-info-wrap {
            position: relative;
            width: 100%;
            height: 0;
            padding-top: 56.25%;
            border: 2.5px solid $main-blue;
            //border-radius: .65rem;
            background-color: #f2f2f9;

            .feature-info {
              /*padding-top: 1rem;
              padding-bottom: 1rem;
              width: 90%;
              margin: 0 auto;
              border: 0.5rem solid $main-blue;
              border-radius: 1rem;*/
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              @include font-bold(1.125rem);
              color: $main-blue;
            }
          }

          .feature-get {
            //padding-top: 0.5rem;
            padding-top: 0.65rem;
            //width: 60%;
            display: inline-block;
            width: auto;
            margin: 0 auto;

            .btn {
              padding-left: 1.25rem;
              padding-right: 1.25rem;
            }
          }
        }
      }
    }
  }
}

@keyframes load {
    0% {
        width: 0;
    }

    100% {
        width: 100%;
    }
}

footer {
  background-color: #000053;
  padding: 2rem 0;

  @media screen and (max-width: 768px) {
    padding: 3.5rem 1.125rem;
  }

  .wrapper-loghi-power {
    display: flex;
    align-items: center;

    @media screen and (max-width: 768px) {
      margin-bottom: 1.75rem;
    }

    .single-logo {
      margin: 10px;

      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  p {
    color: #FFF;
    font-size: 14px;
    line-height: 1.25;

    @media screen and (max-width: 768px) {
      margin-bottom: 2.75rem;
    }
  }

  .label {
    font-size: 14px;
    color: #FFF;
    margin-bottom: 10px;
    @media screen and (max-width: 768px) {
      margin-top: .5rem;
      margin-bottom: .75rem;
    }
  }

  .logo-powered {
    width: 100px;
  }

  ul {
    margin: 0;
    display: block;
    column-count: 2;

    @media screen and (max-width: 768px) {
      margin-bottom: 1.5rem;
    }

    li {
      margin: 0 0 10px;

      @media screen and (max-width: 768px) {
        text-transform: uppercase;
      }

      a {
        color: $light-blue;
        text-decoration: none;

        &:hover {
          color: $secondary-color;
        }
      }
    }
  }

  .social {
    column-count: 1;
    display: flex;
    justify-content: flex-start;
    margin-top: .75rem;

    @media screen and (max-width: 640px) {
      margin-bottom: 1.25rem;
    }

    li {
      font-size: 1.2rem;
      margin: 0 5px;
      &:first-of-type {
        margin-left: unset;
      }
    }
  }

  .logo-footer {
    text-align: right;

    @media screen and (max-width: 992px) {
      text-align: center;
    }

    @media screen and (max-width: 768px) {
      text-align: left;
    }

    img {
      max-width: 115px;

      @media screen and (max-width: 992px) {
        max-width: 70px;
      }      
    }
  }
}

//PAGE RULES
.page-rules, .page-team {
    h1 {
        font-size: 4rem;
        color: $secondary-color;
        border-color: $secondary-color;
    }

    .intro-wrapper {
        margin-top: 50px;
        margin-bottom: 50px;
        @media screen and (max-width: 640px) {
          margin: 1.5rem auto;
        }
    }

    .action-section {
        margin-top: 50px;
        margin-bottom: 50px;
    }
}

.single-team-member {
  text-align: center;
  text-transform: uppercase;

  .wrapper-img {
    //height: 386px;

    img {
      max-width: 100%;
    }
  }

  .name {
    color: $main-blue;
    @include font-black(1.5rem);
    line-height: 1.125;

    @media screen and (max-width: 992px) {
      font-size: 1.3em;
    }
  }

  .role {
    @include font-bold(1rem);
    color: $secondary-color;
    text-transform: none;
  }
}

/*
.wrapper-carousel {
  --carousel-slider-max-width: 1200px;
  --carousel-slider-width: 520px;
  --carousel-slider-height: 380px;
  position: relative;
  width: var(--carousel-slider-max-width);
  margin: 0 auto;
  max-width: 100%;
  overflow: hidden;
  @media screen and (max-width:992px) {
      height: 24vh;
    }  
  }
*/

.toasty-area {
    position: fixed;
    top: 10%;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 1000000;
}

.toast {
    position: absolute;
    width: 25%;
    //height: 6%;
    background-color: #FFFFFF;
    //border: 1px solid $verde_successo;
    -webkit-box-shadow: 0px 5px 10px -1px rgba(#5A5A5A,0.25);
    box-shadow: 0px 5px 10px -1px rgba(#5A5A5A,0.25);
    text-align: center;
    padding: 0.5rem;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;


    @media screen and (max-width:768px) {
        width: 95%;
    }


    .icon-toast {
        width: 40px;
        margin-left: -10px;
    }

    .panel-toast-icon {
        width: 12%;
        display: inline-block;
        vertical-align: middle;
    }

    .panel-toast-message {
        text-align: left;
        width: 80%;
        display: inline-block;
        vertical-align: middle;
        line-height: 1.2;
        padding-left: 5px;
        padding-top: 10px;
        padding-bottom: 10px;
        color: #898989;
        font-family: acumin-pro, sans-serif;
        font-weight: 500;
        font-style: normal;
        font-size: 1rem;
    }
}

.toast-type-0 {
    // Okay
    border-left: 7px solid #39B14F;

    .panel-toast-icon {
        width: 30px;
        height: 30px;
        line-height: 30px;

        &:before {
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            content: "\f058";
            font-size: 1.6rem;
            color: #39B14F;
        }
    }
}

.toast-type-1 {
    // Warning
    border-left: 7px solid #D9A300;

    .panel-toast-icon {
        width: 30px;
        height: 30px;
        line-height: 30px;

        &:before {
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            content: "\f05a";
            font-size: 1.6rem;
            color: #D9A300;
        }
    }
}

.toast-type-2 {
    // Error
    border-left: 7px solid #FF2626;

    .panel-toast-icon {
        width: 30px;
        height: 30px;
        line-height: 30px;

        &:before {
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            content: "\f071";
            font-size: 1.6rem;
            color: #FF2626;
        }
    }
}

.player-card {
    &.mistery-player {
        margin: inherit 0;
        background-color: transparent;

        h3 {
            color: $secondary-color;
        }
    }
}

.page-team {
  .wrapper-title-wrap.bg-pattern-azure .txt-fit-holder h2.txt-fit {
    line-height: 1;
  }
}

.team-members {
  margin-top: 50px;
  margin-bottom: 50px;

  @media screen and (max-width: 992px) {
    margin-bottom: 30px;
  }

  .single-team-member {
    margin-bottom: 25px;

    @media screen and (max-width: 640px) {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
}

.img-lightbox-cont {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 888899;
  display: flex;
  justify-content: center;
  align-items: center;  

  .overlay {
    background-color: rgba(0, 0, 0, .5);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  .img-lightbox {
    position: relative;
    z-index: 1;
    width: auto;
    max-height: 70%;
  }
}

.lore-wrapper{
  h4 {
    color: $main-blue;
    font-family: "acumin-pro", sans-serif;
    font-weight: 900;
    font-style: normal;
    text-transform: uppercase;
    font-size: 1.75rem;

    ~ p {
      margin-top: 0;
    }
  }
  p{
    margin: 1rem 0;
    line-height: 1.25;
  }
  ul{
    list-style: disc;
    padding-left: 2rem;
    margin-bottom: 1.5rem;
    flex-direction: column;
    align-items: flex-start;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1;
    color: #00008d;
    li{
      margin-bottom: 0.85rem;
    }
  }
}