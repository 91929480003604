﻿.clrfix {
 content: "";
 display: block;
 clear: both;
}

.my-nft-page {
  .my-nft {
    .wrapper-text {
      h1 {
        color: #FF175B;
        font-family: acumin-pro, sans-serif;
        font-weight: 800;
        font-style: normal;
        font-size: 3.5rem;
        line-height: 0.9;
        text-transform: uppercase;
        margin: 0 0 1rem;

        @media screen and (max-width: 768px) {
          font-size: 2.5rem;
        }
      }

      p.big {
        font-size: 1.225rem;
        line-height: 1.125;
        margin-bottom: 1.5rem;
      }
    }
  }

  .not-available {
    display: none;
  }

  .single-card-infomation-details.internal {
    /*padding: 0;
    margin: 0;*/
    padding-bottom: unset;
  }

  .my-player-list {
    .single-card-detail-wrapper {
      margin-top: 0;
      margin-bottom: 0;
      //padding: 25px;
      //background-color: rgba($color: $main-blue, $alpha: .1);
      background-color: #e5e5f3;
      position: relative;

      &:before {
        content: "";
        width: 100vw;
        height: 100%;
        position: absolute;
        z-index: -1;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        //background-color: rgba($color: $main-blue, $alpha: .1);
        background-color: #e5e5f3;
      }

      &:nth-child(odd) {
        //background-color: rgba($color: $main-blue, $alpha: .05);
        background-color: #f2f2f9;

        &:before {
          //background-color: rgba($color: $main-blue, $alpha: .05);
          background-color: #f2f2f9;
        }
      }

      .buy-container {
        @media screen and (max-width: 768px) {
          margin-bottom: 2.5rem;
        }

        @media screen and (max-width: 640px) {
          background-color: rgba(255, 255, 255, .5);
        }

        .player-not-available-msg {
          background-color: transparent;
          padding: unset;
          display: none;
        }
      }
    }

    .row {
      max-width: 100%;
    }
  }

  .my-player-list {
    .single-card-detail-wrapper {
      &:after {
        @extend .clrfix;
      }

      .player-card, .player-stats-info, .wrapper-info {
        &:after {
          @extend .clrfix;
        }
      }

      .player-card {
        margin-bottom: 1.5rem;
        &.mistery-player {
          background: unset;
          margin-top: unset;
        }
      }

      .player-stats-info {
        display: none;
        .list-statistic-pie {
          border-top: 0.5px solid #c4c4c4;
        }
      }

      .wrapper-info {
        margin-bottom: unset;
        display: none;
        &.has-bg-light {
          background-color: transparent;
        }
      }

      .p-internal-tb {
        display: none;
      }

      .btn-show-details-cont {
        display: block;
        float: left;
        width: 100%;
        margin-bottom: 1.5rem;

        &:after {
          @extend .clrfix;
        }
      }
    }
  }
}