//pie chart

@property --p {
    syntax: '<number>';
    inherits: true;
    initial-value: 0;
}

.list-statistic-pie {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  box-sizing: border-box;
  max-width: 100%;
  padding: 1.5rem 0;
  border-top: .5px solid #E5E5F3;
  //border-bottom: .5px solid #E5E5F3;
  margin: 0 0 1.25rem;

  @media screen and (max-width: 1180px) {
    padding: 1rem 0;
  }

  @media screen and (max-width: 820px) {
    max-width: calc(100% - 1.875rem);
    marign-right: auto;
  }

  @media screen and (max-width: 819px) {
    max-width: unset;
    marign-right: unset;
  }

  @media screen and (max-width: 640px) {
    flex-wrap: nowrap;
    justify-content: space-between;
    width: calc(100% + 1rem);
    /*margin-left: -.5rem;
    margin-right: -.5rem;*/
    padding-top: 1.75rem;
  }

  @media screen and (max-width: 576px) {
    flex-wrap: wrap;
    justify-content: center;
  }

  .single-pie {
    //flex: 0 0 33%;
    flex: 0 0 calc(145px + 2rem);
    box-sizing: border-box;
    padding: 1rem;

    @media screen and (max-width: 1440px) {
      flex: 0 0 calc(125px + 2rem);
    }

    @media screen and (max-width: 640px) {
      padding: .5rem;
    }

    @media screen and (max-width: 576px) {
      padding: 1rem;
    }

    .label {
      @include font-black(1.625rem);
      color: $main-blue;
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 0.625rem;

      @media screen and (max-width: 1180px) {
        @include font-black(1.3rem);
      }
    }
  }
}

.wrapper-pie {
  position: relative;
  overflow: hidden;
  height: 145px;
  margin-bottom: 0.625rem;

  @media screen and (max-width: 1440px) {
    height: 125px;
  }

  @media screen and (max-width: 1180px) {
    height: 100px;
  }

  @media screen and (max-width: 640px) {
    height: 90px;
  }
}

.pie {
  --p: 20;
  --b: 22px;
  --c: darkred;
  --w: 145px;

  @media screen and (max-width: 1440px) {
    --w: 125px;
  }

  @media screen and (max-width: 1180px) {
    --b: 18px;
    --w: 100px;
  }

  @media screen and (max-width: 992px) {
    --w: 100px;
  }

  @media screen and (max-width: 640px) {
    --b: 16px;
    --w: 90px;
  }

  width: var(--w);
  aspect-ratio: 1;
  position: relative;
  display: inline-grid;
  margin: 0;
  place-content: center;
  font-size: 1.562rem;
  font-weight: bold;
  font-family: sans-serif;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  line-height: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1180px) {
    font-size: 1.25rem;
  }

  .point-gains {
    font-size: 1rem;
    line-height: 1;
    color: $main-blue;
  }
}

.pie:before,
.pie:after {
    content: "";
    position: absolute;
    border-radius: 50%;
}

.pie:before {
    inset: 0;
    background: radial-gradient(farthest-side,var(--c) 98%,#0000) top/var(--b) var(--b) no-repeat, conic-gradient(var(--c) calc(var(--p)*1%),#0000 0);
    -webkit-mask: radial-gradient(farthest-side,#0000 calc(99% - var(--b)),#000 calc(100% - var(--b)));
    mask: radial-gradient(farthest-side,#0000 calc(99% - var(--b)),#000 calc(100% - var(--b)));
}

.pie:after {
    inset: calc(50% - var(--b)/2);
    background: var(--c);
    transform: rotate(calc(var(--p)*3.6deg)) translateY(calc(50% - var(--w)/2));
}

.animate {
    animation: p 1s .5s both;
}

.no-round:before {
    background-size: 0 0,auto;
}

.no-round:after {
    content: none;
}

@keyframes p {
  from {
      --p: 0
  }
}

.wrapper-info {
  padding: 1rem;

  &.has-bg-light {
    background-color: #F2F2F9;
  }
}

.modal-wrapper {
  .body-modal {
    .wrapper-info {
      &.has-bg-light {
        flex: 0 1 63.5%;
        padding-right: 1.875rem;
        max-width: calc(63.5% - 1.875rem);

        @media screen and (max-width: 820px) {
          max-width: unset;
          //margin-bottom: .85rem;
        }
      }

      .feature {
        .feature-get {
          @media screen and (max-width: 376px) {
            transform: scale(.85) translate(2px, 0);
            transform-origin: left;
          }
        }
      }
    }

    .btn-show-details-cont {
      flex: 0 1 63.5%;
      padding-right: 1.875rem;
      max-width: calc(63.5% - 1.875rem);

      @media screen and (max-width: 991px) {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        max-width: unset;
        padding-right: unset;
      }
    }
  }
}

.btn-up {
    position: fixed;
    z-index: 100;
    color: red;
    font-size: 2rem;
    line-height: 30px;
    right: 1rem;
    z-index: 10001;
    bottom: 2rem;
    cursor: pointer;
    width: 30px;
    height: 30px;
    border-radius: 4px;
    background-color: #FFF;
    color: $main-blue;
    text-align: center;
    opacity: 0;
}

.roadmap {
  .wrapper-content {
    .accordion-learn-more {
      @media screen and (max-width: 640px) {
        margin-bottom: 1.5rem;
      }

      .accordion-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem 1rem .6rem;
        background-color: #ceceec;

        &.accordion-open {
          .fa-angle-down {
            transform: rotate(-180deg);
          }
        }

        &:hover {
          cursor: pointer;
        }

        h3 {
          margin-bottom: unset;
          color: $main-blue;
          font-family: acumin-pro, sans-serif;
          font-weight: 800;
          font-style: normal;
          line-height: 1;
          text-transform: uppercase;
        }

        .fa-angle-down {
          color: $main-blue;
          font-size: 2rem;
          transition: all ease-in-out .2s;
        }
      }

      .accordion-content {
        padding-top: 1rem;

        > * {
          font-family: acumin-pro, sans-serif;
          font-weight: 500;
          color: $main-blue;
        }

        p, ul, ol, li {
          font-size: 1rem;
        }

        ul, ol {
          padding-left: 2rem;
          margin-bottom: 1.5rem;
          flex-direction: column;
          align-items: flex-start;
        }

        ul {
          list-style: disc;
        }

        li {
          margin-bottom: .85rem;

          &:last-of-type {
            margin-bottom: unset;
          }
        }

        h4, h5 {
          text-transform: uppercase;
          font-weight: 700;
          line-height: 1;
          margin-bottom: 0.85rem;
        }

        h4 {
          font-size: 1.125rem;
        }

        h5 {
          font-size: 1.025rem;
          margin-bottom: 0.85rem;
        }        
      }
    }
  }
}

.accordion-table, .p-internal-tb {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 1.5rem;
  color: $main-blue;

  &.table-grow {
    .t-block {
      @media screen and (max-width: 820px) {
        flex: .5;
        width: calc(100% / 3);
      }

      &:last-of-type {
        @media screen and (max-width: 820px) {
        }
      }

      .t-header {
        @media screen and (max-width: 820px) {
          min-height: 27.5px;
        }

        &:first-of-type {
          padding-left: unset;
          padding-right: unset;
        }
      }

      .t-row {
        .t-col {
          @media screen and (max-width: 820px) {
            min-height: 21.3px;
          }
        }
      }

      h4, h5 {
        @media screen and (max-width: 820px) {
          font-size: .65rem;
          line-height: 1;
        }
      }
    }
  }

  h4, h5 {
    font-size: .8rem;
    line-height: 1.6;
    margin-bottom: unset;
  }

  .t-block {
    flex: 1 1 50%;

    &:nth-of-type(even) {
      background-color: #f2f2f9;

      .t-row {
        &:nth-of-type(odd) {
          background-color: #eaeaf7;
        }
      }
    }

    &.txt-center {
      .t-col, h4, h5 {
        text-align: center;
      }

      .t-col {
        justify-content: center;
      }
    }

    &.t-col-grow {
      .t-row {
        .t-col {
          flex-grow: 1;
        }
      }
    }

    .t-header {
      text-align: center;
      padding: 0.5rem 1.25rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #ceceec;

      h5 {
        margin-bottom: unset;
      }

      .t-col {
        flex: 1;
      }
    }

    .t-row {
      display: flex;

      &:nth-of-type(odd) {
        background-color: #f2f2f9;
      }

      .t-col {
        font-size: .85rem;
        flex: .5;
        display: flex;
        align-items: center;
        padding: .25rem 1rem;
        word-break: keep-all;
        white-space: nowrap;

        @media screen and (max-width: 1180px) {
          flex: auto;
          width: calc(100% / 3 * .5);
        }

        &.row-head {
          flex: 2;

          @media screen and (max-width: 1180px) {
            flex: auto;
            width: calc(100% / 3);
          }
        }

        &.eth {
          display: flex;
          flex: 2;

          @media screen and (max-width: 1180px) {
            flex: auto;
            width: calc(100% / 3 * 1.5);
          }

          &:before {
            content: "";
            width: 25px;
            height: 25px;
            margin-right: 5px;
            background-image: url($resolvepath+'assets/eth.svg');
            background-size: 80%;
            background-repeat: no-repeat;
            background-position: center;
          }
        }
      }
    }
  }
}

.p-internal-tb-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-left: unset;
  padding-right: unset;
  //margin-top: 2.75rem;

  .p-internal-tb {
    flex-grow: 0;
    width: calc(50% - 0.75rem);

    @media screen and (max-width: 640px) {
      flex-grow: 1;
      width: 100%;
      margin-bottom: 2.75rem;
    }

    &:last-of-type {
      @media screen and (max-width: 640px) {
        margin-bottom: unset;
      }
    }

    .t-block {
      display: flex;
      flex-direction: column; 
      @media screen and (max-width: 640px) {
        min-height: 125px;
      }
    }

    .t-header {
      h3 {
        @include font-black(1.3rem);
        font-style: normal;
        color: $main-blue;
        text-transform: uppercase;
        margin-bottom: unset;
      }
    }

    .msg-no-matches {
      flex-grow: 1;
      background-color: #ff175b30;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h4 {
        font-size: 1.125rem;
        margin-top: unset;
        margin-bottom: 1.25rem;
        color: #FF175B;
      }
    }
  }
}

.player-not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 55vh;
  .wrapper-content {
    .wrapper-text {
      h2 {
        color: #FF175B;
        font-family: acumin-pro, sans-serif;
        font-weight: 800;
        font-style: normal;
        font-size: 3.5rem;
        line-height: 0.9;
        text-transform: uppercase;
        margin: 0 0 1rem;
      }

      p {
        font-size: 1.225rem;
        line-height: 1.25;
      }
    }
  }
}

.roadmap {
  &.intro-msg {
    .wrapper-content {
      padding-left: unset;
      border-left: unset;
      margin-bottom: .25rem;
      text-align: center;

      .wrapper-text {
        h2 {
          color: #FF175B;
        }

        p {
          line-height: 1.125;
        }
      }
    }
  }
}

.layer-cc {
  position: fixed;
  z-index: 999999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: none;

  .layer-content {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 992px;
    background-color: #fff;
    width: auto;
    border-radius: .75rem;
    display: flex;
    padding: 5rem 3rem;
    //aspect-ratio: 2;

    @media screen and (min-width: 1440px) {
      //aspect-ratio: 1.5;
      max-height: calc(90% - 7rem);
      padding: 3rem;
    }

    @media screen and (max-width: 992px) {
      max-width: 90%;
      padding: 3rem 1.75rem;
    }

    .btn-close {
      position: absolute;
      top: -1rem;
      right: -0.5rem;
      width: 3rem;
      height: 3rem;
      background: unset;
      font-size: 3.5rem;
      color: #fff;
      line-height: 0;
      transform: translate(0, -100%);
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 1;

      &:hover {
        cursor: pointer;
      }
    }

    .layer-cc-text {
      //flex: 1 1 60%;
      //width: 60%;
      width: 100%;
      height: 100%;
      padding-left: 4rem;
      padding-right: 4rem;
      border-left: 10px solid #FF175B;
      border-right: 10px solid #FF175B;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-self: stretch;

      @media screen and (min-width: 1440px) {
        padding-left: 2rem;
        padding-right: 2rem;
      }

      @media screen and (max-width: 820px) {
        padding-left: 2rem;
        padding-right: 2rem;
      }

      @media screen and (max-width: 640px) {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        border-left: unset;
        border-right: unset;
      }

      h2 {
        font-family: acumin-pro, sans-serif;
        font-weight: 800;
        font-style: normal;
        font-size: 2.25rem;
        line-height: 1;
        color: #FF175B;
        text-transform: uppercase;
        margin: 0 0 1rem;
        text-align: center;

        @media screen and (max-width: 820px) {
          font-size: 2rem;
        }

        @media screen and (max-width: 640px) {
          font-size: 1.75rem;
        }
      }

      p {
        font-size: 1.125rem;
        color: $main-blue;
        margin-top: 0;
        line-height: 1.15;
        //margin-bottom: 1.5rem;
        text-align: center;
        margin-bottom: unset;
      }

      .player-info {
        width: 100%;
        padding: 1.25rem;
        text-align: center;
        font-size: 1.5rem;
        line-height: 1.125;
        text-transform: uppercase;
        color: $main-blue;
        font-weight: 700;
        background-color: #f2f2f9;
        margin: 1.5rem auto;
        border-left: 5px solid $main-blue;
        border-right: 5px solid $main-blue;

        @media screen and (max-width: 820px) {
          font-size: 1.25rem;
        }

        .paypal-credentials {
          font-size: 1rem;
          text-transform: none;
          margin-bottom: .85rem;

          p {
            margin-bottom: 1rem;
            font-size: 1rem;

            @media screen and (max-width: 640px) {
              margin-bottom: .25rem;
            }
          }

          .single-cred {
            margin-bottom: .25rem;
            font-size: .85rem;

            span {
              font-size: .85rem;
              font-weight: 400;
            }
          }
        }
      }

      .wallet-form {
        p {
          margin-bottom: .5rem;
        }
      }

      input[type="text"] {
        height: 37px;
        width: 100%;
        border: 1.5px solid rgba(0, 0, 141, 0.1);
        border-radius: unset;
        margin-bottom: 1rem;
        font-family: acumin-pro, sans-serif;
        font-weight: 500;
        font-size: 1.25rem;
        text-align: center;
        display: flex;
        align-items: center;
        padding: 0 1.25rem .25rem;

        &:placeholder {
          font-family: acumin-pro, sans-serif;
          font-weight: 500;
          font-size: 1.25rem;
          line-height: 1;
        }
      }

      .layer-cc-send {
        width: 100%;
        max-width: unset;

        @media screen and (max-width: 640px) {
          font-size: 1rem;
          padding-left: .95rem;
        }
      }
    }

    .layer-cc-img {
      flex: 1 1 40%;
      width: 40%;
      text-align: center;
      border-right: 10px solid #FF175B;
      padding: 0 4rem;
      margin-top: unset;
      margin-bottom: unset;
      display: flex;
      align-items: center;
      align-self: stretch;

      @media screen and (min-width: 1440px) {
        padding: 0 2rem;
      }
    }
  }
}

.player-not-available-msg {
  padding: 1rem 1rem .5rem;
  margin-top: 1.5rem;
  background-color: #F2F2F9;
}


.accordion-learn-more {
  @media screen and (max-width: 640px) {
    margin-bottom: 1.5rem;
  }

  .accordion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem .6rem;
    background-color: #ceceec;

    &.accordion-open {
      .fa-angle-down {
        transform: rotate(-180deg);
      }
    }

    &:hover {
      cursor: pointer;
    }

    h3 {
      margin-bottom: unset;
      color: $main-blue;
      font-family: acumin-pro, sans-serif;
      font-weight: 800;
      font-style: normal;
      line-height: 1;
      text-transform: uppercase;
    }

    .fa-angle-down {
      color: $main-blue;
      font-size: 2rem;
      transition: all ease-in-out .2s;
    }
  }

  .accordion-content {
    padding-top: 1rem;

    > * {
      font-family: acumin-pro, sans-serif;
      font-weight: 500;
      color: $main-blue;
    }

    p, ul, ol, li {
      font-size: 1rem;
    }

    ul, ol {
      padding-left: 2rem;
      margin-bottom: 1.5rem;
      flex-direction: column;
      align-items: flex-start;
    }

    ul {
      list-style: disc;
    }

    li {
      margin-bottom: .85rem;

      &:last-of-type {
        margin-bottom: unset;
      }
    }

    h4, h5 {
      text-transform: uppercase;
      font-weight: 700;
      line-height: 1;
    }

    h4 {
      font-size: 1.125rem;
    }

    h5 {
      font-size: 1.025rem;
    }        
  }
}

.wrapper-faq{
  .accordion-learn-more{
    margin:1rem 0;
    p{
      line-height: 1.75;
    }
  }

}

.img-history{
  @media screen and (max-width:660px){
    padding: 4rem;
  }
}

.team{
  .title-decoration.blue{
    border-top-color: #FFF;
    border-bottom-color: #FFF;
  }
  .wrapper-title-wrap{
    h2{
      color:#FFF
    }
  }
  .wrapper-title-wrap.bg-pattern-azure::before{
    background-image: url($resolvepath + 'assets/bg-red.svg');
  }
  margin-bottom: 4rem;
  .single-team{
    text-align: center;
    .wrapper-img{
      padding:1rem 2rem;
    }
    .role{
      color: $main-blue;
    }
  }
}

.btn {
  &.btn-twitter-community {
    padding: .5rem 1.85rem;
    border-radius: .125rem;
    background-color: $main-blue;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      margin-right: .5rem;
    }  
  }
}